import React, { FunctionComponent, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { v4 as uuidV4 } from 'uuid'
import iconAdd from '../../../../assets/images/icons/add.svg'
import iconEdit from '../../../../assets/images/icons/datalist-edit.svg'
import iconHeadingSearch from '../../../../assets/images/icons/datalist-heading-search.svg'
import '../../../../assets/styles/components/_datalist.scss'
import { CallerType } from '../../../../domain/Caller/Caller'
import { ProductChildInterface } from '../../../../domain/Product/ProductChild'
import { FilterProductInterface, ProductListInterface } from '../../../../domain/Product/ProductList'
import { SortInterface, SortOrder } from '../../../../domain/Utils/List'
import { optionsNbRows } from '../../../../fixtures/Referentiel'
import ProductGateway from '../../../../gateway/Product/ProductGateway'
import ProductListPresenter from '../../../../presenter/Product/ProductListPresenter'
import { ListRequest } from '../../../../useCase/Product/List/ListRequest'
import ListUseCase from '../../../../useCase/Product/List/ListUseCase'
import { setOpenDatalistFilterProductChild } from '../../../store/component/event'
import { useAppDispatch, useAppSelector } from '../../../store/hook'
import { saveNbRowsInLocalStorage } from '../../util/SavePreferencesInLocalStorage'
import SelectCustom from '../Elements/Select'
import Pagination from '../Pagination/Pagination'
import TableHead from '../Table/TableHead'

interface ViewModelInterface {
  title: string
  heading: []
  data: []
  filtersShortcut: []
  filters: []
  pagination: PaginationInterface
  count: number
}

interface PaginationInterface {
  itemsPerPage: number
  numberOfItems: number
}

type Props = {
  callback: (product: ProductListInterface) => void
  callerType: CallerType
}

const ProductChildDatalist: FunctionComponent<Props> = ({ callerType, callback }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const openDatalistFilterProductChild = useAppSelector(state => state.event.openDatalistFilterProductChild)
  const productChildArray = useAppSelector(state => state.product.productChildArray)
  const [viewModel, setViewModel] = useState<ViewModelInterface | null>(null)
  const [sortOrder, setSortOrder] = useState<SortInterface>({ sortLabel: null, sortOrder: SortOrder.ASC })
  const [currentPage, setCurrentPage] = useState<number>(1)
  const controller = new AbortController()
  const preferences = localStorage.getItem('preferences')

  const { register, handleSubmit, setValue, watch } = useForm()

  const watchNumberRows = watch('numberRows')

  useEffect(() => {
    if (!watchNumberRows) {
      setValue('numberRows', preferences ? JSON.parse(preferences).numberRows : 50)
    } else {
      saveNbRowsInLocalStorage(preferences, watchNumberRows)
      const listRequest = new ListRequest(currentPage, watchNumberRows || 50, openDatalistFilterProductChild.filters)
      const products = new ListUseCase(new ProductGateway())
        .execute(listRequest, controller.signal, sortOrder.sortLabel ?? '', sortOrder.sortOrder, callerType)
        .then(response => {
          let filteredProductChildByExisting: ProductChildInterface[] = response?.data || []
          if (response?.data) {
            if (filteredProductChildByExisting) {
              filteredProductChildByExisting = filteredProductChildByExisting.filter(child => {
                if (!productChildArray.find(el => el.id === child.id)) {
                  return child
                }
              })
            }
            return { ...response, data: filteredProductChildByExisting }
          } else return response
        })
      const presenter = new ProductListPresenter(products)
      presenter.load().then(() => {
        setViewModel(presenter.immutableViewModel())
      })

      setValue('name', openDatalistFilterProductChild.filters.name)
      setValue('keywords', openDatalistFilterProductChild.filters.keywords)
      setValue('type', openDatalistFilterProductChild.filters.type)
      setValue('subproduct', openDatalistFilterProductChild.filters.subproduct)
    }
  }, [currentPage, openDatalistFilterProductChild.filters, watchNumberRows, productChildArray])

  useEffect(() => {
    setCurrentPage(1)
  }, [openDatalistFilterProductChild.filters])

  const onSubmit: SubmitHandler<FilterProductInterface> = data => {
    dispatch(
      setOpenDatalistFilterProductChild({
        show: false,
        count: openDatalistFilterProductChild.count,
        filters: {
          name: data.name,
          keywords: data.keywords,
          type: '',
          subproduct: true,
        },
      })
    )
  }

  const paginate = (pageNumber: number) => {
    controller.abort()
    setCurrentPage(pageNumber)
  }

  const resetFilters = () => {
    dispatch(
      setOpenDatalistFilterProductChild({
        show: false,
        count: 0,
        productId: '',
        filters: {
          name: '',
          keywords: '',
          type: '',
        },
      })
    )
  }

  return (
    <>
      {viewModel !== null && (
        <>
          <div className={`datalist`}>
            <div className='datalist__title'>{t(viewModel.title)}</div>
            <div className='datalist__header'>
              <form onSubmit={handleSubmit(onSubmit)} className='filter'>
                <div className='filter__input'>
                  {viewModel.filtersShortcut.map((filter: { keyword: string; field: string; type: string }) => (
                    <div key={uuidV4()} className='input-no-border'>
                      <img src={iconHeadingSearch} alt='' />
                      <input
                        {...register(filter.field)}
                        placeholder={t('common.search-by', { keyword: t(filter.keyword) })}
                        className='u-mxs'
                      />
                    </div>
                  ))}
                </div>
                <div className='filter__actions'>
                  <button type='submit' className='button button--submit'>
                    {t('search.submit')}
                  </button>
                  <button type='button' className='button button--white' onClick={() => resetFilters()}>
                    {t('search.cancel')}
                  </button>
                </div>
              </form>
            </div>
            <SelectCustom
              classes='flex justify-end u-mbs'
              id='numberRows'
              name='numberRows'
              label={t('filters.display-results-by')}
              options={optionsNbRows}
              register={register}
              noChoiceOption
            />
            <div className='table-fix-head'>
              <table className='datalist__datas'>
                <thead>
                  {viewModel.heading && (
                    <TableHead
                      typeFilter={'API'}
                      heading={viewModel.heading}
                      sortOrder={sortOrder}
                      setSortOrder={setSortOrder}
                      viewModel={viewModel}
                      setViewModel={setViewModel}
                      filter={openDatalistFilterProductChild.filters}
                      watchNumberRows={watchNumberRows}
                      currentPage={currentPage}
                      listRequest={
                        new ListRequest(currentPage, watchNumberRows || 50, openDatalistFilterProductChild.filters)
                      }
                      listUseCase={ListUseCase}
                      listPresenter={ProductListPresenter}
                      gateway={ProductGateway}
                    />
                  )}
                </thead>
                <tbody>
                  {viewModel.data.map((item: ProductListInterface) => (
                    <tr key={uuidV4()}>
                      {item !== null && item !== undefined && (
                        <>
                          <td>
                            <button type='button' className='button-reset' onClick={() => callback(item)}>
                              <img src={iconAdd} alt='' />
                            </button>
                            <Link to={`/${t('url.product.edit')}/${Object.entries(item)[0][1]}`}>
                              <button type='button' className='button-reset'>
                                <img src={iconEdit} alt='' />
                              </button>
                            </Link>
                          </td>
                          {Object.entries(item).map(obj => {
                            if (obj[0] !== 'id') {
                              return <td key={uuidV4()}>{obj[1]}</td>
                            }
                          })}
                        </>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <Pagination
            currentPage={currentPage}
            itemsPerPage={watchNumberRows || viewModel.pagination.itemsPerPage}
            numberOfItems={viewModel.pagination.numberOfItems}
            callback={paginate}
          />
        </>
      )}
    </>
  )
}

export default ProductChildDatalist
