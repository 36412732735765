import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import Authorization from '../oauth/Authorization'
import Logout from '../oauth/Logout'
import GeneralLayout from '../presentation/component/GeneralLayout/GeneralLayout'
import CommissioningChoiceType from '../presentation/page/Commissioning/Add/CommissioningChoiceType'
import CommissioningDashboard from '../presentation/page/Commissioning/Dashboard/CommissioningDashboard'
import CustomerDashboard from '../presentation/page/Customer/Dashboard/CustomerDashboard'
import PledgeOrganizationDashboard from '../presentation/page/Customer/Dashboard/PledgeOrganizationDashboard'
import Dashboard from '../presentation/page/Dashboard/Dashboard'
import ChoiceDistributionType from '../presentation/page/Distribution/ChoiceType'
import DistributionDashboard from '../presentation/page/Distribution/Dashboard'
import ErrorPage from '../presentation/page/Error/ErrorPage'
import MovementAgreement from '../presentation/page/Movement/Agreement/AddOrEdit/AddOrEdit'
import MovementChoiceType from '../presentation/page/Movement/ChoiceMouvement/ChoiceMouvement'
import MovementDashboard from '../presentation/page/Movement/Dashboard/MovementDashboard'
import MovementDashboardDismemberment from '../presentation/page/Movement/Dashboard/MovementDismenberment'
import MovementDismemberment from '../presentation/page/Movement/Dismemberment/AddOrEdit/AddOrEdit'
import MovementMutation from '../presentation/page/Movement/Mutation/AddOrEdit/AddOrEdit'
import MovementRedemptionWithdrawal from '../presentation/page/Movement/RedemptionWithdrawal/AddOrEdit/AddOrEdit'
import PartnerCommissioning from '../presentation/page/Partner/Commissioning/PartnerCommissioning'
// Page
import PartnerDashboard from '../presentation/page/Partner/Dashboard/PartnerDashboard'
import PartnerExAnteCosts from '../presentation/page/Partner/ExAnteCosts/PartnerExAnteCosts'
import PartnerExPostCosts from '../presentation/page/Partner/ExPostCosts/PartnerExPostCosts'
import PreSubscription from '../presentation/page/PreSubscription/AddOrEdit/AddOrEdit'
import MovementRecurrence from '../presentation/page/PreSubscription/Dashboard/MovementRecurrence'
import PreSubscriptionDashboard from '../presentation/page/PreSubscription/Dashboard/PreSubscriptionDashboard'
import ProductChoiceType from '../presentation/page/Product/ChoiceType/ChoiceType'
import ProductDashboard from '../presentation/page/Product/Dashboard/ProductDashboard'
import ProspectChoiceType from '../presentation/page/Prospect/ChoiceType/ChoiceType'
import ProspectDashboard from '../presentation/page/Prospect/Dashboard/ProspectDashboard'
import ChoiceRecurrenceSessionType from '../presentation/page/Recurrence/ChoiceRecurrenceSession/ChoiceRecurrenceSession'
import RecurrenceMandateList from '../presentation/page/Recurrence/Dashboard/RecurrenceMandateList'
import RecurrenceSession from '../presentation/page/Recurrence/Session/RecurrenceSession'
import GeneralSettings from '../presentation/page/Settings/GeneralSettings'
import RoleSetting from '../presentation/page/Settings/RoleSetting'
import SoftwareSettings from '../presentation/page/Settings/SoftwareSettings'
import UserProfiles from '../presentation/page/Settings/UserProfiles'
import PrivateRoute from './PrivateRoute'
import {Detail as RecurrenceMandateDetail} from '../presentation/page/Recurrence/Mandate'
import DismembermentLedgerDashboard from '../presentation/page/DismembermentLedger/DismembermentLedgerDashboard'
import { DismembermentLedgerProvider } from '../presentation/Context/DismembermentLedgerContext'

const Router: FunctionComponent = () => {
  const { t } = useTranslation()

  return (
    <BrowserRouter>
      <GeneralLayout />
      <Routes>
        <Route path={'/authorization'} element={<Authorization />} />
        <Route path={`/${t('url.logout')}`} element={<Logout />} />
        <Route path={'/'} element={<PrivateRoute component={Dashboard} />} />
        <Route path={'/tableau-de-bord'} element={<PrivateRoute component={Dashboard} />} />

        <Route path={`/${t('url.partner.dashboard')}`} element={<PrivateRoute component={PartnerDashboard} />} />
        <Route
          path={`/${t('url.partner.commissioning')}`}
          element={<PrivateRoute component={PartnerCommissioning} />}
        />
        <Route path={`/${t('url.partner.ex-ante-costs')}`} element={<PrivateRoute component={PartnerExAnteCosts} />} />
        <Route path={`/${t('url.partner.ex-post-costs')}`} element={<PrivateRoute component={PartnerExPostCosts} />} />
        <Route
          path={`/${t('url.partner.add-general-information')}`}
          element={<PrivateRoute layout={'partner'} mode={'add'} page={'GeneralInformation'} />}
        />
        <Route
          path={`/${t('url.partner.edit-general-information')}/:uuid`}
          element={<PrivateRoute layout={'partner'} mode={'edit'} page={'GeneralInformation'} />}
        />
        <Route
          path={`/${t('url.partner.read-general-information')}/:uuid`}
          element={<PrivateRoute layout={'partner'} mode={'read'} page={'GeneralInformation'} />}
        />
        <Route
          path={`/${t('url.partner.edit-document-tracking')}/:uuid`}
          element={<PrivateRoute layout={'partner'} mode={'edit'} page={'DocumentTracking'} />}
        />
        <Route
          path={`/${t('url.partner.read-document-tracking')}/:uuid`}
          element={<PrivateRoute layout={'partner'} mode={'read'} page={'DocumentTracking'} />}
        />
        <Route
          path={`/${t('url.partner.edit-beneficial-beneficiaries')}/:uuid`}
          element={<PrivateRoute layout={'partner'} mode={'edit'} page={'BeneficialBeneficiaries'} />}
        />
        <Route
          path={`/${t('url.partner.read-beneficial-beneficiaries')}/:uuid`}
          element={<PrivateRoute layout={'partner'} mode={'read'} page={'BeneficialBeneficiaries'} />}
        />
        <Route
          path={`/${t('url.partner.edit-portfolio')}/:uuid`}
          element={<PrivateRoute layout={'partner'} mode={'edit'} page={'Portfolio'} />}
        />
        <Route
          path={`/${t('url.partner.read-portfolio')}/:uuid`}
          element={<PrivateRoute layout={'partner'} mode={'read'} page={'Portfolio'} />}
        />
        <Route
          path={`/${t('url.partner.edit-commissioning')}/:uuid`}
          element={<PrivateRoute layout={'partner'} mode={'edit'} page={'Commissions'} />}
        />
        <Route
          path={`/${t('url.partner.read-commissioning')}/:uuid`}
          element={<PrivateRoute layout={'partner'} mode={'read'} page={'Commissions'} />}
        />

        <Route
          path={`/${t('url.partner.edit-convention')}/:uuid`}
          element={<PrivateRoute layout={'partner'} mode={'edit'} page={'Convention'} />}
        />
        <Route
          path={`/${t('url.partner.read-convention')}/:uuid`}
          element={<PrivateRoute layout={'partner'} mode={'read'} page={'Convention'} />}
        />

        <Route
          path={`/${t('url.partner.edit-particularities')}/:uuid`}
          element={<PrivateRoute layout={'partner'} mode={'edit'} page={'Particularities'} />}
        />
        <Route
          path={`/${t('url.partner.read-particularities')}/:uuid`}
          element={<PrivateRoute layout={'partner'} mode={'read'} page={'Particularities'} />}
        />
        <Route
          path={`/${t('url.partner.edit-transfert')}/:uuid`}
          element={<PrivateRoute layout={'partner'} mode={'edit'} page={'Transfert'} />}
        />
        <Route
          path={`/${t('url.partner.read-transfert')}/:uuid`}
          element={<PrivateRoute layout={'partner'} mode={'read'} page={'Transfert'} />}
        />

        <Route path={`/${t('url.product.dashboard')}`} element={<PrivateRoute component={ProductDashboard} />} />
        <Route path={`/${t('url.product.add-choice-type')}`} element={<PrivateRoute component={ProductChoiceType} />} />
        <Route
          path={`/${t('url.product.add')}/:productType`}
          element={<PrivateRoute layout={'product'} mode={'add'} page={'GeneralInformation'} />}
        />
        <Route
          path={`/${t('url.product.edit')}/:uuid`}
          element={<PrivateRoute layout={'product'} mode={'edit'} page={'GeneralInformation'} />}
        />
        <Route
          path={`/${t('url.product.read')}/:uuid`}
          element={<PrivateRoute layout={'product'} mode={'read'} page={'GeneralInformation'} />}
        />
        <Route
          path={`/${t('url.product.edit-distribution')}/:uuid`}
          element={<PrivateRoute layout={'product'} mode={'edit'} page={'Distribution'} />}
        />
        <Route
          path={`/${t('url.product.read-distribution')}/:uuid`}
          element={<PrivateRoute layout={'product'} mode={'read'} page={'Distribution'} />}
        />
        <Route
          path={`/${t('url.product.edit-fiscality')}/:uuid`}
          element={<PrivateRoute layout={'product'} mode={'edit'} page={'Fiscality'} />}
        />
        <Route
          path={`/${t('url.product.read-fiscality')}/:uuid`}
          element={<PrivateRoute layout={'product'} mode={'read'} page={'Fiscality'} />}
        />
        <Route
          path={`/${t('url.product.edit-ag')}/:uuid`}
          element={<PrivateRoute layout={'product'} mode={'edit'} page={'GeneralMeeting'} />}
        />
        <Route
          path={`/${t('url.product.read-ag')}/:uuid`}
          element={<PrivateRoute layout={'product'} mode={'read'} page={'GeneralMeeting'} />}
        />
        <Route
          path={`/${t('url.product.edit-commission')}/:uuid`}
          element={<PrivateRoute layout={'product'} mode={'edit'} page={'Commission'} />}
        />
        <Route
          path={`/${t('url.product.read-commission')}/:uuid`}
          element={<PrivateRoute layout={'product'} mode={'read'} page={'Commission'} />}
        />
        <Route
          path={`/${t('url.product.edit-depositary')}/:uuid`}
          element={<PrivateRoute layout={'product'} mode={'edit'} page={'Depositary'} />}
        />
        <Route
          path={`/${t('url.product.read-depositary')}/:uuid`}
          element={<PrivateRoute layout={'product'} mode={'read'} page={'Depositary'} />}
        />
        <Route
          path={`/${t('url.product.edit-multi-product')}/:uuid`}
          element={<PrivateRoute layout={'product'} mode={'edit'} page={'MultiProduct'} />}
        />
        <Route
          path={`/${t('url.product.read-multi-product')}/:uuid`}
          element={<PrivateRoute layout={'product'} mode={'read'} page={'MultiProduct'} />}
        />
        <Route
          path={`/${t('url.product.edit-bank-detail')}/:uuid`}
          element={<PrivateRoute layout={'product'} mode={'edit'} page={'BankDetails'} />}
        />
        <Route
          path={`/${t('url.product.read-bank-detail')}/:uuid`}
          element={<PrivateRoute layout={'product'} mode={'read'} page={'BankDetails'} />}
        />
        <Route
          path={`/${t('url.product.edit-net-asset-value')}/:uuid`}
          element={<PrivateRoute layout={'product'} mode={'edit'} page={'NetAssetValue'} />}
        />
        <Route
          path={`/${t('url.product.read-net-asset-value')}/:uuid`}
          element={<PrivateRoute layout={'product'} mode={'read'} page={'NetAssetValue'} />}
        />
        <Route
          path={`/${t('url.product.edit-scale')}/:uuid`}
          element={<PrivateRoute layout={'product'} mode={'edit'} page={'Barème'} />}
        />
        <Route
          path={`/${t('url.product.read-scale')}/:uuid`}
          element={<PrivateRoute layout={'product'} mode={'read'} page={'Barème'} />}
        />

        <Route path={`/${t('url.prospect.dashboard')}`} element={<PrivateRoute component={ProspectDashboard} />} />
        <Route
          path={`/${t('url.prospect.add-choice-type')}`}
          element={<PrivateRoute component={ProspectChoiceType} />}
        />
        <Route
          path={`/${t('url.prospect.add-general-information')}/:prospectType`}
          element={<PrivateRoute layout={'prospect'} mode={'add'} page={'GeneralInformation'} />}
        />
        <Route
          path={`/${t('url.prospect.edit-general-information')}/:uuid`}
          element={<PrivateRoute layout={'prospect'} mode={'edit'} page={'GeneralInformation'} />}
        />
        <Route
          path={`/${t('url.prospect.read-general-information')}/:uuid`}
          element={<PrivateRoute layout={'prospect'} mode={'read'} page={'GeneralInformation'} />}
        />
        <Route
          path={`/${t('url.prospect.edit-beneficial-owner')}/:uuid`}
          element={<PrivateRoute layout={'prospect'} mode={'edit'} page={'BeneficialOwner'} />}
        />
        <Route
          path={`/${t('url.prospect.read-beneficial-owner')}/:uuid`}
          element={<PrivateRoute layout={'prospect'} mode={'read'} page={'BeneficialOwner'} />}
        />
        <Route
          path={`/${t('url.prospect.edit-indivisaire')}/:uuid`}
          element={<PrivateRoute layout={'prospect'} mode={'edit'} page={'Undivided'} />}
        />
        <Route
          path={`/${t('url.prospect.read-indivisaire')}/:uuid`}
          element={<PrivateRoute layout={'prospect'} mode={'read'} page={'Undivided'} />}
        />
        <Route
          path={`/${t('url.prospect.edit-moral-legal-representative')}/:uuid`}
          element={<PrivateRoute layout={'prospect'} mode={'edit'} page={'MoralLegalRepresentative'} />}
        />
        <Route
          path={`/${t('url.prospect.read-moral-legal-representative')}/:uuid`}
          element={<PrivateRoute layout={'prospect'} mode={'read'} page={'MoralLegalRepresentative'} />}
        />
        <Route
          path={`/${t('url.prospect.edit-addresses')}/:uuid`}
          element={<PrivateRoute layout={'prospect'} mode={'edit'} page={'Addresses'} />}
        />
        <Route
          path={`/${t('url.prospect.read-addresses')}/:uuid`}
          element={<PrivateRoute layout={'prospect'} mode={'read'} page={'Addresses'} />}
        />
        <Route
          path={`/${t('url.prospect.edit-bank-details')}/:uuid`}
          element={<PrivateRoute layout={'prospect'} mode={'edit'} page={'BankDetails'} />}
        />
        <Route
          path={`/${t('url.prospect.read-bank-details')}/:uuid`}
          element={<PrivateRoute layout={'prospect'} mode={'read'} page={'BankDetails'} />}
        />
        <Route
          path={`/${t('url.prospect.edit-pending-subscriptions')}/:uuid`}
          element={<PrivateRoute layout={'prospect'} mode={'edit'} page={'PendingSubscription'} />}
        />
        <Route
          path={`/${t('url.prospect.edit-particularities')}/:uuid`}
          element={<PrivateRoute layout={'prospect'} mode={'edit'} page={'Particularity'} />}
        />
        <Route
          path={`/${t('url.prospect.read-particularities')}/:uuid`}
          element={<PrivateRoute layout={'prospect'} mode={'read'} page={'Particularity'} />}
        />
        <Route
          path={`/${t('url.prospect.edit-investment-information')}/:uuid`}
          element={<PrivateRoute layout={'prospect'} mode={'edit'} page={'InvestmentInformation'} />}
        />
        <Route
          path={`/${t('url.prospect.read-investment-information')}/:uuid`}
          element={<PrivateRoute layout={'prospect'} mode={'read'} page={'InvestmentInformation'} />}
        />
        <Route
          path={`/${t('url.prospect.edit-legal-representatives')}/:uuid`}
          element={<PrivateRoute layout={'prospect'} mode={'edit'} page={'LegalRepresentatives'} />}
        />

        <Route
          path={`/${t('url.customer.dashboard-pledge-organization')}`}
          element={<PrivateRoute component={PledgeOrganizationDashboard} />}
        />
        <Route path={`/${t('url.customer.dashboard')}`} element={<PrivateRoute component={CustomerDashboard} />} />
        <Route
          path={`/${t('url.customer.edit-general-information')}/:uuid`}
          element={<PrivateRoute layout={'customer'} mode={'edit'} page={'GeneralInformation'} />}
        />
        <Route
          path={`/${t('url.customer.read-general-information')}/:uuid`}
          element={<PrivateRoute layout={'customer'} mode={'read'} page={'GeneralInformation'} />}
        />
        <Route
          path={`/${t('url.customer.edit-beneficial-owner')}/:uuid`}
          element={<PrivateRoute layout={'customer'} mode={'edit'} page={'BeneficialOwner'} />}
        />
        <Route
          path={`/${t('url.customer.read-beneficial-owner')}/:uuid`}
          element={<PrivateRoute layout={'customer'} mode={'read'} page={'BeneficialOwner'} />}
        />
        <Route
          path={`/${t('url.customer.edit-indivisaire')}/:uuid`}
          element={<PrivateRoute layout={'customer'} mode={'edit'} page={'Undivided'} />}
        />
        <Route
          path={`/${t('url.customer.read-indivisaire')}/:uuid`}
          element={<PrivateRoute layout={'customer'} mode={'read'} page={'Undivided'} />}
        />
        <Route
          path={`/${t('url.customer.edit-moral-legal-representative')}/:uuid`}
          element={<PrivateRoute layout={'customer'} mode={'edit'} page={'MoralLegalRepresentative'} />}
        />
        <Route
          path={`/${t('url.customer.read-moral-legal-representative')}/:uuid`}
          element={<PrivateRoute layout={'customer'} mode={'read'} page={'MoralLegalRepresentative'} />}
        />
        <Route
          path={`/${t('url.customer.edit-addresses')}/:uuid`}
          element={<PrivateRoute layout={'customer'} mode={'edit'} page={'Addresses'} />}
        />
        <Route
          path={`/${t('url.customer.read-addresses')}/:uuid`}
          element={<PrivateRoute layout={'customer'} mode={'read'} page={'Addresses'} />}
        />
        <Route
          path={`/${t('url.customer.edit-bank-details')}/:uuid`}
          element={<PrivateRoute layout={'customer'} mode={'edit'} page={'BankDetails'} />}
        />
        <Route
          path={`/${t('url.customer.read-bank-details')}/:uuid`}
          element={<PrivateRoute layout={'customer'} mode={'read'} page={'BankDetails'} />}
        />
        <Route
          path={`/${t('url.customer.edit-investment-information')}/:uuid`}
          element={<PrivateRoute layout={'customer'} mode={'edit'} page={'InvestmentInformation'} />}
        />
        <Route
          path={`/${t('url.customer.read-investment-information')}/:uuid`}
          element={<PrivateRoute layout={'customer'} mode={'read'} page={'InvestmentInformation'} />}
        />
        <Route
          path={`/${t('url.customer.edit-particularities')}/:uuid`}
          element={<PrivateRoute layout={'customer'} mode={'edit'} page={'Particularity'} />}
        />
        <Route
          path={`/${t('url.customer.read-particularities')}/:uuid`}
          element={<PrivateRoute layout={'customer'} mode={'read'} page={'Particularity'} />}
        />
        <Route
          path={`/${t('url.customer.edit-notary')}/:uuid`}
          element={<PrivateRoute layout={'customer'} mode={'edit'} page={'Notary'} />}
        />
        <Route
          path={`/${t('url.customer.read-notary')}/:uuid`}
          element={<PrivateRoute layout={'customer'} mode={'read'} page={'Notary'} />}
        />
        <Route
          path={`/${t('url.customer.edit-legal-representatives')}/:uuid`}
          element={<PrivateRoute layout={'customer'} mode={'edit'} page={'LegalRepresentatives'} />}
        />
        <Route
          path={`/${t('url.customer.edit-pledge')}/:uuid`}
          element={<PrivateRoute layout={'customer'} mode={'edit'} page={'Pledge'} />}
        />
        <Route
          path={`/${t('url.customer.read-pledge')}/:uuid`}
          element={<PrivateRoute layout={'customer'} mode={'read'} page={'Pledge'} />}
        />
        <Route
          path={`/${t('url.customer.edit-distribution')}/:uuid`}
          element={<PrivateRoute layout={'customer'} mode={'edit'} page={'Distribution'} />}
        />
        <Route
          path={`/${t('url.customer.read-distribution')}/:uuid`}
          element={<PrivateRoute layout={'customer'} mode={'read'} page={'Distribution'} />}
        />

        <Route
          path={`/${t('url.pre-subscription.dashboard')}`}
          element={<PrivateRoute component={PreSubscriptionDashboard} />}
        />
        <Route path={`/${t('url.pre-subscription.add')}`} element={<PrivateRoute component={PreSubscription} />} />
        <Route
          path={`/${t('url.pre-subscription.edit')}/:uuid`}
          element={<PrivateRoute component={PreSubscription} />}
        />
        <Route
          path={`/${t('url.pre-subscription.read')}/:uuid`}
          element={<PrivateRoute component={PreSubscription} />}
        />
        <Route
          path={`/${t('url.dismemberment-ledger.dashboard')}`}
          element={
            <PrivateRoute
              component={() => (
                <DismembermentLedgerProvider>
                  <DismembermentLedgerDashboard />
                </DismembermentLedgerProvider>
              )}
            />
          }
        />
        <Route
          path={`/${t('url.recurrence.mandate.list')}`}
          element={<PrivateRoute component={RecurrenceMandateList} />}
        />
        <Route path={`/${t('url.recurrence.mandate.detail')}/:mandateId/:mode`} element={<PrivateRoute component={RecurrenceMandateDetail} />} />
        <Route path={`/${t('url.recurrence.dashboard')}`} element={<PrivateRoute component={RecurrenceSession} />} />
        <Route
          path={`/${t('url.recurrence.session.add')}`}
          element={<PrivateRoute component={ChoiceRecurrenceSessionType} />}
        />
        <Route
          path={`/${t('url.recurrence.session.add-setting')}/:sessionType`}
          element={<PrivateRoute layout={'recurrence'} mode={'add'} page={'subscription'} />}
        />
        <Route
          path={`/${t('url.recurrence.session.edit-setting')}/:sessionType/:uuid`}
          element={<PrivateRoute layout={'recurrence'} mode={'edit'} page={'subscription'} />}
        />
        <Route
          path={`/${t('url.recurrence.session.read-setting')}/:sessionType/:uuid`}
          element={<PrivateRoute layout={'recurrence'} mode={'read'} page={'subscription'} />}
        />
        <Route
          path={`/${t('url.recurrence.session.edit-session')}/:sessionType/:uuid`}
          element={<PrivateRoute layout={'recurrence'} mode={'edit'} page={'subscription'} />}
        />
        <Route
          path={`/${t('url.recurrence.session.read-session')}/:sessionType/:uuid`}
          element={<PrivateRoute layout={'recurrence'} mode={'read'} page={'subscription'} />}
        />
        <Route
          path={`/${t('url.distribution.dashboard')}`}
          element={<PrivateRoute component={DistributionDashboard} />}
        />
        <Route
          path={`/${t('url.distribution.session.add')}`}
          element={<PrivateRoute component={ChoiceDistributionType} />}
        />
        <Route
          path={`/${t('url.distribution.session.add-setting')}/:sessionType`}
          element={<PrivateRoute layout={'distribution'} mode={'add'} page={'setting'} />}
        />
        <Route
          path={`/${t('url.distribution.session.edit-setting')}/:sessionType/:uuid`}
          element={<PrivateRoute layout={'distribution'} mode={'edit'} page={'setting'} />}
        />
        <Route
          path={`/${t('url.distribution.session.read-setting')}/:sessionType/:uuid`}
          element={<PrivateRoute layout={'distribution'} mode={'read'} page={'setting'} />}
        />
        <Route
          path={`/${t('url.distribution.session.edit-investor-list')}/:sessionType/:uuid`}
          element={<PrivateRoute layout={'distribution'} mode={'edit'} page={'investor-list'} />}
        />
        <Route
          path={`/${t('url.distribution.session.read-investor-list')}/:sessionType/:uuid`}
          element={<PrivateRoute layout={'distribution'} mode={'read'} page={'investor-list'} />}
        />

        <Route path={`/${t('url.movements.dashboard')}`} element={<PrivateRoute component={MovementDashboard} />} />
        <Route
          path={`/${t('url.pre-subscription.recurrence')}`}
          element={<PrivateRoute component={MovementRecurrence} />}
        />
        <Route
          path={`/${t('url.movements.dismenberment')}`}
          element={<PrivateRoute component={MovementDashboardDismemberment} />}
        />
        <Route path={`/${t('url.movements.choice-type')}`} element={<PrivateRoute component={MovementChoiceType} />} />
        <Route
          path={`/${t('url.movements.add-redemption-withdrawal')}`}
          element={<PrivateRoute component={MovementRedemptionWithdrawal} />}
        />
        <Route
          path={`/${t('url.movements.edit-redemption-withdrawal')}/:uuid`}
          element={<PrivateRoute component={MovementRedemptionWithdrawal} />}
        />
        <Route
          path={`/${t('url.movements.read-redemption-withdrawal')}/:uuid`}
          element={<PrivateRoute component={MovementRedemptionWithdrawal} />}
        />
        <Route path={`/${t('url.movements.add-gre-gre')}`} element={<PrivateRoute component={MovementAgreement} />} />
        <Route
          path={`/${t('url.movements.edit-gre-gre')}/:uuid`}
          element={<PrivateRoute component={MovementAgreement} />}
        />
        <Route
          path={`/${t('url.movements.read-gre-gre')}/:uuid`}
          element={<PrivateRoute component={MovementAgreement} />}
        />
        <Route path={`/${t('url.movements.add-mutations')}`} element={<PrivateRoute component={MovementMutation} />} />
        <Route
          path={`/${t('url.movements.edit-mutations')}/:uuid`}
          element={<PrivateRoute component={MovementMutation} />}
        />
        <Route
          path={`/${t('url.movements.read-mutations')}/:uuid`}
          element={<PrivateRoute component={MovementMutation} />}
        />
        <Route
          path={`/${t('url.movements.add-dismemberment')}`}
          element={<PrivateRoute component={MovementDismemberment} />}
        />
        <Route
          path={`/${t('url.movements.edit-dismemberment')}/:uuid`}
          element={<PrivateRoute component={MovementDismemberment} />}
        />
        <Route
          path={`/${t('url.movements.read-dismemberment')}/:uuid`}
          element={<PrivateRoute component={MovementDismemberment} />}
        />

        <Route
          path={`/${t('url.commissioning.dashboard')}`}
          element={<PrivateRoute component={CommissioningDashboard} />}
        />
        <Route
          path={`/${t('url.commissioning.add-choice-type')}`}
          element={<PrivateRoute component={CommissioningChoiceType} />}
        />
        <Route
          path={`/${t('url.commissioning.add-session')}/:commissioningType`}
          element={<PrivateRoute layout={'commissioning'} mode={'add'} page={'upfront'} />}
        />
        <Route
          path={`/${t('url.commissioning.edit-session')}/:commissioningType/:uuid`}
          element={<PrivateRoute layout={'commissioning'} mode={'edit'} page={'upfront'} />}
        />
        <Route
          path={`/${t('url.commissioning.read-session')}/:commissioningType/:uuid`}
          element={<PrivateRoute layout={'commissioning'} mode={'read'} page={'upfront'} />}
        />
        <Route
          path={`/${t('url.commissioning.add-setting')}/:commissioningType`}
          element={<PrivateRoute layout={'commissioning'} mode={'add'} page={'upfront'} />}
        />
        <Route
          path={`/${t('url.commissioning.edit-setting')}/:commissioningType/:uuid`}
          element={<PrivateRoute layout={'commissioning'} mode={'edit'} page={'upfront'} />}
        />
        <Route
          path={`/${t('url.commissioning.read-setting')}/:commissioningType/:uuid`}
          element={<PrivateRoute layout={'commissioning'} mode={'read'} page={'upfront'} />}
        />

        <Route path={`/${t('url.settings.general')}`} element={<PrivateRoute component={GeneralSettings} />} />
        <Route path={`/${t('url.settings.user-profiles')}`} element={<PrivateRoute component={UserProfiles} />} />
        <Route path={`/${t('url.settings.role-setting')}`} element={<PrivateRoute component={RoleSetting} />} />
        <Route
          path={`/${t('url.settings.software-setting')}`}
          element={<PrivateRoute component={SoftwareSettings} />}
        />

        <Route path='/logout' element={<Logout />} />

        <Route path={`/page-not-found/:errorCode`} element={<PrivateRoute component={ErrorPage} />} />

        <Route path='*' element={<Navigate to='/page-not-found/404' />} />
      </Routes>
    </BrowserRouter>
  )
}

export default Router
