import { City } from '../../Referentiel/City/City'
import { Country } from '../../Referentiel/Country/Country'

interface SettingsInterface {
  id: string | null
  createdAt: string | null
  updatedAt: string | null
  deletedAt: string | null
  companyName: string | null
  registration: string | null
  address1: string | null
  address2: string | null
  address3: string | null
  postcode: string | null
  city: City | null
  country: Country | null
  bankName: string | null
  bankIban: string | null
  bankSwift: string | null

  commissionPdfFormat: string | null
  commissionCsvFormat: string | null
  isEnableRecurrenceModifyPrice: boolean
}

class Settings implements SettingsInterface {
  constructor(
    public id: string | null = null,
    public createdAt: string | null = null,
    public updatedAt: string | null = null,
    public deletedAt: string | null = null,
    public companyName: string | null = null,
    public registration: string | null = null,
    public address1: string | null = null,
    public address2: string | null = null,
    public address3: string | null = null,
    public postcode: string | null = null,
    public city: City | null = null,
    public country: Country | null = null,
    public bankName: string | null = null,
    public bankIban: string | null = null,
    public bankSwift: string | null = null,
    public commissionPdfFormat: string | null = 'default',
    public commissionCsvFormat: string | null = 'default',
    public isEnableRecurrenceModifyPrice: boolean = false
  ) {
    this.id = id
    this.createdAt = createdAt
    this.updatedAt = updatedAt
    this.deletedAt = deletedAt
    this.companyName = companyName
    this.registration = registration
    this.address1 = address1
    this.address2 = address2
    this.address3 = address3
    this.postcode = postcode
    this.city = city
    this.country = country
    this.bankName = bankName
    this.bankIban = bankIban
    this.bankSwift = bankSwift

    this.commissionPdfFormat = commissionPdfFormat
    this.commissionCsvFormat = commissionCsvFormat
    this.isEnableRecurrenceModifyPrice = isEnableRecurrenceModifyPrice
  }
}

export { Settings }
export type { SettingsInterface }
