import Caller from '../../../domain/Api/Caller'
import { Settings, SettingsInterface } from '../../../domain/Settings/GeneralSettings/Settings'
import envVariable from '../../../infrastructure/presentation/util/envVariable'
import InitializeSettings from '../../../useCase/Settings/Initialization/InitializeSettings'

export default class GeneralSettingsGateway {
  get(): Promise<SettingsInterface | null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/settings`, {}).then(data => {
      return data
        ? new Settings(
            data.id,
            data.createdAt,
            data.updatedAt,
            data.deletedAt,
            data.companyName,
            data.registration,
            data.address1,
            data.address2,
            data.address3,
            data.postcode,
            data.city,
            data.country,
            data.bankName,
            data.bankIban,
            data.bankSwift,
            data.commissionPdfFormat,
            data.commissionCsvFormat,
            data.isEnableRecurrenceModifyPrice
          )
        : new InitializeSettings().initializeSettings()
    })
  }

  public async update(settings: SettingsInterface): Promise<{ uuid: string } | null> {
    return Caller.executePut(`${envVariable('REACT_APP_API_URL')}/settings`, {
      companyName: settings.companyName,
      registration: settings.registration,
      address1: settings.address1,
      address2: settings.address2,
      address3: settings.address3,
      postcode: settings.postcode,
      city: settings.city?.id,
      country: settings.country?.id,
      bankName: settings.bankName,
      bankIban: settings.bankIban,
      bankSwift: settings.bankSwift,
      commissionPdfFormat: settings.commissionPdfFormat,
      commissionCsvFormat: settings.commissionCsvFormat,
      isEnableRecurrenceModifyPrice: settings.isEnableRecurrenceModifyPrice,
    })
      .then(data => {
        return data || null
      })
      .catch(() => {
        return null
      })
  }
}
