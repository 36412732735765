import { GeneralAddressInterface } from '../../domain/Address/GeneralAddress'
import Caller from '../../domain/Api/Caller'
import { CallerType } from '../../domain/Caller/Caller'
import { BankInformation } from '../../domain/Customer/BankInformation'
import { CustomerInterface } from '../../domain/Customer/Customer'
import CustomerGatewayInterface from '../../domain/Customer/CustomerGatewayInterface'
import { CustomerLegalRepresentatives } from '../../domain/Customer/CustomerLegalRepresentatives'
import { CustomerListInterface, FilterCustomerInterface } from '../../domain/Customer/CustomerList'
import { Person } from '../../domain/Customer/Person'
import { Relation } from '../../domain/Customer/Relation'
import DateCleaner from '../../domain/Utils/DateCleaner'
import IdCleaner from '../../domain/Utils/IdCleaner'
import { KpiResponseInterface } from '../../infrastructure/presentation/Type/Kpi'
import envVariable from '../../infrastructure/presentation/util/envVariable'

export default class CustomerGateway implements CustomerGatewayInterface {
  getList(
    callerType: CallerType,
    currentPage: number,
    itemsPerPage: number,
    filter: FilterCustomerInterface | null,
    signal?: AbortSignal,
    sort?: string,
    order?: string
  ): Promise<{ data: CustomerListInterface[] | null; numberOfItems: number } | null> {
    return Caller.executeGet(
      `${envVariable('REACT_APP_API_URL')}/customers?params[nb_element_per_page]=${itemsPerPage}&params[page]=${currentPage}${sort ? '&sort=' + sort + '&order=' + order : ''}`,
      {
        lastName: filter?.lastName || '',
        maidenName: filter?.maidenName || '',
        keywords: filter?.keywords || '',
        birthDate: filter?.birthDate || '',
        status: filter?.status?.map(status => status.value) || '',
      },
      signal
    )
      .then(data => {
        if (data) {
          return data
        }

        return {
          data: [],
          numberOfItems: 0,
        }
      })
      .catch(response => {
        return {
          data: [],
          numberOfItems: 0,
        }
      })
  }

  add(customer: CustomerInterface): Promise<CustomerInterface | null> {
    return Caller.executePost(`${envVariable('REACT_APP_API_URL')}/customers`, {
      enabled: customer.enabled,
      customerType: customer.customerType,
      status: customer.status,
      statusCreatedAt: DateCleaner.clean(customer.statusCreatedAt),
      exitedAt: DateCleaner.clean(customer.exitedAt),
      blockedAt: DateCleaner.clean(customer.blockedAt),
      customerCode: customer.customerCode,
      moralType: customer.moralType,
      externalCode: customer.externalCode,
      externalDepositaryCode: customer.externalDepositaryCode,
      recoveryCode: customer.recoveryCode,
      legalStatus: customer.legalStatus,
      companyName: customer.companyName,
      siret: customer.siret,
      orias: customer.orias,
      kycCategoryValue: customer.kycCategoryValue,
      kycCategoryDate: DateCleaner.clean(customer.kycCategoryDate),
      kycLabft: customer.kycLabft,
      kycLabftDate: DateCleaner.clean(DateCleaner.clean(customer.kycLabftDate)),
      kycProfession: customer.kycProfession,
      kycFatca: customer.kycFatca,
      groupRelation: customer.groupRelation,
      kycCategoryValueSecond: customer.kycCategoryValueSecond,
      kycCategoryDateSecond: DateCleaner.clean(customer.kycCategoryDateSecond),
      kycLabftSecond: customer.kycLabftSecond,
      kycLabftDateSecond: DateCleaner.clean(DateCleaner.clean(customer.kycLabftDateSecond)),
      kycProfessionSecond: customer.kycProfessionSecond,
      kycFatcaSecond: customer.kycFatcaSecond,
      groupRelationSecond: customer.groupRelationSecond,
      providerCode: null,
      partner: customer.partner ? customer.partner.id : null,
      bankInformations: customer.bankInformations?.map((bankInformation: BankInformation) => {
        return {
          id: IdCleaner.clean(bankInformation.id),
          label: bankInformation.label,
          main: bankInformation.main,
          bank: bankInformation.bank,
          iban: bankInformation.iban,
          swift: bankInformation.swift,
          debitMandate: bankInformation.debitMandate,
          products: bankInformation.products,
          type: bankInformation.type,
          flux: bankInformation.flux,
          status: bankInformation.status,
        }
      }),
      beneficialOwners: customer.beneficialOwners?.map(beneficialOwner => {
        return {
          ppe: beneficialOwner.ppe,
          shareHolding: parseInt(beneficialOwner.shareholding),
          status: beneficialOwner.status,
          person: {
            ppe: beneficialOwner.ppe,
            ppeCategory: beneficialOwner.person?.ppeCategory,
            gerant: beneficialOwner.person?.gerant,
            title: beneficialOwner.person?.title,
            lastname: beneficialOwner.person?.lastname,
            firstname: beneficialOwner.person?.firstname,
            maidenName: beneficialOwner.person?.maidenName,
            birthdate: beneficialOwner.person?.birthdate ? DateCleaner.clean(beneficialOwner.person?.birthdate) : null,
            birthCountry: beneficialOwner.person?.birthCountry?.id,
            birthCity: beneficialOwner.person?.birthCity?.id,
            birthCityForeign: beneficialOwner.person?.birthCityForeign,
            nationality: beneficialOwner.person?.nationality?.id,
            address: {
              title: beneficialOwner.person?.address?.title,
              lastname: beneficialOwner.person?.address?.lastname,
              firstname: beneficialOwner.person?.address?.firstname,
              maidenName: beneficialOwner.person?.address?.maidenname,
              address: beneficialOwner.person?.address?.address,
              address2: beneficialOwner.person?.address?.address2,
              address3: beneficialOwner.person?.address?.address3,
              postcode: beneficialOwner.person?.address?.postcode,
              city: beneficialOwner.person?.address?.city?.id,
              country: beneficialOwner.person?.address?.country?.id,
            },
          },
        }
      }),
      undivideds: customer.undivideds?.map(undivided => {
        return {
          ppe: undivided.ppe,
          shareHolding: parseInt(undivided.shareHolding),
          status: undivided.status,
          person: {
            id: undivided.person?.id ? IdCleaner.clean(undivided.person?.id) : null,
            title: undivided.person?.title,
            lastname: undivided.person?.lastname,
            firstname: undivided.person?.firstname,
            maidenName: undivided.person?.maidenName,
            birthdate: undivided.person?.birthdate ? DateCleaner.clean(undivided.person?.birthdate) : null,
            birthCountry: undivided.person?.birthCountry?.id,
            birthCity: undivided.person?.birthCity?.id,
            birthCityForeign: undivided.person?.birthCityForeign,
            nationality: undivided.person?.nationality?.id,
            ppe: undivided.person?.ppe,
            gerant: undivided.person?.gerant,
            ppeCategory: undivided.person?.ppeCategory,
            address: {
              title: undivided.person?.address?.title,
              lastname: undivided.person?.address?.lastname,
              firstname: undivided.person?.address?.firstname,
              maidenName: undivided.person?.address?.maidenname,
              address: undivided.person?.address?.address,
              address2: undivided.person?.address?.address2,
              address3: undivided.person?.address?.address3,
              postcode: undivided.person?.address?.postcode,
              city: undivided.person?.address?.city?.id,
              country: undivided.person?.address?.country?.id,
            },
          },
        }
      }),
      moralLegalRepresentatives: customer.moralLegalRepresentatives?.map(moralLegalRepresentative => {
        return {
          ppe: moralLegalRepresentative.ppe,
          shareHolding: parseInt(moralLegalRepresentative.shareHolding),
          status: moralLegalRepresentative.status,
          person: {
            gerant: moralLegalRepresentative.person?.gerant,
            ppe: moralLegalRepresentative.ppe,
            ppeCategory: moralLegalRepresentative.person?.ppeCategory,
            title: moralLegalRepresentative.person?.title,
            lastname: moralLegalRepresentative.person?.lastname,
            firstname: moralLegalRepresentative.person?.firstname,
            maidenName: moralLegalRepresentative.person?.maidenName,
            birthdate: moralLegalRepresentative.person?.birthdate
              ? DateCleaner.clean(moralLegalRepresentative.person?.birthdate)
              : null,
            birthCountry: moralLegalRepresentative.person?.birthCountry?.id,
            birthCity: moralLegalRepresentative.person?.birthCity?.id,
            birthCityForeign: moralLegalRepresentative.person?.birthCityForeign,
            nationality: moralLegalRepresentative.person?.nationality?.id,
            address: {
              title: moralLegalRepresentative.person?.address?.title,
              lastname: moralLegalRepresentative.person?.address?.lastname,
              firstname: moralLegalRepresentative.person?.address?.firstname,
              maidenName: moralLegalRepresentative.person?.address?.maidenname,
              address: moralLegalRepresentative.person?.address?.address,
              address2: moralLegalRepresentative.person?.address?.address2,
              address3: moralLegalRepresentative.person?.address?.address3,
              postcode: moralLegalRepresentative.person?.address?.postcode,
              city: moralLegalRepresentative.person?.address?.city?.id,
              country: moralLegalRepresentative.person?.address?.country?.id,
            },
          },
        }
      }),
      legalRepresentatives: customer.legalRepresentatives?.map((legalRepresentative: CustomerLegalRepresentatives) => {
        return {
          status: legalRepresentative.status,
          person: {
            firstname: legalRepresentative.person?.firstname,
            lastname: legalRepresentative.person?.lastname,
            birthdate: legalRepresentative.person?.birthdate
              ? DateCleaner.clean(legalRepresentative.person?.birthdate)
              : null,
            title: legalRepresentative.person?.title,
            nationality: legalRepresentative.person?.nationality?.id,
            birthCountry: legalRepresentative.person?.birthCountry?.id,
            birthCity: legalRepresentative.person?.birthCity?.id,
            ppe: legalRepresentative.person?.ppe,
            ppeCategory: legalRepresentative.person?.ppeCategory,
            address: {
              firstname: legalRepresentative.person?.address?.firstname,
              lastname: legalRepresentative.person?.address?.lastname,
              address: legalRepresentative.person?.address?.address,
              address2: legalRepresentative.person?.address?.address2,
              address3: legalRepresentative.person?.address?.address3,
              city: legalRepresentative.person?.address?.city?.id,
              country: legalRepresentative.person?.address?.country?.id,
              postcode: legalRepresentative.person?.address?.postcode,
            },
          },
        }
      }),
      persons: customer.persons?.map((person: Person) => {
        return {
          id: person.id,
          type: person.type,
          gerant: person.gerant,
          jointDelegate: null,
          title: person.title,
          firstname: person.firstname,
          lastname: person.lastname,
          maidenName: person.maidenName,
          birthdate: DateCleaner.clean(person.birthdate),
          birthCity: person.birthCity ? person.birthCity.id : null,
          birthCityForeign: person.birthCityForeign,
          birthCountry: person.birthCountry ? person.birthCountry.id : null,
          nationality: person.nationality ? person.nationality.id : null,
          extranet: person.extranet,
          extranetCreatedAt: DateCleaner.clean(person.extranetCreatedAt),
          ppe: person.ppe,
          ppeCategory: person.ppeCategory,
          legalCapacity: person.legalCapacity,
          familySituation: person.familySituation,
          matrimonialRegime: person.matrimonialRegime,
          email: person.email,
          phone: person.phone,
          optinMarketing: person.optinMarketing
            ? {
                type: person.optinMarketing.type,
                date: null,
              }
            : null,
          optinDividend: person.optinDividend
            ? {
                type: person.optinDividend.type,
                date: null,
              }
            : null,
          optinFiscality: person.optinFiscality
            ? {
                type: person.optinFiscality.type,
                date: null,
              }
            : null,
          optinGeneralAssembly: person.optinGeneralAssembly
            ? {
                type: person.optinGeneralAssembly.type,
                date: null,
              }
            : null,
          optinInvitation: person.optinInvitation
            ? {
                type: person.optinInvitation.type,
                date: null,
              }
            : null,
          optinAll: person.optinAll
            ? {
                type: person.optinAll.type,
                date: null,
              }
            : null,
          address: person.address
            ? {
                id: person.address.id,
                title: person.address.title,
                firstname: person.address.firstname,
                lastname: person.address.lastname,
                maidenName: null,
                address: person.address.address,
                address2: person.address.address2,
                address3: person.address.address3,
                postcode: person.address.postcode,
                city: person.address.city?.id,
                country: person.address.country?.id,
                npaiAt: DateCleaner.clean(person.address.npaiAt),
                npaiRemovedAt: null,
              }
            : null,
          fiscality: person.fiscality
            ? {
                id: person.fiscality.id,
                paymentMethod: person.fiscality.paymentMethod,
                taxResidency: person.fiscality.taxResidency,
                rate: person.fiscality.rate,
                businessTax: person.fiscality.businessTax,
                incomeTax: person.fiscality.incomeTax,
                subjectIfi: person.fiscality.subjectIfi,
                ifi: person.fiscality.ifi,
                lei: person.fiscality.lei,
                nif: person.fiscality.nif,
              }
            : null,
          documents: person.documents,
        }
      }),
      customerAddresses: customer.customerAddresses?.map((customerAddress: GeneralAddressInterface) => {
        return {
          main: customerAddress.main,
          type: customerAddress.type,
          status: customerAddress.status,
          address: customerAddress.address
            ? {
                id: customerAddress.address.id,
                title: customerAddress.address.title,
                firstname: customerAddress.address.firstname,
                lastname: customerAddress.address.lastname,
                maidenName: null,
                address: customerAddress.address.address,
                address2: customerAddress.address.address2,
                address3: customerAddress.address.address3,
                postcode: customerAddress.address.postcode,
                city: customerAddress.address.city?.id,
                country: customerAddress.address.country?.id,
                npaiAt: DateCleaner.clean(customerAddress.address.npaiAt),
                npaiRemovedAt: null,
              }
            : null,
        }
      }),
      relations: customer.relations?.map((relation: Relation) => {
        return {
          personId: relation.personId,
          relation: relation.relation,
        }
      }),
      fiscality: {
        paymentMethod: customer.fiscality?.paymentMethod,
        taxResidency: customer.fiscality?.taxResidency,
        rate: customer.fiscality?.rate,
        businessTax: customer.fiscality?.businessTax,
        incomeTax: customer.fiscality?.incomeTax,
        subjectIfi: customer.fiscality?.subjectIfi,
        ifi: customer.fiscality?.ifi,
        lei: customer.fiscality?.lei,
        nif: customer.fiscality?.nif,
        withholdingTax: customer.fiscality?.withholdingTax,
      },
      notary: {
        id: customer.notary?.id,
        deceasedAt: customer.notary?.deceasedAt,
        blockedAt: customer.notary?.blockedAt,
        title: customer.notary?.title,
        firstname: customer.notary?.firstname,
        lastname: customer.notary?.lastname,
        address: customer.notary?.address,
        address2: customer.notary?.address2,
        postcode: customer.notary?.postcode,
        city: customer.notary?.city?.id,
        country: customer.notary?.country?.id,
        phone: customer.notary?.phone,
        email: customer.notary?.email,
        office: customer.notary?.office,
        sellIntention: customer.notary?.sellIntention,
        successionFee: customer.notary?.successionFee,
        successionBill: customer.notary?.successionBill,
        start: customer.notary?.successionPeriod?.start,
        end: customer.notary?.successionPeriod?.end,
        quasiUsufruit: customer.notary?.quasiUsufruit ?? false,
      },
      sameFiscalAddress: customer.sameFiscalAddress,
      distributionPaymentPeriodicity: customer.distributionPaymentPeriodicity,
    }).then(data => {
      if (data.uuid) {
        return data.uuid
      }

      return null
    })
  }

  get(id: string): Promise<CustomerInterface | null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/customers/${id}`, {}).then(data => {
      if (data) {
        return data
      }

      return null
    })
  }

  update(customer: CustomerInterface): Promise<CustomerInterface | null> {
    return Caller.executePut(`${envVariable('REACT_APP_API_URL')}/customers/${customer.id}`, {
      enabled: customer.enabled,
      customerType: customer.customerType,
      status: customer.status,
      statusCreatedAt: DateCleaner.clean(customer.statusCreatedAt),
      exitedAt: DateCleaner.clean(customer.exitedAt),
      blockedAt: DateCleaner.clean(customer.blockedAt),
      customerCode: customer.customerCode,
      moralType: customer.moralType,
      externalCode: customer.externalCode,
      externalDepositaryCode: customer.externalDepositaryCode,
      recoveryCode: customer.recoveryCode,
      legalStatus: customer.legalStatus,
      companyName: customer.companyName,
      siret: customer.siret,
      orias: customer.orias,
      kycCategoryValue: customer.kycCategoryValue,
      kycCategoryDate: DateCleaner.clean(customer.kycCategoryDate),
      kycLabft: customer.kycLabft,
      kycLabftDate: DateCleaner.clean(customer.kycLabftDate),
      kycProfession: customer.kycProfession,
      kycFatca: customer.kycFatca,
      groupRelation: customer.groupRelation,
      kycCategoryValueSecond: customer.kycCategoryValueSecond,
      kycCategoryDateSecond: DateCleaner.clean(customer.kycCategoryDateSecond),
      kycLabftSecond: customer.kycLabftSecond,
      kycLabftDateSecond: DateCleaner.clean(DateCleaner.clean(customer.kycLabftDateSecond)),
      kycProfessionSecond: customer.kycProfessionSecond,
      kycFatcaSecond: customer.kycFatcaSecond,
      groupRelationSecond: customer.groupRelationSecond,
      providerCode: null,
      partner: customer.partner ? customer.partner.id : null,
      bankInformations: customer.bankInformations?.map((bankInformation: BankInformation) => {
        return {
          id: bankInformation.id ? IdCleaner.clean(bankInformation.id) : null,
          label: bankInformation.label,
          main: bankInformation.main,
          bank: bankInformation.bank,
          iban: bankInformation.iban,
          swift: bankInformation.swift,
          debitMandate: bankInformation.debitMandate,
          products: bankInformation.products,
          type: bankInformation.type,
          flux: bankInformation.flux,
          status: bankInformation.status,
        }
      }),
      legalRepresentatives: customer.legalRepresentatives?.map((legalRepresentative: CustomerLegalRepresentatives) => {
        return {
          status: legalRepresentative.status,
          person: {
            id: legalRepresentative.person?.id ? IdCleaner.clean(legalRepresentative.person.id) : null,
            firstname: legalRepresentative.person?.firstname,
            lastname: legalRepresentative.person?.lastname,
            birthdate: legalRepresentative.person?.birthdate
              ? DateCleaner.clean(legalRepresentative.person?.birthdate)
              : null,
            title: legalRepresentative.person?.title,
            nationality: legalRepresentative.person?.nationality?.id,
            birthCountry: legalRepresentative.person?.birthCountry?.id,
            birthCity: legalRepresentative.person?.birthCity?.id,
            ppe: legalRepresentative.person?.ppe,
            ppeCategory: legalRepresentative.person?.ppeCategory,
            address: {
              id: legalRepresentative.person?.address?.id
                ? IdCleaner.clean(legalRepresentative.person?.address?.id)
                : null,
              firstname: legalRepresentative.person?.address?.firstname,
              lastname: legalRepresentative.person?.address?.lastname,
              address: legalRepresentative.person?.address?.address,
              address2: legalRepresentative.person?.address?.address2,
              address3: legalRepresentative.person?.address?.address3,
              city: legalRepresentative.person?.address?.city?.id,
              country: legalRepresentative.person?.address?.country?.id,
              postcode: legalRepresentative.person?.address?.postcode,
            },
          },
        }
      }),
      persons: customer.persons?.map((person: Person) => {
        return {
          id: person.id,
          type: person.type,
          gerant: person.gerant,
          jointDelegate: null,
          title: person.title,
          firstname: person.firstname,
          lastname: person.lastname,
          maidenName: person.maidenName,
          birthdate: DateCleaner.clean(person.birthdate),
          birthCity: person.birthCity ? person.birthCity.id : null,
          birthCityForeign: person.birthCityForeign,
          birthCountry: person.birthCountry ? person.birthCountry.id : null,
          nationality: person.nationality ? person.nationality.id : null,
          extranet: person.extranet,
          extranetCreatedAt: DateCleaner.clean(person.extranetCreatedAt),
          ppe: person.ppe,
          ppeCategory: person.ppeCategory,
          legalCapacity: person.legalCapacity,
          familySituation: person.familySituation,
          matrimonialRegime: person.matrimonialRegime,
          email: person.email,
          phone: person.phone,
          optinMarketing: person.optinMarketing
            ? {
                type: person.optinMarketing.type,
                date: null,
              }
            : null,
          optinDividend: person.optinDividend
            ? {
                type: person.optinDividend.type,
                date: null,
              }
            : null,
          optinFiscality: person.optinFiscality
            ? {
                type: person.optinFiscality.type,
                date: null,
              }
            : null,
          optinGeneralAssembly: person.optinGeneralAssembly
            ? {
                type: person.optinGeneralAssembly.type,
                date: null,
              }
            : null,
          optinInvitation: person.optinInvitation
            ? {
                type: person.optinInvitation.type,
                date: null,
              }
            : null,
          optinAll: person.optinAll
            ? {
                type: person.optinAll.type,
                date: null,
              }
            : null,
          address: person.address
            ? {
                id: person.address.id,
                title: person.address.title,
                firstname: person.address.firstname,
                lastname: person.address.lastname,
                maidenName: null,
                address: person.address.address,
                address2: person.address.address2,
                address3: person.address.address3,
                postcode: person.address.postcode,
                city: person.address.city?.id,
                country: person.address.country?.id,
                npaiAt: DateCleaner.clean(person.address.npaiAt),
                npaiRemovedAt: null,
              }
            : null,
          fiscality: person.fiscality
            ? {
                id: person.fiscality.id,
                paymentMethod: person.fiscality.paymentMethod,
                taxResidency: person.fiscality.taxResidency,
                rate: person.fiscality.rate,
                businessTax: person.fiscality.businessTax,
                incomeTax: person.fiscality.incomeTax,
                subjectIfi: person.fiscality.subjectIfi,
                ifi: person.fiscality.ifi,
                lei: person.fiscality.lei,
                nif: person.fiscality.nif,
                withholdingTax: person.fiscality.withholdingTax,
              }
            : null,
          documents: person.documents,
        }
      }),
      customerAddresses: customer.customerAddresses?.map((customerAddress: GeneralAddressInterface) => {
        return {
          main: customerAddress.main,
          type: customerAddress.type,
          status: customerAddress.status,
          address: customerAddress.address
            ? {
                id: customerAddress.address.id ? IdCleaner.clean(customerAddress.address.id) : null,
                title: customerAddress.address.title,
                firstname: customerAddress.address.firstname,
                lastname: customerAddress.address.lastname,
                maidenName: null,
                address: customerAddress.address.address,
                address2: customerAddress.address.address2,
                address3: customerAddress.address.address3,
                postcode: customerAddress.address.postcode,
                city: customerAddress.address.city?.id,
                country: customerAddress.address.country?.id,
                npaiAt: DateCleaner.clean(customerAddress.address.npaiAt),
                npaiRemovedAt: null,
                socialReason: customerAddress.address?.socialReason,
              }
            : null,
        }
      }),
      relations: customer.relations?.map((relation: Relation) => {
        return {
          personId: IdCleaner.clean(relation.personId),
          relation: relation.relation,
        }
      }),
      beneficialOwners: customer.beneficialOwners?.map(beneficialOwner => {
        return {
          ppe: beneficialOwner.ppe,
          shareHolding: parseInt(beneficialOwner.shareholding),
          status: beneficialOwner.status,
          person: {
            id: beneficialOwner.person?.id ? IdCleaner.clean(beneficialOwner.person?.id) : null,
            ppe: beneficialOwner.ppe,
            gerant: beneficialOwner.person?.gerant,
            ppeCategory: beneficialOwner.person?.ppeCategory,
            title: beneficialOwner.person?.title,
            lastname: beneficialOwner.person?.lastname,
            firstname: beneficialOwner.person?.firstname,
            maidenName: beneficialOwner.person?.maidenName,
            birthdate: beneficialOwner.person?.birthdate ? DateCleaner.clean(beneficialOwner.person?.birthdate) : null,
            birthCountry: beneficialOwner.person?.birthCountry?.id,
            birthCity: beneficialOwner.person?.birthCity?.id,
            birthCityForeign: beneficialOwner.person?.birthCityForeign,
            nationality: beneficialOwner.person?.nationality?.id,
            address: {
              title: beneficialOwner.person?.address?.title,
              lastname: beneficialOwner.person?.address?.lastname,
              firstname: beneficialOwner.person?.address?.firstname,
              maidenName: beneficialOwner.person?.address?.maidenname,
              address: beneficialOwner.person?.address?.address,
              address2: beneficialOwner.person?.address?.address2,
              address3: beneficialOwner.person?.address?.address3,
              postcode: beneficialOwner.person?.address?.postcode,
              city: beneficialOwner.person?.address?.city?.id,
              country: beneficialOwner.person?.address?.country?.id,
            },
          },
        }
      }),
      undivideds: customer.undivideds?.map(undivided => {
        return {
          ppe: undivided.ppe,
          shareHolding: parseInt(undivided.shareHolding),
          status: undivided.status,
          person: {
            id: undivided.person?.id ? IdCleaner.clean(undivided.person?.id) : null,
            title: undivided.person?.title,
            lastname: undivided.person?.lastname,
            firstname: undivided.person?.firstname,
            maidenName: undivided.person?.maidenName,
            birthdate: undivided.person?.birthdate ? DateCleaner.clean(undivided.person?.birthdate) : null,
            birthCountry: undivided.person?.birthCountry?.id,
            birthCity: undivided.person?.birthCity?.id,
            birthCityForeign: undivided.person?.birthCityForeign,
            nationality: undivided.person?.nationality?.id,
            ppe: undivided.person?.ppe,
            gerant: undivided.person?.gerant,
            ppeCategory: undivided.person?.ppeCategory,
            address: {
              title: undivided.person?.address?.title,
              lastname: undivided.person?.address?.lastname,
              firstname: undivided.person?.address?.firstname,
              maidenName: undivided.person?.address?.maidenname,
              address: undivided.person?.address?.address,
              address2: undivided.person?.address?.address2,
              address3: undivided.person?.address?.address3,
              postcode: undivided.person?.address?.postcode,
              city: undivided.person?.address?.city?.id,
              country: undivided.person?.address?.country?.id,
            },
          },
        }
      }),
      moralLegalRepresentatives: customer.moralLegalRepresentatives?.map(moralLegalRepresentative => {
        return {
          ppe: moralLegalRepresentative.ppe,
          shareHolding: parseInt(moralLegalRepresentative.shareHolding),
          status: moralLegalRepresentative.status,
          person: {
            id: moralLegalRepresentative.person?.id ? IdCleaner.clean(moralLegalRepresentative.person?.id) : null,
            ppe: moralLegalRepresentative.ppe,
            gerant: moralLegalRepresentative.person?.gerant,
            ppeCategory: moralLegalRepresentative.person?.ppeCategory,
            title: moralLegalRepresentative.person?.title,
            lastname: moralLegalRepresentative.person?.lastname,
            firstname: moralLegalRepresentative.person?.firstname,
            maidenName: moralLegalRepresentative.person?.maidenName,
            birthdate: moralLegalRepresentative.person?.birthdate
              ? DateCleaner.clean(moralLegalRepresentative.person?.birthdate)
              : null,
            birthCountry: moralLegalRepresentative.person?.birthCountry?.id,
            birthCity: moralLegalRepresentative.person?.birthCity?.id,
            birthCityForeign: moralLegalRepresentative.person?.birthCityForeign,
            nationality: moralLegalRepresentative.person?.nationality?.id,
            address: {
              title: moralLegalRepresentative.person?.address?.title,
              lastname: moralLegalRepresentative.person?.address?.lastname,
              firstname: moralLegalRepresentative.person?.address?.firstname,
              maidenName: moralLegalRepresentative.person?.address?.maidenname,
              address: moralLegalRepresentative.person?.address?.address,
              address2: moralLegalRepresentative.person?.address?.address2,
              address3: moralLegalRepresentative.person?.address?.address3,
              postcode: moralLegalRepresentative.person?.address?.postcode,
              city: moralLegalRepresentative.person?.address?.city?.id,
              country: moralLegalRepresentative.person?.address?.country?.id,
            },
          },
        }
      }),
      notary: {
        id: customer.notary?.id,
        deceasedAt: customer.notary?.deceasedAt,
        blockedAt: customer.notary?.blockedAt,
        title: customer.notary?.title,
        firstname: customer.notary?.firstname,
        lastname: customer.notary?.lastname,
        address: customer.notary?.address,
        address2: customer.notary?.address2,
        postcode: customer.notary?.postcode,
        city: customer.notary?.city?.id,
        country: customer.notary?.country?.id,
        phone: customer.notary?.phone,
        email: customer.notary?.email,
        office: customer.notary?.office,
        sellIntention: customer.notary?.sellIntention,
        successionFee: customer.notary?.successionFee,
        successionBill: customer.notary?.successionBill,
        start: customer.notary?.successionPeriod?.start,
        end: customer.notary?.successionPeriod?.end,
        quasiUsufruit: customer.notary?.quasiUsufruit ?? false,
      },
      sameFiscalAddress: customer.sameFiscalAddress,
      distributionPaymentPeriodicity: customer.distributionPaymentPeriodicity,
    }).then(data => {
      if (data.uuid) {
        return data.uuid
      }

      return null
    })
  }

  getExport(filter: FilterCustomerInterface | null): Promise<string | null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/customers/extract`, {
      lastName: filter?.lastName || '',
      maidenName: filter?.maidenName || '',
      keywords: filter?.keywords || '',
      birthDate: filter?.birthDate || '',
    })
      .then(data => {
        if (data && data.content) {
          return data.content
        }

        return null
      })
      .catch(response => {
        return null
      })
  }

  getKpi(): Promise<KpiResponseInterface[] | null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/customers/kpi`, {})
      .then(data => {
        if (data) {
          return data
        }

        return null
      })
      .catch(response => {
        return null
      })
  }
}
