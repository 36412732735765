import React, { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import { SubmitHandler, useForm, useFormState } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
// Notification lib
import 'react-toastify/dist/ReactToastify.min.css'
import { v4 as uuidV4 } from 'uuid'
import addIcon from '../../../../../../assets/images/icons/add.svg'
import { AddressInterface } from '../../../../../../domain/Address/Address'
import { DocumentInterface, DocumentType } from '../../../../../../domain/Document/DocumentInterface'
import { CommunicationInterface } from '../../../../../../domain/Prospect/Communication'
import { FiscalityInterface } from '../../../../../../domain/Prospect/Fiscality'
import { ProspectInterface } from '../../../../../../domain/Prospect/Prospect'
import { City } from '../../../../../../domain/Referentiel/City/City'
import { Country } from '../../../../../../domain/Referentiel/Country/Country'
import { ReferentielInterface } from '../../../../../../domain/Referentiel/ReferentielInterface'
import DocumentGateway from '../../../../../../gateway/Prospect/Document/DocumentGateway'
import ProspectGateway from '../../../../../../gateway/Prospect/ProspectGateway'
import AddUseCase from '../../../../../../useCase/Prospect/Add/AddUseCase'
import InitializePerson from '../../../../../../useCase/Prospect/Initialization/InitializePerson'
import InitializeProspect from '../../../../../../useCase/Prospect/Initialization/InitializeProspect'
import UpdateUseCase from '../../../../../../useCase/Prospect/Update/UpdateUseCase'
import { setIsLoading } from '../../../../../store/component/event'
import { reloadProspect } from '../../../../../store/component/prospect'
import { useAppDispatch, useAppSelector } from '../../../../../store/hook'
import DocumentUtils from '../../../../util/DocumentUtils'
import { usePrompt } from '../../../../util/Navigation'
import { setReadOnlyFormElements } from '../../../../util/setReadOnlyFormElements'
import { toastError, toastSuccess } from '../../../../util/Toast'
import AlertInfo from '../../../Alert/AlertInfo/AlertInfo'
import FormErrorInfo from '../../../Alert/FormErrorInfo/FormErrorInfo'
import DocumentHistoryBlock from '../../../Block/DocumentHistoryBlock'
import FooterBlock from '../../../Block/FooterBlock'
import CityDatalist from '../../../Datalist/City/CityDatalist'
import CountryDatalist from '../../../Datalist/Country/CountryDatalist'
import CheckboxToggle from '../../../Elements/CheckboxToggle'
import Input from '../../../Elements/Input'
import InputDate from '../../../Elements/InputDate'
import RadioYesOrNot from '../../../Elements/RadioYesOrNot'
import SelectCustom from '../../../Elements/Select'
import ConfirmationModal from '../../../Modal/ConfirmationModal'
import DocumentForm from '../../Document/Form'

interface IFormInput {
  prospectCode: string | null
  externalDepositaryCode: string | null
  externalCode: string | null
  recoveryCode: string | null
  statusCreatedAt: string | null
  status: string | null
  exitedAt: string | null
  blockedAt: string | null

  personFirstId: string | null
  personFirstCreatedAt: string | null
  personFirstUpdatedAt: string | null
  personFirstDeletedAt: string | null
  personFirstType: string | null
  personFirstGerant: string | null
  personFirstTitle: string | null
  personFirstFirstname: string | null
  personFirstLastname: string | null
  personFirstMaidenname: string | null
  personFirstBirthdate: string | null
  personFirstBirthCity: City | null
  personFirstBirthCityForeign: string | null
  personFirstBirthCountry: Country | null
  personFirstNationality: Country | null
  personFirstPpe: boolean
  personFirstPpeCategory: string | null
  personFirstLegalCapacity: string | null
  personFirstFamilySituation: string | null
  personFirstMatrimonialRegime: string | null
  personFirstEmail: string | null
  personFirstPhone: string | null
  personFirstExtranet: boolean
  personFirstExtranetCreatedAt: string | null
  personFirstOptinMarketing: CommunicationInterface | null
  personFirstOptinDividend: CommunicationInterface | null
  personFirstOptinFiscality: CommunicationInterface | null
  personFirstOptinGeneralAssembly: CommunicationInterface | null
  personFirstOptinInvitation: CommunicationInterface | null
  personFirstOptinAll: CommunicationInterface | null
  personFirstAddress: AddressInterface | null
  personFirstFiscality: FiscalityInterface | null

  personSecondId: string | null
  personSecondCreatedAt: string | null
  personSecondUpdatedAt: string | null
  personSecondDeletedAt: string | null
  personSecondType: string | null
  personSecondGerant: string | null
  personSecondTitle: string | null
  personSecondFirstname: string | null
  personSecondLastname: string | null
  personSecondMaidenname: string | null
  personSecondBirthdate: string | null
  personSecondBirthCity: City | null
  personSecondBirthCityForeign: string | null
  personSecondBirthCountry: Country | null
  personSecondNationality: Country | null
  personSecondPpe: boolean
  personSecondPpeCategory: string | null
  personSecondLegalCapacity: string | null
  personSecondFamilySituation: string | null
  personSecondMatrimonialRegime: string | null
  personSecondEmail: string | null
  personSecondPhone: string | null
  personSecondExtranet: boolean
  personSecondExtranetCreatedAt: string | null
  personSecondOptinMarketing: CommunicationInterface | null
  personSecondOptinDividend: CommunicationInterface | null
  personSecondOptinFiscality: CommunicationInterface | null
  personSecondOptinGeneralAssembly: CommunicationInterface | null
  personSecondOptinInvitation: CommunicationInterface | null
  personSecondOptinAll: CommunicationInterface | null
  personSecondAddress: AddressInterface | null
  personSecondFiscality: FiscalityInterface | null

  personFirstKycCategoryValue: string | null
  personFirstKycCategoryDate: string | null
  personFirstKycLabft: string | null
  personFirstKycLabftDate: string | null
  personFirstKycProfession: string | null
  personFirstKycFatca: boolean | null
  personFirstGroupRelation: string | null

  personSecondKycCategoryValue: string | null
  personSecondKycCategoryDate: string | null
  personSecondKycLabft: string | null
  personSecondKycLabftDate: string | null
  personSecondKycProfession: string | null
  personSecondKycFatca: boolean | null
  personSecondGroupRelation: string | null

  personFirstFiscalityIncomeTax: string | null
  personFirstFiscalitySubjectIfi: string | null
  personFirstFiscalityWithholdingTax: string | null
  personSecondFiscalityIncomeTax: string | null
  personSecondFiscalitySubjectIfi: string | null
  personSecondFiscalityWithholdingTax: string | null
  prospectExtranetRadio: string | null

  documents: {
    [key: string]: {
      [key: string]: {
        date: string
        updatedAt: string
      }
    }
  }
  distributionPaymentPeriodicity: string | null
}

type Props = {
  setStatus: React.Dispatch<React.SetStateAction<string>>
  referential: ReferentielInterface
  prospect?: ProspectInterface
  isLectureMode?: boolean
  handler?: any
}

const GeneralInformationPhysicalPerson: FunctionComponent<Props> = ({
  prospect,
  isLectureMode,
  handler,
  referential,
  setStatus,
}) => {
  const { t } = useTranslation()
  const { prospectType } = useParams()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const countryByDefault = useAppSelector(({ referential }) => referential.countryByDefault)
  const prospectStore = useAppSelector(state => state.prospect)
  const isLoading = useAppSelector(state => state.event.isLoading)
  const [formErrors, setFormErrors] = useState<string[]>([])
  const [documentArray, setDocumentArray] = useState<DocumentType>({})
  const [documentHistoryArray, setDocumentHistoryArray] = useState<DocumentInterface[]>([])
  const [displaySecondInvestForm, setDisplaySecondInvestForm] = useState<boolean>(false)
  const openUploadForm = useAppSelector(state => state.event.openUploadForm)
  const documentUtils = new DocumentUtils()

  useEffect(() => {
    if (prospect) {
      setValue('prospectCode', prospect.prospectCode)
      setValue('externalDepositaryCode', prospect.externalDepositaryCode)
      setValue('externalCode', prospect.externalCode)
      setValue('recoveryCode', prospect.recoveryCode)
      setValue('statusCreatedAt', prospect.createdAt)
      setValue('status', prospect.status)
      setValue('exitedAt', prospect.exitedAt)
      setValue('blockedAt', prospect.blockedAt)
      setValue('distributionPaymentPeriodicity', prospect.distributionPaymentPeriodicity)
      if (prospect.persons !== null && prospect.persons[0]) {
        setValue('personFirstId', prospect.persons[0].id)
        setValue('personFirstCreatedAt', prospect.persons[0].createdAt)
        setValue('personFirstUpdatedAt', prospect.persons[0].updatedAt)
        setValue('personFirstDeletedAt', prospect.persons[0].deletedAt)
        setValue('personFirstType', prospect.persons[0].type)
        setValue('personFirstGerant', prospect.persons[0].gerant ? '1' : '0')
        setValue('personFirstTitle', prospect.persons[0].title)
        setValue('personFirstFirstname', prospect.persons[0].firstname)
        setValue('personFirstLastname', prospect.persons[0].lastname)
        setValue('personFirstMaidenname', prospect.persons[0].maidenName)
        setValue('personFirstBirthdate', prospect.persons[0].birthdate)
        setValue('personFirstBirthCity', prospect.persons[0].birthCity)
        setValue('personFirstBirthCityForeign', prospect.persons[0].birthCityForeign)
        setValue('personFirstPpe', prospect.persons[0].ppe ?? false)
        setValue('personFirstPpeCategory', prospect.persons[0].ppeCategory)
        setValue('personFirstLegalCapacity', prospect.persons[0].legalCapacity)
        setValue('personFirstFamilySituation', prospect.persons[0].familySituation)
        setValue('personFirstMatrimonialRegime', prospect.persons[0].matrimonialRegime)
        setValue('personFirstEmail', prospect.persons[0].email)
        setValue('personFirstPhone', prospect.persons[0].phone)
        setValue('personFirstExtranet', prospect.persons[0].extranet ?? false)
        setValue('personFirstExtranetCreatedAt', prospect.persons[0].extranetCreatedAt)
        setValue('personFirstOptinMarketing', prospect.persons[0].optinMarketing)
        setValue('personFirstOptinDividend', prospect.persons[0].optinDividend)
        setValue('personFirstOptinFiscality', prospect.persons[0].optinFiscality)
        setValue('personFirstOptinGeneralAssembly', prospect.persons[0].optinGeneralAssembly)
        setValue('personFirstOptinInvitation', prospect.persons[0].optinInvitation)
        setValue('personFirstAddress', prospect.persons[0].address)
        setValue('personFirstFiscality', prospect.persons[0].fiscality)
        setValue('prospectExtranetRadio', prospect.persons[0].extranet ? '1' : '0')

        if (prospect.persons[0].fiscality) {
          setValue('personFirstFiscalityIncomeTax', prospect.persons[0].fiscality.incomeTax ? '1' : '0')
          setValue('personFirstFiscalitySubjectIfi', prospect.persons[0].fiscality?.subjectIfi ? '1' : '0')
          setValue('personFirstFiscalityWithholdingTax', prospect.persons[0].fiscality?.withholdingTax ? '1' : '0')
        }
      }

      if (prospect.persons !== null && prospect.persons[1]) {
        if (prospect.persons[1].id) {
          setDisplaySecondInvestForm(true)
        }
        setValue('personSecondId', prospect.persons[1].id)
        setValue('personSecondCreatedAt', prospect.persons[1].createdAt)
        setValue('personSecondUpdatedAt', prospect.persons[1].updatedAt)
        setValue('personSecondDeletedAt', prospect.persons[1].deletedAt)
        setValue('personSecondType', prospect.persons[1].type)
        setValue('personSecondGerant', prospect.persons[1].gerant ? '1' : '0')
        setValue('personSecondTitle', prospect.persons[1].title)
        setValue('personSecondFirstname', prospect.persons[1].firstname)
        setValue('personSecondLastname', prospect.persons[1].lastname)
        setValue('personSecondMaidenname', prospect.persons[1].maidenName)
        setValue('personSecondBirthdate', prospect.persons[1].birthdate)
        setValue('personSecondBirthCity', prospect.persons[1].birthCity)
        setValue('personSecondBirthCityForeign', prospect.persons[1].birthCityForeign)
        setValue('personSecondPpe', prospect.persons[1].ppe ?? false)
        setValue('personSecondPpeCategory', prospect.persons[1].ppeCategory)
        setValue('personSecondLegalCapacity', prospect.persons[1].legalCapacity)
        setValue('personSecondFamilySituation', prospect.persons[1].familySituation)
        setValue('personSecondMatrimonialRegime', prospect.persons[1].matrimonialRegime)
        setValue('personSecondEmail', prospect.persons[1].email)
        setValue('personSecondPhone', prospect.persons[1].phone)
        setValue('personSecondExtranet', prospect.persons[1].extranet ?? false)
        setValue('personSecondExtranetCreatedAt', prospect.persons[1].extranetCreatedAt)
        setValue('personSecondOptinMarketing', prospect.persons[1].optinMarketing)
        setValue('personSecondOptinDividend', prospect.persons[1].optinDividend)
        setValue('personSecondOptinFiscality', prospect.persons[1].optinFiscality)
        setValue('personSecondOptinGeneralAssembly', prospect.persons[1].optinGeneralAssembly)
        setValue('personSecondOptinInvitation', prospect.persons[1].optinInvitation)
        setValue('personSecondAddress', prospect.persons[1].address)
        setValue('personSecondFiscality', prospect.persons[1].fiscality)

        if (prospect.persons[1].fiscality) {
          setValue('personSecondFiscalityIncomeTax', prospect.persons[1].fiscality.incomeTax ? '1' : '0')
        }
        if (prospect.persons[1].fiscality) {
          setValue('personSecondFiscalitySubjectIfi', prospect.persons[1].fiscality.subjectIfi ? '1' : '0')
        }
      }

      setValue('personFirstKycCategoryValue', prospect.kycCategoryValue)
      setValue('personFirstKycCategoryDate', prospect.kycCategoryDate)
      setValue('personFirstKycLabft', prospect.kycLabft)
      setValue('personFirstKycLabftDate', prospect.kycLabftDate)
      setValue('personFirstKycProfession', prospect.kycProfession)
      setValue('personFirstKycFatca', prospect.kycFatca)
      setValue('personFirstGroupRelation', prospect.groupRelation)

      setValue('personSecondKycCategoryValue', prospect.kycCategoryValueSecond)
      setValue('personSecondKycCategoryDate', prospect.kycCategoryDateSecond)
      setValue('personSecondKycLabft', prospect.kycLabftSecond)
      setValue('personSecondKycLabftDate', prospect.kycLabftDateSecond)
      setValue('personSecondKycProfession', prospect.kycProfessionSecond)
      setValue('personSecondKycFatca', prospect.kycFatcaSecond)
      setValue('personSecondGroupRelation', prospect.groupRelationSecond)
    }

    setValue(
      'personFirstFiscality.taxResidency',
      prospect &&
        prospect.persons &&
        prospect.persons[0] &&
        prospect.persons[0].fiscality &&
        prospect.persons[0].fiscality.taxResidency
        ? prospect.persons[0].fiscality?.taxResidency
        : 'france'
    )
    setValue(
      'personFirstFiscalityIncomeTax',
      prospect &&
        prospect.persons &&
        prospect.persons[0] &&
        prospect.persons[0].fiscality &&
        prospect.persons[0].fiscality
        ? prospect.persons[0].fiscality.incomeTax
          ? '1'
          : '0'
        : '1'
    )
    setValue(
      'personFirstFiscalitySubjectIfi',
      prospect &&
        prospect.persons &&
        prospect.persons[0] &&
        prospect.persons[0].fiscality &&
        prospect.persons[0].fiscality
        ? prospect.persons[0].fiscality?.subjectIfi
          ? '1'
          : '0'
        : '1'
    )
    setValue(
      'personFirstFiscalityWithholdingTax',
      prospect &&
        prospect.persons &&
        prospect.persons[0] &&
        prospect.persons[0].fiscality &&
        prospect.persons[0].fiscality
        ? prospect.persons[0].fiscality?.withholdingTax
          ? '1'
          : '0'
        : '1'
    )
    setValue(
      'personSecondFiscality.taxResidency',
      prospect &&
        prospect.persons &&
        prospect.persons[1] &&
        prospect.persons[1].fiscality &&
        prospect.persons[1].fiscality.taxResidency
        ? prospect.persons[1].fiscality?.taxResidency
        : 'france'
    )
    setValue(
      'personSecondFiscalityIncomeTax',
      prospect &&
        prospect.persons &&
        prospect.persons[1] &&
        prospect.persons[1].fiscality &&
        prospect.persons[1].fiscality
        ? prospect.persons[1].fiscality.incomeTax
          ? '1'
          : '0'
        : '1'
    )
    setValue(
      'personSecondFiscalitySubjectIfi',
      prospect &&
        prospect.persons &&
        prospect.persons[1] &&
        prospect.persons[1].fiscality &&
        prospect.persons[1].fiscality
        ? prospect.persons[1].fiscality?.subjectIfi
          ? '1'
          : '0'
        : '1'
    )
    setValue(
      'personSecondFiscalityWithholdingTax',
      prospect &&
        prospect.persons &&
        prospect.persons[1] &&
        prospect.persons[1].fiscality &&
        prospect.persons[1].fiscality
        ? prospect.persons[1].fiscality?.withholdingTax
          ? '1'
          : '0'
        : '1'
    )
  }, [prospect])

  useEffect(() => {
    if (countryByDefault) {
      if (prospect) {
        setValue(
          'personFirstBirthCountry',
          prospect && prospect.persons && prospect.persons.length > 0 && prospect.persons[0].birthCountry
            ? prospect.persons[0].birthCountry
            : countryByDefault[0]
        )
        setValue(
          'personFirstNationality',
          prospect && prospect.persons && prospect.persons.length > 0 && prospect.persons[0].nationality
            ? prospect.persons[0].nationality
            : countryByDefault[0]
        )

        setValue(
          'personSecondBirthCountry',
          prospect &&
            prospect.persons &&
            prospect.persons.length > 0 &&
            prospect.persons[1] &&
            prospect.persons[1].birthCountry
            ? prospect.persons[1].birthCountry
            : countryByDefault[0]
        )
        setValue(
          'personSecondNationality',
          prospect &&
            prospect.persons &&
            prospect.persons.length > 0 &&
            prospect.persons[1] &&
            prospect.persons[1].nationality
            ? prospect.persons[1].nationality
            : countryByDefault[0]
        )
      } else {
        setValue('personFirstBirthCountry', countryByDefault[0])
        setValue('personFirstNationality', countryByDefault[0])

        setValue('personSecondBirthCountry', countryByDefault[0])
        setValue('personSecondNationality', countryByDefault[0])
      }
    }
  }, [countryByDefault])

  const { register, control, watch, handleSubmit, reset, setValue, getValues } = useForm<IFormInput>()
  const onSubmit: SubmitHandler<IFormInput> = data => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return <ConfirmationModal onConfirm={() => onConfirm(data)} onClose={onClose} />
      },
    })
  }
  const onConfirm = (data: any) => {
    if (undefined === prospect) {
      prospect = new InitializeProspect().initializeProspect()
    }

    if (null == prospect.prospectType) {
      prospect.prospectType = undefined !== prospectType ? prospectType : 'other'
    }

    prospect.prospectCode = data.prospectCode
    prospect.externalDepositaryCode = data.externalDepositaryCode
    prospect.externalCode = data.externalCode
    prospect.recoveryCode = data.recoveryCode
    prospect.status = data.status
    prospect.exitedAt = data.exitedAt
    prospect.blockedAt = data.blockedAt

    if (prospectStore !== null && prospectStore.partner !== null && prospectStore.partner.id !== null) {
      prospect.partner = prospectStore.partner
    }

    let personFirst = new InitializePerson().initializePerson()
    if (prospect.persons !== null && prospect.persons[0]) {
      personFirst = prospect.persons[0]
    }

    personFirst.id = data.personFirstId
    personFirst.title = data.personFirstTitle
    personFirst.type = 'invest'
    personFirst.ppe = data.personFirstPpe
    personFirst.ppeCategory = data.personFirstPpeCategory
    personFirst.lastname = data.personFirstLastname
    personFirst.firstname = data.personFirstFirstname
    personFirst.maidenName = data.personFirstMaidenname
    personFirst.birthdate = data.personFirstBirthdate
    personFirst.birthCity = data.personFirstBirthCity
    personFirst.birthCountry = data.personFirstBirthCountry
    personFirst.nationality = data.personFirstNationality
    personFirst.familySituation = data.personFirstFamilySituation
    personFirst.matrimonialRegime = data.personFirstMatrimonialRegime
    personFirst.legalCapacity = data.personFirstLegalCapacity
    personFirst.email = data.personFirstEmail
    personFirst.phone = data.personFirstPhone

    if (personFirst.id && data.documents[personFirst.id]) {
      personFirst.documents = documentUtils.getDocumentsForPerson(
        data.documents[personFirst.id],
        documentArray,
        personFirst.id
      )
    }

    if (data.prospectExtranetRadio === '1') {
      personFirst.extranet = true
    } else {
      personFirst.extranet = false
    }

    personFirst.extranetCreatedAt = data.personFirstExtranetCreatedAt
    personFirst.optinMarketing = data.personFirstOptinMarketing
    personFirst.optinDividend = data.personFirstOptinDividend
    personFirst.optinFiscality = data.personFirstOptinFiscality
    personFirst.optinGeneralAssembly = data.personFirstOptinGeneralAssembly
    personFirst.optinInvitation = data.personFirstOptinInvitation
    personFirst.address = data.personFirstAddress
    personFirst.fiscality = data.personFirstFiscality

    if (personFirst.fiscality) {
      if (data.personFirstFiscalityIncomeTax === '1') {
        personFirst.fiscality.incomeTax = true
      } else {
        personFirst.fiscality.incomeTax = false
      }
      if (data.personFirstFiscalitySubjectIfi === '1') {
        personFirst.fiscality.subjectIfi = true
      } else {
        personFirst.fiscality.subjectIfi = false
      }
      if (data.personFirstFiscalityWithholdingTax === '1') {
        personFirst.fiscality.withholdingTax = true
      } else {
        personFirst.fiscality.withholdingTax = false
      }
    }

    let personSecond = new InitializePerson().initializePerson()
    if (displaySecondInvestForm) {
      // let personSecond = new InitializePerson().initializePerson()
      if (prospect.persons !== null && prospect.persons[1]) {
        personSecond = prospect.persons[1]
      }

      personSecond.id = data.personSecondId
      personSecond.title = data.personSecondTitle
      personSecond.ppe = data.personSecondPpe
      personSecond.ppeCategory = data.personSecondPpeCategory
      personSecond.lastname = data.personSecondLastname
      personSecond.type = 'invest'
      personSecond.firstname = data.personSecondFirstname
      personSecond.maidenName = data.personSecondMaidenname
      personSecond.birthdate = data.personSecondBirthdate
      personSecond.birthCity = data.personSecondBirthCity
      personSecond.birthCountry = data.personSecondBirthCountry
      personSecond.nationality = data.personSecondNationality
      personSecond.familySituation = data.personSecondFamilySituation
      personSecond.matrimonialRegime = data.personSecondMatrimonialRegime
      personSecond.legalCapacity = data.personSecondLegalCapacity
      personSecond.email = data.personSecondEmail
      personSecond.phone = data.personSecondPhone

      if (data.prospectExtranetRadio === '1') {
        personSecond.extranet = true
      } else {
        personSecond.extranet = false
      }

      personSecond.extranetCreatedAt = data.personFirstExtranetCreatedAt
      personSecond.optinMarketing = data.personFirstOptinMarketing
      personSecond.optinDividend = data.personFirstOptinDividend
      personSecond.optinFiscality = data.personFirstOptinFiscality
      personSecond.optinGeneralAssembly = data.personFirstOptinGeneralAssembly
      personSecond.optinInvitation = data.personFirstOptinInvitation
      personSecond.address = data.personSecondAddress
      personSecond.fiscality = data.personSecondFiscality

      if (personSecond.fiscality) {
        if (data.personSecondFiscalityIncomeTax === '1') {
          personSecond.fiscality.incomeTax = true
        } else {
          personSecond.fiscality.incomeTax = false
        }
        if (data.personSecondFiscalitySubjectIfi === '1') {
          personSecond.fiscality.subjectIfi = true
        } else {
          personSecond.fiscality.subjectIfi = false
        }
        if (data.personSecondFiscalityWithholdingTax === '1') {
          personSecond.fiscality.withholdingTax = true
        } else {
          personSecond.fiscality.withholdingTax = false
        }
      }

      if (personSecond.id && data.documents[personSecond.id]) {
        personSecond.documents = documentUtils.getDocumentsForPerson(
          data.documents[personSecond.id],
          documentArray,
          personSecond.id
        )
      }
    }

    prospect.persons = personFirst && personFirst.firstname && personFirst.lastname ? [personFirst] : []
    if (displaySecondInvestForm && personSecond && personSecond.firstname && personSecond.lastname) {
      prospect.persons.push(personSecond)
    }

    prospect.kycCategoryValue = data.personFirstKycCategoryValue
    prospect.kycCategoryDate = data.personFirstKycCategoryDate
    prospect.kycLabft = data.personFirstKycLabft
    prospect.kycLabftDate = data.personFirstKycLabftDate
    prospect.kycProfession = data.personFirstKycProfession
    prospect.kycFatca = data.personFirstKycFatca
    prospect.groupRelation = data.personFirstGroupRelation

    prospect.kycCategoryValueSecond = data.personSecondKycCategoryValue
    prospect.kycCategoryDateSecond = data.personSecondKycCategoryDate
    prospect.kycLabftSecond = data.personSecondKycLabft
    prospect.kycLabftDateSecond = data.personSecondKycLabftDate
    prospect.kycProfessionSecond = data.personSecondKycProfession
    prospect.kycFatcaSecond = data.personSecondKycFatca
    prospect.groupRelationSecond = data.personSecondGroupRelation

    if (null !== prospect.id) {
      dispatch(setIsLoading(true))
      new UpdateUseCase(new ProspectGateway())
        .execute(prospect)
        .then(response => {
          dispatch(reloadProspect())
          if (null !== response) {
            toastSuccess(t('account.notify.update-success'))
            if (watchStatus) {
              setStatus(watchStatus)
            } else {
              setStatus('')
            }
          } else {
            toastError(t('account.notify.update-error'))
          }
        })
        .catch(error => {
          toastError(t('account.notify.update-error'))
        })
        .finally(() => {
          dispatch(setIsLoading(false))
        })
    } else {
      dispatch(setIsLoading(true))
      new AddUseCase(new ProspectGateway())
        .execute(prospect)
        .then(uuid => {
          if (null !== uuid) {
            toastSuccess(t('account.notify.add-success'))
            navigate(`/${t('url.prospect.edit-general-information')}/${uuid}`)
          } else {
            toastError(t('account.notify.add-error'))
          }
          dispatch(setIsLoading(false))
        })
        .catch(error => {
          toastError(t('account.notify.add-error'))
        })
        .finally(() => {
          dispatch(setIsLoading(false))
        })
    }
  }

  const { isDirty } = useFormState({
    control,
  })
  usePrompt(isLectureMode ? false : isDirty, handleSubmit(onConfirm))

  const watchreceiveAllBy = watch('personFirstOptinAll.type')
  const watchPersonFirstLegalCapacity = watch('personFirstLegalCapacity')
  const watchPersonSecondLegalCapacity = watch('personSecondLegalCapacity')
  const watchPersonFirstPpe = watch('personFirstPpe')
  const watchPersonSecondtPpe = watch('personSecondPpe')
  const watchStatus = watch('status')
  const watchPersonFirstLastname = watch('personFirstLastname')
  const watchPersonFirstFirstname = watch('personFirstFirstname')
  const watchPersonFirstBirthdate = watch('personFirstBirthdate')
  const watchPersonFirstNationality = watch('personFirstNationality')
  const watchPersonFirstBirthCity = watch('personFirstBirthCity')
  const watchPersonFirstBirthCountry = watch('personFirstBirthCountry')
  const watchPersonSecondBirthCountry = watch('personSecondBirthCountry')

  useEffect(() => {
    checkValidity()
  }, [
    watchStatus,
    watchPersonFirstLastname,
    watchPersonFirstFirstname,
    watchPersonFirstBirthdate,
    watchPersonFirstNationality,
    watchPersonFirstBirthCity,
  ])

  const checkValidity = () => {
    const errors = []
    if (!watchStatus) {
      errors.push('account.form.status.status')
    }
    if (!watchPersonFirstLastname) {
      errors.push('account.form.general-information.personLastname')
    }
    if (!watchPersonFirstFirstname) {
      errors.push('account.form.general-information.personFirstname')
    }
    if (!watchPersonFirstBirthdate) {
      errors.push('account.form.general-information.personBirthdate')
    }
    if (!watchPersonFirstNationality) {
      errors.push('account.form.general-information.personNationality')
    }
    if (!watchPersonFirstBirthCity) {
      errors.push('account.form.general-information.personBirthCity')
    }
    setFormErrors([...errors])
  }

  useEffect(() => {
    if (prospect && prospect.id) {
      const documentGateway = new DocumentGateway(prospect.id)
      documentGateway.getFiles().then(response => {
        if (response) {
          setDocumentArray(response)
        }
      })

      documentGateway.getHistory().then(response => {
        if (response) {
          setDocumentHistoryArray(response)
        }
      })
    }
  }, [openUploadForm])

  useEffect(() => {
    if (prospect && prospect.persons) {
      prospect.persons.map(person => {
        if (person.id && documentArray[person.id]) {
          documentArray[person.id].map((doc: DocumentInterface) => {
            setValue(`documents.${person.id}.${doc.type}.date`, doc.date)
            setValue(`documents.${person.id}.${doc.type}.updatedAt`, doc.updatedAt)
          })
        }
      })
    }
  }, [documentArray])

  useEffect(() => {
    const initProspectWithLegalCapacity = function (prospect: ProspectInterface) {
      if (prospect.persons) {
        if (prospect.persons.length === 0) {
          prospect.persons.push(new InitializePerson().initializePerson())
        }
        prospect.persons[0].legalCapacity = getValues('personFirstLegalCapacity')
        if (prospect.persons[1]) {
          prospect.persons[1].legalCapacity = getValues('personSecondLegalCapacity')
        }
      }
    }
    if (prospect) {
      initProspectWithLegalCapacity(prospect)
      if (handler) {
        handler(prospect)
      }
    } else {
      if (handler) {
        const prosp = new InitializeProspect().initializeProspect()
        initProspectWithLegalCapacity(prosp)
        handler(prosp)
      }
    }
  }, [watchPersonFirstLegalCapacity, watchPersonSecondLegalCapacity])

  useEffect(() => {
    if (!watchPersonFirstPpe) {
      setValue('personFirstPpeCategory', '')
    }
  }, [watchPersonFirstPpe])

  useEffect(() => {
    if (!watchPersonSecondtPpe) {
      setValue('personSecondPpeCategory', '')
    }
  }, [watchPersonSecondtPpe])

  useEffect(() => {
    if (watchreceiveAllBy) {
      setValue('personFirstOptinDividend.type', watchreceiveAllBy)
      setValue('personFirstOptinFiscality.type', watchreceiveAllBy)
      setValue('personFirstOptinGeneralAssembly.type', watchreceiveAllBy)
      setValue('personFirstOptinInvitation.type', watchreceiveAllBy)
      setValue('personFirstOptinMarketing.type', watchreceiveAllBy)
    }
  }, [watchreceiveAllBy])

  const addSecondInvest = () => {
    setDisplaySecondInvestForm(true)
  }

  const immatriculationBlock = (
    <div className={`form-bloc form--bloc--immatriculation`}>
      <div className='form-bloc__title'>{t('account.form.immatriculation.title')}</div>
      <div className='form-bloc__form flex-container'>
        <div className='col-md-3'>
          <div className='form-control'>
            <label htmlFor='prospectCode' className='form-control__label'>
              {t('account.form.immatriculation.prospectCode')}
            </label>
            <div className='form-control__input'>
              <input type='text' id='prospectCode' {...register('prospectCode')} disabled={true} />
            </div>
          </div>
        </div>
        <div className='col-md-3'>
          <div className='form-control'>
            <label htmlFor='externalDepositaryCode' className='form-control__label'>
              {t('account.form.immatriculation.externalDepositaryCode')}
            </label>
            <div className='form-control__input'>
              <input type='text' id='externalDepositaryCode' {...register('externalDepositaryCode')} disabled={true} />
            </div>
          </div>
        </div>
        <div className='col-md-3'>
          <div className='form-control'>
            <label htmlFor='externalCode' className='form-control__label'>
              {t('account.form.immatriculation.externalCode')}
            </label>
            <div className='form-control__input'>
              <input type='text' id='externalCode' {...register('externalCode')} disabled={true} />
            </div>
          </div>
        </div>
        <div className='col-md-3'>
          <div className='form-control'>
            <label htmlFor='recoveryCode' className='form-control__label'>
              {t('account.form.immatriculation.recoveryCode')}
            </label>
            <div className='form-control__input'>
              <input type='text' id='recoveryCode' {...register('recoveryCode')} disabled={true} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
  const generalInformationBlock = (
    <div className={`form-bloc form--bloc--general-information`}>
      <div className='flex'>
        <div className='col-md-6 form-bloc__title'>{t('account.form.general-information.title')}</div>
        {!displaySecondInvestForm && !prospect?.customer && (
          <div className='col-md-6 flex items-baseline'>
            <button type='button' className='button-reset u-mrs' onClick={() => addSecondInvest()}>
              <img src={addIcon} alt='Icone ajouter' />
            </button>
            <div className='u-pointer' onClick={() => addSecondInvest()}>
              Ajouter un deuxième investisseur
            </div>
          </div>
        )}
      </div>
      <div className='flex-container'>
        <div className='col-md-6'>
          <div className='form-bloc__form flex-container'>
            <div className='col-md-12'>{t('account.form.personFirst')}</div>
            <div className='col-md-6'>
              <div className='form-control'>
                <label htmlFor='personFirstTitle' className='form-control__label'>
                  {t('account.form.general-information.personTitle')}
                </label>
                <div className='form-control__input'>
                  <select {...register('personFirstTitle')}>
                    <option value='' />
                    {referential.global.title_physic.map((item, index) => (
                      <option key={index} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <Input
              id={`personFirstLastname`}
              name={`personFirstLastname`}
              label={t('account.form.general-information.personLastname')}
              register={register}
              inputClasses={!watchPersonFirstLastname ? 'field-in-error' : ''}
              classes={`col-md-12`}
              required={true}
            />
            <Input
              id={`personFirstFirstname`}
              name={`personFirstFirstname`}
              label={t('account.form.general-information.personFirstname')}
              register={register}
              inputClasses={!watchPersonFirstFirstname ? 'field-in-error' : ''}
              classes={`col-md-12`}
              required={true}
            />
            <div className='col-md-12'>
              <div className='form-control'>
                <label htmlFor='personFirstMaidenname' className='form-control__label'>
                  {t('account.form.general-information.personMaidenname')}
                </label>
                <div className='form-control__input'>
                  <input type='text' id='personFirstMaidenname' {...register('personFirstMaidenname')} />
                </div>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-control'>
                <label htmlFor='personFirstBirthdate' className='form-control__label mendatory-field'>
                  {t('account.form.general-information.personBirthdate')}
                </label>
                <div className='form-control__input'>
                  <InputDate
                    id='personFirstBirthdate'
                    name='personFirstBirthdate'
                    classes={!watchPersonFirstBirthdate ? 'field-in-error' : ''}
                    register={register}
                    control={control}
                  />
                </div>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-control'>
                <label htmlFor='personFirstNationality' className='form-control__label mendatory-field'>
                  {t('account.form.general-information.personNationality')}
                </label>
                <div className='form-control__input'>
                  <CountryDatalist
                    control={control}
                    isOnError={!watchPersonFirstNationality}
                    name='personFirstNationality'
                    defaultValue={getValues('personFirstNationality')}
                    disabled={isLectureMode}
                  />
                </div>
              </div>
            </div>
            <div className='col-md-12'>
              <div className='form-control'>
                <label htmlFor='personFirstBirthCountry' className='form-control__label'>
                  {t('account.form.general-information.personBirthCountry')}
                </label>
                <div className='form-control__input'>
                  <CountryDatalist
                    control={control}
                    name='personFirstBirthCountry'
                    defaultValue={getValues('personFirstBirthCountry')}
                    disabled={isLectureMode}
                  />
                </div>
              </div>
            </div>
            <div className='col-md-12'>
              <CityDatalist
                control={control}
                setValue={setValue}
                labelContent={t('account.form.general-information.personBirthCity')}
                name='personFirstBirthCity'
                defaultValue={getValues('personFirstBirthCity')}
                country={watchPersonFirstBirthCountry}
                classes={!watchPersonFirstBirthCity ? 'field-in-error' : ''}
                mode='row'
                disabled={isLectureMode}
              />
            </div>
            <div className='col-md-6'>
              <div className='form-control'>
                <label htmlFor='personFirstFamilySituation' className='form-control__label'>
                  {t('account.form.general-information.personFamilySituation')}
                </label>
                <div className='form-control__input'>
                  <select {...register('personFirstFamilySituation')}>
                    <option value='' />
                    {referential.global.family.map((item, index) => (
                      <option key={index} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-control'>
                <label htmlFor='personFirstMatrimonialRegime' className='form-control__label'>
                  {t('account.form.general-information.personMatrimonialRegime')}
                </label>
                <div className='form-control__input'>
                  <select {...register('personFirstMatrimonialRegime')}>
                    <option value='' />
                    {referential.global.matrimonial_regime.map((item, index) => (
                      <option key={index} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className='col-md-12'>
              <div className='form-control'>
                <label htmlFor='personFirstLegalCapacity' className='form-control__label'>
                  {t('account.form.general-information.personLegalCapacity')}
                </label>
                <div className='form-control__input'>
                  <select {...register('personFirstLegalCapacity')}>
                    <option value='' />
                    {referential.prospect.legal_capacity.map((item, index) => (
                      <option key={index} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className='col-md-6'>
              <CheckboxToggle
                id={'personFirstPpe'}
                name={'personFirstPpe'}
                register={register}
                label={t('account.form.general-information.personPpe')}
              />
            </div>
            <div className='col-md-6'>
              {watchPersonFirstPpe && (
                <SelectCustom
                  classes=''
                  containerClasses=''
                  id='personFirstPpeCategory'
                  name='personFirstPpeCategory'
                  options={referential.customer.ppe_category}
                  register={register}
                  label={t('account.form.manager.managerPpeCategory')}
                />
              )}
            </div>
          </div>
        </div>

        {displaySecondInvestForm && (
          <div className='col-md-6'>
            <div className='form-bloc__form flex-container'>
              <div className='col-md-12'>{t('account.form.personSecond')}</div>
              <div className='col-md-6'>
                <div className='form-control'>
                  <label htmlFor='personSecondTitle' className='form-control__label'>
                    {t('account.form.general-information.personTitle')}
                  </label>
                  <div className='form-control__input'>
                    <select {...register('personSecondTitle')}>
                      <option value='' />
                      {referential.global.title_physic.map((item, index) => (
                        <option key={index} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <Input
                id={`personSecondLastname`}
                name={`personSecondLastname`}
                label={t('account.form.general-information.personLastname')}
                register={register}
                classes={`col-md-12`}
              />
              <Input
                id={`personSecondFirstname`}
                name={`personSecondFirstname`}
                label={t('account.form.general-information.personFirstname')}
                register={register}
                classes={`col-md-12`}
              />
              <div className='col-md-12'>
                <div className='form-control'>
                  <label htmlFor='personSecondMaidenname' className='form-control__label'>
                    {t('account.form.general-information.personMaidenname')}
                  </label>
                  <div className='form-control__input'>
                    <input type='text' id='personSecondMaidenname' {...register('personSecondMaidenname')} />
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='form-control'>
                  <label htmlFor='personSecondBirthdate' className='form-control__label'>
                    {t('account.form.general-information.personBirthdate')}
                  </label>
                  <div className='form-control__input'>
                    <InputDate
                      id='personSecondBirthdate'
                      name='personSecondBirthdate'
                      register={register}
                      control={control}
                    />
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='form-control'>
                  <label htmlFor='personSecondNationality' className='form-control__label'>
                    {t('account.form.general-information.personNationality')}
                  </label>
                  <div className='form-control__input'>
                    <CountryDatalist
                      control={control}
                      name='personSecondNationality'
                      defaultValue={getValues('personSecondNationality')}
                      disabled={isLectureMode}
                    />
                  </div>
                </div>
              </div>
              <div className='col-md-12'>
                <div className='form-control'>
                  <label htmlFor='personSecondBirthCountry' className='form-control__label'>
                    {t('account.form.general-information.personBirthCountry')}
                  </label>
                  <div className='form-control__input'>
                    <CountryDatalist
                      control={control}
                      name='personSecondBirthCountry'
                      defaultValue={getValues('personSecondBirthCountry')}
                      disabled={isLectureMode}
                    />
                  </div>
                </div>
              </div>
              <div className='col-md-12'>
                <CityDatalist
                  control={control}
                  setValue={setValue}
                  labelContent={t('account.form.general-information.personBirthCity')}
                  name='personSecondBirthCity'
                  defaultValue={getValues('personSecondBirthCity')}
                  country={watchPersonSecondBirthCountry}
                  classes='flex'
                  mode='row'
                  disabled={isLectureMode}
                />
              </div>
              <div className='col-md-6'>
                <div className='form-control'>
                  <label htmlFor='personSecondFamilySituation' className='form-control__label'>
                    {t('account.form.general-information.personFamilySituation')}
                  </label>
                  <div className='form-control__input'>
                    <select {...register('personSecondFamilySituation')}>
                      <option value='' />
                      {referential.global.family.map((item, index) => (
                        <option key={index} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='form-control'>
                  <label htmlFor='personSecondMatrimonialRegime' className='form-control__label'>
                    {t('account.form.general-information.personMatrimonialRegime')}
                  </label>
                  <div className='form-control__input'>
                    <select {...register('personSecondMatrimonialRegime')}>
                      <option value='' />
                      {referential.global.matrimonial_regime.map((item, index) => (
                        <option key={index} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className='col-md-12'>
                <div className='form-control'>
                  <label htmlFor='personSecondLegalCapacity' className='form-control__label'>
                    {t('account.form.general-information.personLegalCapacity')}
                  </label>
                  <div className='form-control__input'>
                    <select {...register('personSecondLegalCapacity')}>
                      <option value='' />
                      {referential.prospect.legal_capacity.map((item, index) => (
                        <option key={index} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <CheckboxToggle
                  id={'personSecondPpe'}
                  name={'personSecondPpe'}
                  register={register}
                  label={t('account.form.general-information.personPpe')}
                />
              </div>
              <div className='col-md-6'>
                {watchPersonSecondtPpe && (
                  <SelectCustom
                    classes=''
                    containerClasses=''
                    id='personSecondPpeCategory'
                    name='personSecondPpeCategory'
                    options={referential.customer.ppe_category}
                    register={register}
                    label={t('account.form.manager.managerPpeCategory')}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
  const kycBlock = (
    <div className={`form-bloc form--bloc--kyc`}>
      <div className='form-bloc__title'>{t('account.form.kyc.title')}</div>
      <div className='form-bloc__form flex-container'>
        <div className='col-md-6'>
          <div className='flex-container'>
            <div className='col-md-12'>{t('account.form.personFirst')}</div>
            <div className='col-md-6'>
              <div className='form-control'>
                <label htmlFor='personFirstKycCategoryValue' className='form-control__label'>
                  {t('account.form.kyc.kycCategoryValue')}
                </label>
                <div className='form-control__input'>
                  <select {...register('personFirstKycCategoryValue')}>
                    <option value='' />
                    {referential.prospect.kyc_category_value.map((item, index) => (
                      <option key={index} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-control'>
                <label htmlFor='personFirstKycCategoryDate' className='form-control__label'>
                  {t('account.form.kyc.kycCategoryDate')}
                </label>
                <div className='form-control__input'>
                  <InputDate
                    id='personFirstKycCategoryDate'
                    name='personFirstKycCategoryDate'
                    register={register}
                    control={control}
                  />
                </div>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-control'>
                <label htmlFor='personFirstKycLabft' className='form-control__label'>
                  {t('account.form.kyc.kycLabft')}
                </label>
                <div className='form-control__input'>
                  <select {...register('personFirstKycLabft')}>
                    <option value='' />
                    {referential.prospect.kyc_labft.map((item, index) => (
                      <option key={index} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-control'>
                <label htmlFor='personFirstKycLabftDate' className='form-control__label'>
                  {t('account.form.kyc.kycLabftDate')}
                </label>
                <div className='form-control__input'>
                  <InputDate
                    id='personFirstKycLabftDate'
                    name='personFirstKycLabftDate'
                    register={register}
                    control={control}
                  />
                </div>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-control'>
                <label htmlFor='personFirstKycProfession' className='form-control__label'>
                  {t('account.form.kyc.kycProfession')}
                </label>
                <div className='form-control__input'>
                  <select {...register('personFirstKycProfession')}>
                    <option value='' />
                    {referential.global.profession.map((item, index) => (
                      <option key={index} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-control'>
                <label htmlFor='personFirstKycFatca' className='form-control__label'>
                  {t('account.form.kyc.kycFatca')}
                </label>
                <div className='form-control__input'>
                  <input type='checkbox' id='personFirstKycFatca' {...register('personFirstKycFatca')} />
                </div>
              </div>
            </div>
            <SelectCustom
              classes='col-md-8'
              id='personFirstGroupRelation'
              name='personFirstGroupRelation'
              label={t('account.form.kyc.groupRelation')}
              options={referential.prospect.relation}
              register={register}
            />
          </div>
        </div>
        {displaySecondInvestForm && (
          <div className='col-md-6'>
            <div className='flex-container'>
              <div className='col-md-12'>{t('account.form.personSecond')}</div>
              <div className='col-md-6'>
                <div className='form-control'>
                  <label htmlFor='personSecondKycCategoryValue' className='form-control__label'>
                    {t('account.form.kyc.kycCategoryValue')}
                  </label>
                  <div className='form-control__input'>
                    <select {...register('personSecondKycCategoryValue')}>
                      <option value='' />
                      {referential.prospect.kyc_category_value.map((item, index) => (
                        <option key={index} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='form-control'>
                  <label htmlFor='personSecondKycCategoryDate' className='form-control__label'>
                    {t('account.form.kyc.kycCategoryDate')}
                  </label>
                  <div className='form-control__input'>
                    <InputDate
                      id='personSecondKycCategoryDate'
                      name='personSecondKycCategoryDate'
                      register={register}
                      control={control}
                    />
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='form-control'>
                  <label htmlFor='personSecondKycLabft' className='form-control__label'>
                    {t('account.form.kyc.kycLabft')}
                  </label>
                  <div className='form-control__input'>
                    <select {...register('personSecondKycLabft')}>
                      <option value='' />
                      {referential.prospect.kyc_labft.map((item, index) => (
                        <option key={index} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='form-control'>
                  <label htmlFor='personSecondKycLabftDate' className='form-control__label'>
                    {t('account.form.kyc.kycLabftDate')}
                  </label>
                  <div className='form-control__input'>
                    <InputDate
                      id='personSecondKycLabftDate'
                      name='personSecondKycLabftDate'
                      register={register}
                      control={control}
                    />
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='form-control'>
                  <label htmlFor='personSecondKycProfession' className='form-control__label'>
                    {t('account.form.kyc.kycProfession')}
                  </label>
                  <div className='form-control__input'>
                    <select {...register('personSecondKycProfession')}>
                      <option value='' />
                      {referential.global.profession.map((item, index) => (
                        <option key={index} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='form-control'>
                  <label htmlFor='personSecondKycFatca' className='form-control__label'>
                    {t('account.form.kyc.kycFatca')}
                  </label>
                  <div className='form-control__input'>
                    <input type='checkbox' id='personSecondKycFatca' {...register('personSecondKycFatca')} />
                  </div>
                </div>
              </div>
              <SelectCustom
                classes='col-md-6'
                id='personSecondGroupRelation'
                name='personSecondGroupRelation'
                label={t('account.form.kyc.groupRelation')}
                options={referential.prospect.relation}
                register={register}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
  const fiscalityBlock = (
    <div className={`form-bloc form--bloc--fiscality`}>
      <div className='form-bloc__title'>{t('account.form.fiscality.title')}</div>
      <div className='form-bloc__form flex-container'>
        <div className='col-md-6'>
          <div className='flex-container'>
            <div className='col-md-12'>{t('account.form.personFirst')}</div>
            <div className='col-md-12'>
              <div className='form-control'>
                <label htmlFor='personFirstFiscalityNif' className='form-control__label'>
                  {t('account.form.fiscality.nif')}
                </label>
                <div className='form-control__input'>
                  <input type='text' id='personFirstFiscalityNif' {...register('personFirstFiscality.nif')} />
                </div>
              </div>
            </div>
            <SelectCustom
              classes='col-md-12'
              label={t('account.form.fiscality.taxResidency')}
              id='personFirstFiscality.taxResidency'
              name='personFirstFiscality.taxResidency'
              options={referential.prospect.tax_residency || []}
              register={register}
            />
            <div className='col-md-12'>
              <div className='form-control'>
                <label htmlFor='personFirstFiscalityIncomeTax' className='form-control__label'>
                  {t('account.form.fiscality.incomeTax')}
                </label>
                <div className='form-control form-control--label-auto'>
                  <div className='form-control__input'>
                    <label className='form-label form-label--input-radio'>
                      <input type='radio' value='1' {...register('personFirstFiscalityIncomeTax')} />
                      {t('common.yes')}
                    </label>
                  </div>
                  <div className='form-control__input'>
                    <label className='form-label form-label--input-radio'>
                      <input type='radio' value='0' {...register('personFirstFiscalityIncomeTax')} />
                      {t('common.no')}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-12'>
              <div className='form-control'>
                <label htmlFor='personFirstFiscalitySubjectIfi' className='form-control__label'>
                  {t('account.form.fiscality.subjectIfi')}
                </label>
                <div className='form-control form-control--label-auto'>
                  <div className='form-control__input'>
                    <label className='form-label form-label--input-radio'>
                      <input type='radio' value='1' {...register('personFirstFiscalitySubjectIfi')} />
                      {t('common.yes')}
                    </label>
                  </div>
                  <div className='form-control__input'>
                    <label className='form-label form-label--input-radio'>
                      <input type='radio' value='0' {...register('personFirstFiscalitySubjectIfi')} />
                      {t('common.no')}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-12'>
              <div className='form-control'>
                <label htmlFor='personFirstFiscalityRate' className='form-control__label'>
                  {t('account.form.fiscality.rate')}
                </label>
                <div className='form-control__input'>
                  <select {...register('personFirstFiscality.rate')} id='personFirstFiscalityRate'>
                    <option value='' />
                    {referential.prospect.nr_rate.map((item, index) => (
                      <option key={index} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className='col-md-12'>
              <RadioYesOrNot
                id={`personFirstFiscalityWithholdingTax`}
                name={`personFirstFiscalityWithholdingTax`}
                label={t('account.form.fiscality.withholdingTax')}
                register={register}
              />
            </div>
          </div>
        </div>
        {displaySecondInvestForm && (
          <div className='col-md-6'>
            <div className='flex-container'>
              <div className='col-md-12'>{t('account.form.personSecond')}</div>
              <div className='col-md-12'>
                <div className='form-control'>
                  <label htmlFor='personSecondFiscalityNif' className='form-control__label'>
                    {t('account.form.fiscality.nif')}
                  </label>
                  <div className='form-control__input'>
                    <input type='text' id='personSecondFiscalityNif' {...register('personSecondFiscality.nif')} />
                  </div>
                </div>
              </div>
              <SelectCustom
                classes='col-md-12'
                label={t('account.form.fiscality.taxResidency')}
                id='personSecondFiscality.taxResidency'
                name='personSecondFiscality.taxResidency'
                options={referential.prospect.tax_residency || []}
                register={register}
              />
              <div className='col-md-12'>
                <div className='form-control'>
                  <label htmlFor='personSecondFiscalityIncomeTax' className='form-control__label'>
                    {t('account.form.fiscality.incomeTax')}
                  </label>
                  <div className='form-control form-control--label-auto'>
                    <div className='form-control__input'>
                      <label className='form-label form-label--input-radio'>
                        <input type='radio' value='1' {...register('personSecondFiscalityIncomeTax')} />
                        {t('common.yes')}
                      </label>
                    </div>
                    <div className='form-control__input'>
                      <label className='form-label form-label--input-radio'>
                        <input type='radio' value='0' {...register('personSecondFiscalityIncomeTax')} />
                        {t('common.no')}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-12'>
                <div className='form-control'>
                  <label htmlFor='personSecondFiscalitySubjectIfi' className='form-control__label'>
                    {t('account.form.fiscality.subjectIfi')}
                  </label>
                  <div className='form-control form-control--label-auto'>
                    <div className='form-control__input'>
                      <label className='form-label form-label--input-radio'>
                        <input type='radio' value='1' {...register('personSecondFiscalitySubjectIfi')} />
                        {t('common.yes')}
                      </label>
                    </div>
                    <div className='form-control__input'>
                      <label className='form-label form-label--input-radio'>
                        <input type='radio' value='0' {...register('personSecondFiscalitySubjectIfi')} />
                        {t('common.no')}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-12'>
                <div className='form-control'>
                  <label htmlFor='personSecondFiscalityRate' className='form-control__label'>
                    {t('account.form.fiscality.rate')}
                  </label>
                  <div className='form-control__input'>
                    <select {...register('personSecondFiscality.rate')} id='personSecondFiscalityRate'>
                      <option value='' />
                      {referential.prospect.nr_rate.map((item, index) => (
                        <option key={index} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
  const distributionBlock = (
    <div className={`form-bloc form--bloc--distribution`}>
      <div className='form-bloc__title'>{t('account.form.distribution.title')}</div>
      <div className='form-bloc__form '>
        <div className='flex-container'>
          <SelectCustom
            classes='col-md-6'
            label={t('account.form.distribution.paymentMethod')}
            id={`persons.0.fiscality.paymentMethod`}
            name={`persons.0.fiscality.paymentMethod`}
            options={referential.prospect.payment_method || []}
            register={register}
          />
          <SelectCustom
            classes='col-md-6'
            label={t('account.form.distribution.paymentPeriodicity')}
            id={`distributionPaymentPeriodicity`}
            name={`distributionPaymentPeriodicity`}
            options={referential.prospect.payment_periodicity || []}
            register={register}
          />
        </div>
      </div>
    </div>
  )

  const communicationBlock = (
    <div className={`form-bloc form--bloc--communication`}>
      <div className='form-bloc__title'>{t('account.form.communication.title')}</div>
      {displaySecondInvestForm && (
        <div className='flex-container'>
          <div className='col-md-12'>
            <AlertInfo text={t('account.form.communication.alert-sign-elec')} />
          </div>
        </div>
      )}
      <div className='form-bloc__form flex-container'>
        <div className='col-md-6'>
          <div className='flex-container'>
            <div className='col-md-12'>{t('account.form.personFirst')}</div>
            <Input
              register={register}
              classes={'col-md-12'}
              label={t('account.form.communication.personEmail')}
              type={'email'}
              validator={'email'}
              name={'personFirstEmail'}
              id={'personFirstEmail'}
            />
            <Input
              register={register}
              classes={'col-md-12'}
              label={t('account.form.communication.personPhone')}
              type={'tel'}
              validator={'tel'}
              name={'personFirstPhone'}
              id={'personFirstPhone'}
            />
          </div>
        </div>
        {displaySecondInvestForm && (
          <div className='col-md-6'>
            <div className='flex-container'>
              <div className='col-md-12'>{t('account.form.personSecond')}</div>
              <Input
                register={register}
                classes={'col-md-12'}
                label={t('account.form.communication.personEmail')}
                type={'email'}
                validator={'email'}
                name={'personSecondEmail'}
                id={'personSecondEmail'}
              />
              <Input
                register={register}
                classes={'col-md-12'}
                label={t('account.form.communication.personPhone')}
                type={'tel'}
                validator={'tel'}
                name={'personSecondPhone'}
                id={'personSecondPhone'}
              />
            </div>
          </div>
        )}
      </div>
      <div className='form-bloc__form flex-container'>
        <div className='col-md-12'>{t('account.form.communication.communication-person')}</div>
        <div className='col-md-4'>
          <div className='form-control'>
            <label htmlFor='personFirstExtranet' className='form-control__label'>
              {t('account.form.communication.personExtranet')}
            </label>
            <div className='form-control form-control--label-auto'>
              <div className='form-control__input'>
                <label className='form-label form-label--input-radio'>
                  <input type='radio' value='1' {...register('prospectExtranetRadio')} />
                  {t('common.yes')}
                </label>
              </div>
              <div className='form-control__input'>
                <label className='form-label form-label--input-radio'>
                  <input type='radio' value='0' {...register('prospectExtranetRadio')} />
                  {t('common.no')}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className='col-md-4'>
          <div className='form-control'>
            <label htmlFor='personFirstExtranetCreatedAt' className='form-control__label'>
              {t('account.form.communication.personExtranetCreatedAt')}
            </label>
            <div className='form-control__input'>
              <InputDate
                id='personFirstExtranetCreatedAt'
                name='personFirstExtranetCreatedAt'
                register={register}
                control={control}
              />
            </div>
          </div>
        </div>
        <div className='col-md-5'>
          <table className='table--communication'>
            <thead>
              <tr>
                <th />
                {referential.prospect.communication_type.map(communication_type => {
                  return (
                    <th key={'table' + uuidV4()} className='form-control__label'>
                      {communication_type.label}
                    </th>
                  )
                })}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <label className='form-control__label'>{t('account.form.communication.optinMarketing')}</label>
                </td>
                {referential.prospect.communication_type.map(communication_type => {
                  return (
                    <td key={uuidV4()}>
                      <div className='form-control__input'>
                        <input
                          type='radio'
                          value={communication_type.value}
                          {...register('personFirstOptinMarketing.type')}
                          disabled={isLectureMode}
                        />
                      </div>
                    </td>
                  )
                })}
              </tr>
              <tr>
                <td>
                  <label className='form-control__label'>{t('account.form.communication.optinDividend')}</label>
                </td>
                {referential.prospect.communication_type.map(communication_type => {
                  return (
                    <td key={uuidV4()}>
                      <div className='form-control__input'>
                        <input
                          type='radio'
                          value={communication_type.value}
                          {...register('personFirstOptinDividend.type')}
                          disabled={isLectureMode}
                        />
                      </div>
                    </td>
                  )
                })}
              </tr>
              <tr>
                <td>
                  <label className='form-control__label'>{t('account.form.communication.optinFiscality')}</label>
                </td>
                {referential.prospect.communication_type.map(communication_type => {
                  return (
                    <td key={uuidV4()}>
                      <div className='form-control__input'>
                        <input
                          type='radio'
                          value={communication_type.value}
                          {...register('personFirstOptinFiscality.type')}
                          disabled={isLectureMode}
                        />
                      </div>
                    </td>
                  )
                })}
              </tr>
              <tr>
                <td>
                  <label className='form-control__label'>{t('account.form.communication.optinGeneralAssembly')}</label>
                </td>
                {referential.prospect.communication_type.map(communication_type => {
                  return (
                    <td key={uuidV4()}>
                      <div className='form-control__input'>
                        <input
                          type='radio'
                          value={communication_type.value}
                          {...register('personFirstOptinGeneralAssembly.type')}
                          disabled={isLectureMode}
                        />
                      </div>
                    </td>
                  )
                })}
              </tr>
              <tr>
                <td>
                  <label className='form-control__label'>{t('account.form.communication.optinInvitation')}</label>
                </td>
                {referential.prospect.communication_type.map(communication_type => {
                  return (
                    <td key={uuidV4()}>
                      <div className='form-control__input'>
                        <input
                          type='radio'
                          value={communication_type.value}
                          {...register('personFirstOptinInvitation.type')}
                          disabled={isLectureMode}
                        />
                      </div>
                    </td>
                  )
                })}
              </tr>
              <tr>
                <td>
                  <label className='form-control__label'>{t('account.form.communication.optinAll')}</label>
                </td>
                {referential.prospect.communication_type.map(communication_type => {
                  return (
                    <td key={uuidV4()}>
                      <div className='form-control__input'>
                        <input
                          type='radio'
                          value={communication_type.value}
                          {...register('personFirstOptinAll.type')}
                          disabled={isLectureMode}
                        />
                      </div>
                    </td>
                  )
                })}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
  const statusBlock = (
    <div className={`form-bloc form--bloc--status`}>
      <div className='form-bloc__title'>{t('account.form.status.title')}</div>
      <div className='form-bloc__form flex-container'>
        <div className='col-md-3'>
          <div className='form-control'>
            <label htmlFor='statusCreatedAt' className='form-control__label'>
              {t('account.form.status.statusCreatedAt')}
            </label>
            <div className='form-control__input'>
              <InputDate
                id='statusCreatedAt'
                name='statusCreatedAt'
                register={register}
                control={control}
                disabled={true}
              />
            </div>
          </div>
        </div>
        <div className='col-md-3'>
          <div className='form-control'>
            <label htmlFor='status' className='form-control__label mendatory-field'>
              {t('account.form.status.status')}
            </label>
            <div className='form-control__input'>
              <select {...register('status')} className={!watchStatus ? 'field-in-error' : ''}>
                <option value='' />
                {referential.prospect.status.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className='col-md-3'>
          <div className='form-control'>
            <label htmlFor='exitedAt' className='form-control__label'>
              {t('account.form.status.exitedAt')}
            </label>
            <div className='form-control__input'>
              <InputDate id='statusCreatedAt' name='exitedAt' register={register} control={control} />
            </div>
          </div>
        </div>
        <div className='col-md-3'>
          <div className='form-control'>
            <label htmlFor='blockedAt' className='form-control__label'>
              {t('account.form.status.blockedAt')}
            </label>
            <div className='form-control__input'>
              <InputDate id='blockedAt' name='blockedAt' register={register} control={control} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
  const documentsBlock = prospect?.persons?.map((person, index) => {
    if (prospect && prospect.id && person && person.id) {
      return (
        <>
          <div className='col-md-6'>
            <DocumentForm
              title={`${t('movement.pre-subscription.form.list-of-documents.title')} : ${person.lastname + ' ' + person.firstname}`}
              name={`documents.${person.id}`}
              uuid={person.id}
              documentGateway={new DocumentGateway(prospect.id)}
              register={register}
              control={control}
              documentData={documentArray[person.id]}
              isLectureMode={isLectureMode}
            />
          </div>
        </>
      )
    }
  })
  const measuredRef = useCallback(node => {
    if (node !== null && isLectureMode) {
      setReadOnlyFormElements(true, node)
    }
  }, [])

  return (
    <form onSubmit={handleSubmit(onSubmit)} ref={measuredRef}>
      <div className='flex-container'>
        <div className='col-md-12'>
          {formErrors.length ? (
            <FormErrorInfo errors={formErrors} message={t('account.notify.form-error-base-message')} />
          ) : null}
        </div>
        <div className='col-md-12'>{immatriculationBlock}</div>
        <div className='col-md-12'>{generalInformationBlock}</div>
        <div className='col-md-12'>{kycBlock}</div>
        <div className='col-md-12'>{fiscalityBlock}</div>
        <div className='col-md-12'>{distributionBlock}</div>
        <div className='col-md-12'>{communicationBlock}</div>
        <div className='col-md-12'>{statusBlock}</div>
        <div className='col-md-12'>
          <div className='flex-container'>{documentsBlock}</div>
        </div>
        <div className='col-md-12'>
          {prospect &&
            prospect.id &&
            prospect.recoveryCode &&
            documentHistoryArray &&
            documentHistoryArray.length > 0 && (
              <div className='form-bloc'>
                <DocumentHistoryBlock documents={documentHistoryArray} gateway={new DocumentGateway(prospect.id)} />
              </div>
            )}
        </div>
      </div>
      {!isLectureMode && <FooterBlock disabled={!watchStatus || isLoading} onCancelUrl={t('url.prospect.dashboard')} />}
    </form>
  )
}

export default GeneralInformationPhysicalPerson
