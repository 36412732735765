import { DistributionSessionListInterface, SessionGatewayInterface } from '../../../domain/Distribution'
import { ListRequestInterface } from './ListRequest'

export default class ListUseCase {
  public sessionGateway

  constructor(sessionGateway: SessionGatewayInterface) {
    this.sessionGateway = sessionGateway
  }

  async execute(
    ListRequest: ListRequestInterface,
    signal?: AbortSignal,
    sort?: string,
    order?: string
  ): Promise<{ data: DistributionSessionListInterface[] | null; nb_element: number } | null> {
    return await this.sessionGateway
      .getList(ListRequest.currentPage, ListRequest.itemPerPage, ListRequest.filter, signal, sort, order)
      .then(response => response)
  }
}
