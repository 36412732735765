import React, { FunctionComponent, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import '../../../../../assets/styles/components/_datalist.scss'
import {
  DismembermentLedgerType,
  DismembermentLedgerTypes,
} from '../../../../../domain/DismembermentLedger/DismembermentLedgerGatewayInterface'
import { DismembermentLedgerListInterface } from '../../../../../domain/DismembermentLedger/DismembermentLedgerList'
import { SortInterface, SortOrder } from '../../../../../domain/Utils/List'
import { optionsNbRows } from '../../../../../fixtures/Referentiel'
import DismembermentLedgerGateway from '../../../../../gateway/DismembermentLedger/DismembermentLedgerGateway'
import DismembermentLedgerListPresenter from '../../../../../presenter/DismembermentLedger/DismembermentLedgerListPresenter'
import { Filter } from '../../../../../presenter/Presenter'
import { ListRequest } from '../../../../../useCase/DismembermentLedger/List/ListRequest'
import ListUseCase from '../../../../../useCase/DismembermentLedger/List/ListUseCase'
import { setOpenDatalistFilterDismembermentLedger } from '../../../../store/component/event'
import { useAppDispatch, useAppSelector } from '../../../../store/hook'
import { useDismembermentLedger } from '../../../Context/DismembermentLedgerContext'
import { saveNbRowsInLocalStorage } from '../../../util/SavePreferencesInLocalStorage'
import SelectCustom from '../../Elements/Select'
import Pagination from '../../Pagination/Pagination'
import TableHead from '../../Table/TableHead'
import HeaderRight from '../Element/HeaderRight'
import FilterForm from './FilterForm'
import Row from './Row'

interface ViewModelInterface {
  title: string
  heading: []
  data: []
  filtersShortcut: Filter[]
  filters: []
  pagination: PaginationInterface
  count: number
  onSelectionChange: (selectedRows: string[], selectedNbShares: number) => void
}

interface PaginationInterface {
  itemsPerPage: number
  numberOfItems: number
}

type Props = {
  type: DismembermentLedgerType
  multiSelect: boolean
}

const DismembermentLedgerDatalist: FunctionComponent<Props> = ({ type, multiSelect }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const {
    selectedBareOwners,
    selectedUsufructuaries,
    totalNbSharesBareOwners,
    totalNbSharesUsufructuaries,
    updateSelection,
    refresh,
  } = useDismembermentLedger()

  const openDatalistFilterDismembermentLedger = useAppSelector(
    state => state.event.openDatalistFilterDismembermentLedger
  )
  const [viewModel, setViewModel] = useState<ViewModelInterface | null>(null)
  const [sortOrder, setSortOrder] = useState<SortInterface>({ sortLabel: null, sortOrder: SortOrder.ASC })
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [isLoadingExport, setLoadingExport] = useState<boolean>(false)
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([])
  const [selectedNbShares, setSelectedNbShares] = useState<number>(0)

  const controller = new AbortController()
  const preferences = localStorage.getItem('preferences')

  const methods = useForm()

  const { watch, setValue, register } = methods
  const watchNumberRows = watch('numberRows')

  useEffect(() => {
    dispatch(
      setOpenDatalistFilterDismembermentLedger({
        show: false,
        count: 0,
        filters: {
          product: {
            id: '',
            value: '',
            label: '',
          },
          executions: [],
          typeDurations: [],
          preferences: [],
          keywords: '',
        },
      })
    )
  }, [])

  useEffect(() => {
    if (!watchNumberRows) {
      setValue('numberRows', preferences ? JSON.parse(preferences).numberRows : 50)
    } else {
      saveNbRowsInLocalStorage(preferences, watchNumberRows)
      const listRequest = new ListRequest(
        type,
        currentPage,
        watchNumberRows || 50,
        openDatalistFilterDismembermentLedger.filters
      )
      const dismembermentLedgers = new ListUseCase(new DismembermentLedgerGateway())
        .execute(listRequest, controller.signal)
        .then(response => {
          return response
        })
      const presenter = new DismembermentLedgerListPresenter(dismembermentLedgers)
      presenter.load().then(() => {
        setViewModel(presenter.immutableViewModel())
      })
      setValue('product', openDatalistFilterDismembermentLedger.filters.product)
      setValue('keywords', openDatalistFilterDismembermentLedger.filters.keywords)
      setValue('executions', openDatalistFilterDismembermentLedger.filters.executions)
      setValue('typeDurations', openDatalistFilterDismembermentLedger.filters.typeDurations)
      setValue('preferences', openDatalistFilterDismembermentLedger.filters.preferences)
    }
  }, [currentPage, openDatalistFilterDismembermentLedger.filters, watchNumberRows])

  useEffect(() => {
    setSelectedRowIds([])
    setSelectedNbShares(0)
  }, [refresh])

  useEffect(() => {
    setCurrentPage(1)
  }, [openDatalistFilterDismembermentLedger.filters])

  const paginate = (pageNumber: number) => {
    controller.abort()
    setCurrentPage(pageNumber)
  }

  const handleClickFilter = (response: string) => {
    if (response) {
      dispatch(
        setOpenDatalistFilterDismembermentLedger({
          show: true,
          count: openDatalistFilterDismembermentLedger.count,
          filters: openDatalistFilterDismembermentLedger.filters,
        })
      )
    }
  }

  const handleRowClick = (id: string, nbShares: number, duration: number) => {
    const isSelected = selectedRowIds.some(row => row === id)

    const updatedSelectedRows = multiSelect
      ? isSelected
        ? selectedRowIds
            .filter(rowId => rowId !== id)
            .map(rowId => ({ id: rowId, duration: selectedBareOwners.find(row => row.id === rowId)?.duration || 0 }))
        : [
            ...selectedRowIds.map(rowId => ({
              id: rowId,
              duration: selectedBareOwners.find(row => row.id === rowId)?.duration || 0,
            })),
            { id, duration },
          ]
      : isSelected
        ? []
        : [{ id, duration }]

    const totalNbShares = multiSelect
      ? updatedSelectedRows.some(row => row.id === id)
        ? selectedNbShares + nbShares
        : selectedNbShares - nbShares
      : nbShares

    setSelectedRowIds(updatedSelectedRows.map(row => row.id))
    setSelectedNbShares(totalNbShares)

    updateSelection(type, updatedSelectedRows, totalNbShares)
  }

  const isRowDisabled = (item: DismembermentLedgerListInterface) => {
    if (selectedBareOwners.length > 0 || selectedUsufructuaries.length > 0) {
      const selectedDuration = selectedUsufructuaries[0]?.duration || selectedBareOwners[0]?.duration
      if (item.duration !== selectedDuration && typeof selectedDuration !== 'undefined') {
        return true
      }
    }

    if (
      selectedUsufructuaries.some(row => row.id === item.transactionId) ||
      selectedBareOwners.some(row => row.id === item.transactionId)
    ) {
      return false
    }

    if (
      selectedBareOwners.length > 1 &&
      selectedUsufructuaries.length === 1 &&
      type === DismembermentLedgerTypes.USUFRUCTUARIES
    ) {
      return true
    }

    if (
      selectedUsufructuaries.length > 1 &&
      selectedBareOwners.length === 1 &&
      type === DismembermentLedgerTypes.BARE_OWNERS
    ) {
      return true
    }

    return multiSelect ? false : selectedRowIds.length > 0 && !selectedRowIds.includes(item.transactionId)
  }

  return (
    <>
      {viewModel !== null && (
        <>
          <div className={`datalist`}>
            <div className='datalist__title'>{t(viewModel.title)}</div>
            <div className='datalist__header'>
              <FormProvider {...methods}>
                <FilterForm filters={viewModel.filtersShortcut} />
              </FormProvider>
              <HeaderRight
                numberOfActivatedFilters={openDatalistFilterDismembermentLedger.count}
                handleClickFilter={handleClickFilter}
                handleClickExport={() => setLoadingExport(true)}
                isLoadingExport={isLoadingExport}
                allowExport={false}
              />
            </div>
            <SelectCustom
              classes='flex justify-end u-mbs'
              id='numberRows'
              name='numberRows'
              label={t('filters.display-results-by')}
              options={optionsNbRows}
              register={register}
              noChoiceOption
            />
            <div className='table-fix-head'>
              <table className='datalist__datas datalist__datas--dismemberment-ledger'>
                <thead>
                  {viewModel.heading && (
                    <TableHead
                      typeFilter={'API'}
                      heading={viewModel.heading}
                      sortOrder={sortOrder}
                      setSortOrder={setSortOrder}
                      viewModel={viewModel}
                      setViewModel={setViewModel}
                      filter={openDatalistFilterDismembermentLedger.filters}
                      watchNumberRows={watchNumberRows}
                      currentPage={currentPage}
                      listRequest={
                        new ListRequest(
                          type,
                          currentPage,
                          watchNumberRows || 50,
                          openDatalistFilterDismembermentLedger.filters
                        )
                      }
                      listUseCase={ListUseCase}
                      listPresenter={DismembermentLedgerListPresenter}
                      gateway={DismembermentLedgerGateway}
                    />
                  )}
                </thead>
                <tbody>
                  {viewModel?.data.map((item: DismembermentLedgerListInterface, index: number) => (
                    <Row
                      key={`dismemberment-ledger-${item.transactionId}-${item.productAcronyme}-${index}`}
                      row={item}
                      isSelected={selectedRowIds.includes(item.transactionId)}
                      onRowClick={handleRowClick}
                      multiSelect={multiSelect}
                      isRowDisabled={isRowDisabled(item)}
                    />
                  ))}
                  {viewModel?.data.length === 0 && (
                    <tr>
                      <td colSpan={viewModel?.heading.length}>{t('common.data-is-empty')}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <Pagination
            currentPage={currentPage}
            itemsPerPage={watchNumberRows || viewModel.pagination.itemsPerPage}
            numberOfItems={viewModel.pagination.numberOfItems}
            callback={paginate}
          />
        </>
      )}
    </>
  )
}

export default DismembermentLedgerDatalist
