import { GeneralAddressInterface } from '../../domain/Address/GeneralAddress'
import Caller from '../../domain/Api/Caller'
import { CallerType } from '../../domain/Caller/Caller'
import { BankInformation } from '../../domain/Prospect/BankInformation'
import { Person } from '../../domain/Prospect/Person'
import { ProspectInterface } from '../../domain/Prospect/Prospect'
import ProspectGatewayInterface from '../../domain/Prospect/ProspectGatewayInterface'
import { ProspectLegalRepresentatives } from '../../domain/Prospect/ProspectLegalRepresentatives'
import { FilterProspectInterface, ProspectListInterface } from '../../domain/Prospect/ProspectList'
import { Relation } from '../../domain/Prospect/Relation'
import DateCleaner from '../../domain/Utils/DateCleaner'
import IdCleaner from '../../domain/Utils/IdCleaner'
import { AgreedVsProspect, BreakdownOfProspectsByType, NumberOfProspectByState } from '../../fixtures/Prospect'
import { KpiResponseInterface } from '../../infrastructure/presentation/Type/Kpi'
import envVariable from '../../infrastructure/presentation/util/envVariable'

export default class ProspectGateway implements ProspectGatewayInterface {
  getList(
    callerType: CallerType,
    currentPage: number,
    itemsPerPage: number,
    filter: FilterProspectInterface | null,
    signal?: AbortSignal,
    sort?: string,
    order?: string
  ): Promise<{ data: ProspectListInterface[] | null; numberOfItems: number } | null> {
    return Caller.executeGet(
      `${envVariable('REACT_APP_API_URL')}/prospects${callerType === 'datalist' ? '-datalist' : ''}?params[nb_element_per_page]=${itemsPerPage}&params[page]=${currentPage}${sort ? '&sort=' + sort + '&order=' + order : ''}`,
      {
        lastName: filter?.lastName || '',
        maidenName: filter?.maidenName || '',
        keywords: filter?.keywords || '',
        birthDate: filter?.birthDate || '',
        status: filter?.status?.map(status => status.value),
      },
      signal
    )
      .then(data => {
        if (data) {
          return data
        }

        return {
          data: [],
          numberOfItems: 0,
        }
      })
      .catch(response => {
        return {
          data: [],
          numberOfItems: 0,
        }
      })
  }

  getKpi(): Promise<KpiResponseInterface[] | null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/prospects/kpi`, {})
      .then(data => {
        if (data) {
          return data
        }

        return null
      })
      .catch(response => {
        return null
      })
  }

  getNumberByState(): Promise<Array<number> | []> {
    return new Promise<Array<number> | []>(resolve => {
      resolve(NumberOfProspectByState)
    })
  }

  getAgreedVsProspect(): Promise<Array<number> | []> {
    return new Promise<Array<number> | []>(resolve => {
      resolve(AgreedVsProspect)
    })
  }

  getBreakdownOfProspectsByType(): Promise<Array<number> | []> {
    return new Promise<Array<number> | []>(resolve => {
      resolve(BreakdownOfProspectsByType)
    })
  }

  add(prospect: ProspectInterface): Promise<ProspectInterface | null> {
    return Caller.executePost(`${envVariable('REACT_APP_API_URL')}/prospect`, {
      enabled: prospect.enabled,
      prospectType: prospect.prospectType,
      status: prospect.status,
      statusCreatedAt: DateCleaner.clean(prospect.statusCreatedAt),
      exitedAt: DateCleaner.clean(prospect.exitedAt),
      blockedAt: DateCleaner.clean(prospect.blockedAt),
      prospectCode: prospect.prospectCode,
      moralType: prospect.moralType,
      externalCode: prospect.externalCode,
      externalDepositaryCode: prospect.externalDepositaryCode,
      recoveryCode: prospect.recoveryCode,
      legalStatus: prospect.legalStatus,
      companyName: prospect.companyName,
      siret: prospect.siret,
      orias: prospect.orias,
      kycCategoryValue: prospect.kycCategoryValue,
      kycCategoryDate: DateCleaner.clean(prospect.kycCategoryDate),
      kycLabft: prospect.kycLabft,
      kycLabftDate: DateCleaner.clean(DateCleaner.clean(prospect.kycLabftDate)),
      kycProfession: prospect.kycProfession,
      kycFatca: prospect.kycFatca,
      groupRelation: prospect.groupRelation,
      kycCategoryValueSecond: prospect.kycCategoryValueSecond,
      kycCategoryDateSecond: DateCleaner.clean(prospect.kycCategoryDateSecond),
      kycLabftSecond: prospect.kycLabftSecond,
      kycLabftDateSecond: DateCleaner.clean(prospect.kycLabftDateSecond),
      kycProfessionSecond: prospect.kycProfessionSecond,
      kycFatcaSecond: prospect.kycFatcaSecond,
      groupRelationSecond: prospect.groupRelationSecond,
      providerCode: null,
      partner: prospect.partner ? prospect.partner.id : null,
      bankInformations: prospect.bankInformations?.map((bankInformation: BankInformation) => {
        return {
          id: IdCleaner.clean(bankInformation.id),
          label: bankInformation.label,
          main: bankInformation.main,
          bank: bankInformation.bank,
          iban: bankInformation.iban,
          swift: bankInformation.swift,
          debitMandate: bankInformation.debitMandate,
          products: bankInformation.products,
          type: bankInformation.type,
          flux: bankInformation.flux,
          status: bankInformation.status,
        }
      }),
      beneficialOwners: prospect.beneficialOwners?.map(beneficialOwner => {
        return {
          ppe: beneficialOwner.ppe,
          shareHolding: parseInt(beneficialOwner.shareHolding),
          status: beneficialOwner.status,
          person: {
            ppe: beneficialOwner.ppe,
            gerant: beneficialOwner.person?.gerant,
            ppeCategory: beneficialOwner.person?.ppeCategory,
            title: beneficialOwner.person?.title,
            lastname: beneficialOwner.person?.lastname,
            firstname: beneficialOwner.person?.firstname,
            maidenName: beneficialOwner.person?.maidenName,
            birthdate: beneficialOwner.person?.birthdate ? DateCleaner.clean(beneficialOwner.person?.birthdate) : null,
            birthCountry: beneficialOwner.person?.birthCountry?.id,
            birthCity: beneficialOwner.person?.birthCity?.id,
            birthCityForeign: beneficialOwner.person?.birthCityForeign,
            nationality: beneficialOwner.person?.nationality?.id,
            address: {
              title: beneficialOwner.person?.address?.title,
              lastname: beneficialOwner.person?.address?.lastname,
              firstname: beneficialOwner.person?.address?.firstname,
              maidenName: beneficialOwner.person?.address?.maidenname,
              address: beneficialOwner.person?.address?.address,
              address2: beneficialOwner.person?.address?.address2,
              address3: beneficialOwner.person?.address?.address3,
              postcode: beneficialOwner.person?.address?.postcode,
              city: beneficialOwner.person?.address?.city?.id,
              country: beneficialOwner.person?.address?.country?.id,
            },
          },
        }
      }),
      undivideds: prospect.undivideds?.map(undivided => {
        return {
          ppe: undivided.ppe,
          shareHolding: parseInt(undivided.shareHolding),
          status: undivided.status,
          person: {
            title: undivided.person?.title,
            lastname: undivided.person?.lastname,
            firstname: undivided.person?.firstname,
            maidenName: undivided.person?.maidenName,
            birthdate: undivided.person?.birthdate ? DateCleaner.clean(undivided.person?.birthdate) : null,
            birthCountry: undivided.person?.birthCountry?.id,
            birthCity: undivided.person?.birthCity?.id,
            birthCityForeign: undivided.person?.birthCityForeign,
            nationality: undivided.person?.nationality?.id,
            ppe: undivided.person?.ppe,
            gerant: undivided.person?.gerant,
            ppeCategory: undivided.person?.ppeCategory,
            address: {
              title: undivided.person?.address?.title,
              lastname: undivided.person?.address?.lastname,
              firstname: undivided.person?.address?.firstname,
              maidenName: undivided.person?.address?.maidenname,
              address: undivided.person?.address?.address,
              address2: undivided.person?.address?.address2,
              address3: undivided.person?.address?.address3,
              postcode: undivided.person?.address?.postcode,
              city: undivided.person?.address?.city?.id,
              country: undivided.person?.address?.country?.id,
            },
          },
        }
      }),
      moralLegalRepresentatives: prospect.moralLegalRepresentatives?.map(moralLegalRepresentative => {
        return {
          ppe: moralLegalRepresentative.ppe,
          shareHolding: parseInt(moralLegalRepresentative.shareHolding),
          status: moralLegalRepresentative.status,
          person: {
            ppe: moralLegalRepresentative.ppe,
            gerant: moralLegalRepresentative.person?.gerant,
            ppeCategory: moralLegalRepresentative.person?.ppeCategory,
            title: moralLegalRepresentative.person?.title,
            lastname: moralLegalRepresentative.person?.lastname,
            firstname: moralLegalRepresentative.person?.firstname,
            maidenName: moralLegalRepresentative.person?.maidenName,
            birthdate: moralLegalRepresentative.person?.birthdate
              ? DateCleaner.clean(moralLegalRepresentative.person?.birthdate)
              : null,
            birthCountry: moralLegalRepresentative.person?.birthCountry?.id,
            birthCity: moralLegalRepresentative.person?.birthCity?.id,
            birthCityForeign: moralLegalRepresentative.person?.birthCityForeign,
            nationality: moralLegalRepresentative.person?.nationality?.id,
            address: {
              title: moralLegalRepresentative.person?.address?.title,
              lastname: moralLegalRepresentative.person?.address?.lastname,
              firstname: moralLegalRepresentative.person?.address?.firstname,
              maidenName: moralLegalRepresentative.person?.address?.maidenname,
              address: moralLegalRepresentative.person?.address?.address,
              address2: moralLegalRepresentative.person?.address?.address2,
              address3: moralLegalRepresentative.person?.address?.address3,
              postcode: moralLegalRepresentative.person?.address?.postcode,
              city: moralLegalRepresentative.person?.address?.city?.id,
              country: moralLegalRepresentative.person?.address?.country?.id,
            },
          },
        }
      }),
      legalRepresentatives: prospect.legalRepresentatives?.map((legalRepresentative: ProspectLegalRepresentatives) => {
        return {
          status: legalRepresentative.status,
          person: {
            firstname: legalRepresentative.person?.firstname,
            lastname: legalRepresentative.person?.lastname,
            birthdate: legalRepresentative.person?.birthdate
              ? DateCleaner.clean(legalRepresentative.person?.birthdate)
              : null,
            title: legalRepresentative.person?.title,
            nationality: legalRepresentative.person?.nationality?.id,
            birthCountry: legalRepresentative.person?.birthCountry?.id,
            birthCity: legalRepresentative.person?.birthCity?.id,
            ppe: legalRepresentative.person?.ppe,
            ppeCategory: legalRepresentative.person?.ppeCategory,
            address: {
              firstname: legalRepresentative.person?.address?.firstname,
              lastname: legalRepresentative.person?.address?.lastname,
              address: legalRepresentative.person?.address?.address,
              address2: legalRepresentative.person?.address?.address2,
              address3: legalRepresentative.person?.address?.address3,
              city: legalRepresentative.person?.address?.city?.id,
              country: legalRepresentative.person?.address?.country?.id,
              postcode: legalRepresentative.person?.address?.postcode,
            },
          },
        }
      }),
      persons: prospect.persons?.map((person: Person) => {
        return {
          id: person.id,
          type: person.type,
          gerant: person.gerant,
          jointDelegate: null,
          title: person.title,
          firstname: person.firstname,
          lastname: person.lastname,
          maidenName: person.maidenName,
          birthdate: DateCleaner.clean(person.birthdate),
          birthCity: person.birthCity ? person.birthCity.id : null,
          birthCityForeign: person.birthCityForeign,
          birthCountry: person.birthCountry ? person.birthCountry.id : null,
          nationality: person.nationality ? person.nationality.id : null,
          extranet: person.extranet,
          extranetCreatedAt: DateCleaner.clean(person.extranetCreatedAt),
          ppe: person.ppe,
          ppeCategory: person.ppeCategory,
          legalCapacity: person.legalCapacity,
          familySituation: person.familySituation,
          matrimonialRegime: person.matrimonialRegime,
          email: person.email,
          phone: person.phone,
          optinMarketing: person.optinMarketing
            ? {
                type: person.optinMarketing.type,
                date: null,
              }
            : null,
          optinDividend: person.optinDividend
            ? {
                type: person.optinDividend.type,
                date: null,
              }
            : null,
          optinFiscality: person.optinFiscality
            ? {
                type: person.optinFiscality.type,
                date: null,
              }
            : null,
          optinGeneralAssembly: person.optinGeneralAssembly
            ? {
                type: person.optinGeneralAssembly.type,
                date: null,
              }
            : null,
          optinInvitation: person.optinInvitation
            ? {
                type: person.optinInvitation.type,
                date: null,
              }
            : null,
          optinAll: person.optinAll
            ? {
                type: person.optinAll.type,
                date: null,
              }
            : null,
          address: person.address
            ? {
                id: person.address.id,
                title: person.address.title,
                firstname: person.address.firstname,
                lastname: person.address.lastname,
                maidenName: null,
                address: person.address.address,
                address2: person.address.address2,
                address3: person.address.address3,
                postcode: person.address.postcode,
                city: person.address.city?.id,
                country: person.address.country?.id,
                npaiAt: DateCleaner.clean(person.address.npaiAt),
                npaiRemovedAt: null,
              }
            : null,
          fiscality: person.fiscality
            ? {
                id: person.fiscality.id,
                paymentMethod: person.fiscality.paymentMethod,
                taxResidency: person.fiscality.taxResidency,
                rate: person.fiscality.rate,
                businessTax: person.fiscality.businessTax,
                incomeTax: person.fiscality.incomeTax,
                subjectIfi: person.fiscality.subjectIfi,
                ifi: person.fiscality.ifi,
                lei: person.fiscality.lei,
                nif: person.fiscality.nif,
                withholdingTax: person.fiscality.withholdingTax,
              }
            : null,
          documents: person.documents,
        }
      }),
      prospectAddresses: prospect.prospectAddresses?.map((prospectAddress: GeneralAddressInterface) => {
        return {
          main: prospectAddress.main,
          type: prospectAddress.type,
          status: prospectAddress.status,
          address: prospectAddress.address
            ? {
                id: prospectAddress.address.id,
                title: prospectAddress.address.title,
                firstname: prospectAddress.address.firstname,
                lastname: prospectAddress.address.lastname,
                maidenName: null,
                address: prospectAddress.address.address,
                address2: prospectAddress.address.address2,
                address3: prospectAddress.address.address3,
                postcode: prospectAddress.address.postcode,
                city: prospectAddress.address.city?.id,
                country: prospectAddress.address.country?.id,
                npaiAt: DateCleaner.clean(prospectAddress.address.npaiAt),
                npaiRemovedAt: null,
              }
            : null,
        }
      }),
      relations: prospect.relations?.map((relation: Relation) => {
        return {
          personId: relation.personId,
          relation: relation.relation,
        }
      }),
      fiscality: {
        paymentMethod: prospect.fiscality?.paymentMethod,
        taxResidency: prospect.fiscality?.taxResidency,
        rate: prospect.fiscality?.rate,
        businessTax: prospect.fiscality?.businessTax,
        incomeTax: prospect.fiscality?.incomeTax,
        subjectIfi: prospect.fiscality?.subjectIfi,
        ifi: prospect.fiscality?.ifi,
        lei: prospect.fiscality?.lei,
        nif: prospect.fiscality?.nif,
      },
      sameFiscalAddress: prospect.sameFiscalAddress,
      distributionPaymentPeriodicity: prospect.distributionPaymentPeriodicity,
    }).then(data => {
      if (data.uuid) {
        return data.uuid
      }

      return null
    })
  }

  get(id: string): Promise<ProspectInterface | null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/prospect/${id}`, {}).then(data => {
      if (data) {
        return data[0]
      }

      return null
    })
  }

  update(prospect: ProspectInterface): Promise<ProspectInterface | null> {
    return Caller.executePut(`${envVariable('REACT_APP_API_URL')}/prospect/${prospect.id}`, {
      enabled: prospect.enabled,
      prospectType: prospect.prospectType,
      status: prospect.status,
      statusCreatedAt: DateCleaner.clean(prospect.statusCreatedAt),
      exitedAt: DateCleaner.clean(prospect.exitedAt),
      blockedAt: DateCleaner.clean(prospect.blockedAt),
      prospectCode: prospect.prospectCode,
      moralType: prospect.moralType,
      externalCode: prospect.externalCode,
      externalDepositaryCode: prospect.externalDepositaryCode,
      recoveryCode: prospect.recoveryCode,
      legalStatus: prospect.legalStatus,
      companyName: prospect.companyName,
      siret: prospect.siret,
      orias: prospect.orias,
      kycCategoryValue: prospect.kycCategoryValue,
      kycCategoryDate: DateCleaner.clean(prospect.kycCategoryDate),
      kycLabft: prospect.kycLabft,
      kycLabftDate: DateCleaner.clean(prospect.kycLabftDate),
      kycProfession: prospect.kycProfession,
      kycFatca: prospect.kycFatca,
      groupRelation: prospect.groupRelation,
      kycCategoryValueSecond: prospect.kycCategoryValueSecond,
      kycCategoryDateSecond: DateCleaner.clean(prospect.kycCategoryDateSecond),
      kycLabftSecond: prospect.kycLabftSecond,
      kycLabftDateSecond: DateCleaner.clean(prospect.kycLabftDateSecond),
      kycProfessionSecond: prospect.kycProfessionSecond,
      kycFatcaSecond: prospect.kycFatcaSecond,
      groupRelationSecond: prospect.groupRelationSecond,
      providerCode: null,
      partner: prospect.partner ? prospect.partner.id : null,
      bankInformations: prospect.bankInformations?.map((bankInformation: BankInformation) => {
        return {
          id: bankInformation.id ? IdCleaner.clean(bankInformation.id) : null,
          label: bankInformation.label,
          main: bankInformation.main,
          bank: bankInformation.bank,
          iban: bankInformation.iban,
          swift: bankInformation.swift,
          debitMandate: bankInformation.debitMandate,
          products: bankInformation.products,
          type: bankInformation.type,
          flux: bankInformation.flux,
          status: bankInformation.status,
        }
      }),
      legalRepresentatives: prospect.legalRepresentatives?.map((legalRepresentative: ProspectLegalRepresentatives) => {
        return {
          status: legalRepresentative.status,
          person: {
            id: legalRepresentative.person?.id ? IdCleaner.clean(legalRepresentative.person.id) : null,
            firstname: legalRepresentative.person?.firstname,
            lastname: legalRepresentative.person?.lastname,
            birthdate: legalRepresentative.person?.birthdate,
            title: legalRepresentative.person?.title,
            nationality: legalRepresentative.person?.nationality?.id,
            birthCountry: legalRepresentative.person?.birthCountry?.id,
            birthCity: legalRepresentative.person?.birthCity?.id,
            ppe: legalRepresentative.person?.ppe,
            ppeCategory: legalRepresentative.person?.ppeCategory,
            address: {
              id: legalRepresentative.person?.address?.id
                ? IdCleaner.clean(legalRepresentative.person?.address?.id)
                : null,
              firstname: legalRepresentative.person?.address?.firstname,
              lastname: legalRepresentative.person?.address?.lastname,
              address: legalRepresentative.person?.address?.address,
              address2: legalRepresentative.person?.address?.address2,
              address3: legalRepresentative.person?.address?.address3,
              city: legalRepresentative.person?.address?.city?.id,
              country: legalRepresentative.person?.address?.country?.id,
              postcode: legalRepresentative.person?.address?.postcode,
            },
          },
        }
      }),
      persons: prospect.persons?.map((person: Person) => {
        return {
          id: person.id,
          type: person.type,
          gerant: person.gerant,
          jointDelegate: null,
          title: person.title,
          firstname: person.firstname,
          lastname: person.lastname,
          maidenName: person.maidenName,
          birthdate: DateCleaner.clean(person.birthdate),
          birthCity: person.birthCity ? person.birthCity.id : null,
          birthCityForeign: person.birthCityForeign,
          birthCountry: person.birthCountry ? person.birthCountry.id : null,
          nationality: person.nationality ? person.nationality.id : null,
          extranet: person.extranet,
          extranetCreatedAt: DateCleaner.clean(person.extranetCreatedAt),
          ppe: person.ppe,
          ppeCategory: person.ppeCategory,
          legalCapacity: person.legalCapacity,
          familySituation: person.familySituation,
          matrimonialRegime: person.matrimonialRegime,
          email: person.email,
          phone: person.phone,
          optinMarketing: person.optinMarketing
            ? {
                type: person.optinMarketing.type,
                date: null,
              }
            : null,
          optinDividend: person.optinDividend
            ? {
                type: person.optinDividend.type,
                date: null,
              }
            : null,
          optinFiscality: person.optinFiscality
            ? {
                type: person.optinFiscality.type,
                date: null,
              }
            : null,
          optinGeneralAssembly: person.optinGeneralAssembly
            ? {
                type: person.optinGeneralAssembly.type,
                date: null,
              }
            : null,
          optinInvitation: person.optinInvitation
            ? {
                type: person.optinInvitation.type,
                date: null,
              }
            : null,
          optinAll: person.optinAll
            ? {
                type: person.optinAll.type,
                date: null,
              }
            : null,
          address: person.address
            ? {
                id: person.address.id,
                title: person.address.title,
                firstname: person.address.firstname,
                lastname: person.address.lastname,
                maidenName: null,
                address: person.address.address,
                address2: person.address.address2,
                address3: person.address.address3,
                postcode: person.address.postcode,
                city: person.address.city?.id,
                country: person.address.country?.id,
                npaiAt: DateCleaner.clean(person.address.npaiAt),
                npaiRemovedAt: null,
              }
            : null,
          fiscality: person.fiscality
            ? {
                id: person.fiscality.id,
                paymentMethod: person.fiscality.paymentMethod,
                taxResidency: person.fiscality.taxResidency,
                rate: person.fiscality.rate,
                businessTax: person.fiscality.businessTax,
                incomeTax: person.fiscality.incomeTax,
                subjectIfi: person.fiscality.subjectIfi,
                ifi: person.fiscality.ifi,
                lei: person.fiscality.lei,
                nif: person.fiscality.nif,
                withholdingTax: person.fiscality.withholdingTax,
              }
            : null,
          documents: person.documents,
        }
      }),
      prospectAddresses: prospect.prospectAddresses?.map((prospectAddress: GeneralAddressInterface) => {
        return {
          main: prospectAddress.main,
          type: prospectAddress.type,
          status: prospectAddress.status,
          address: prospectAddress.address
            ? {
                id: prospectAddress.address.id ? IdCleaner.clean(prospectAddress.address.id) : null,
                title: prospectAddress.address.title,
                firstname: prospectAddress.address.firstname,
                lastname: prospectAddress.address.lastname,
                maidenName: null,
                address: prospectAddress.address.address,
                address2: prospectAddress.address.address2,
                address3: prospectAddress.address.address3,
                postcode: prospectAddress.address.postcode,
                city: prospectAddress.address.city?.id,
                country: prospectAddress.address.country?.id,
                npaiAt: DateCleaner.clean(prospectAddress.address.npaiAt),
                npaiRemovedAt: null,
                socialReason: prospectAddress.address?.socialReason,
              }
            : null,
        }
      }),
      relations: prospect.relations?.map((relation: Relation) => {
        return {
          personId: IdCleaner.clean(relation.personId),
          relation: relation.relation,
        }
      }),
      beneficialOwners: prospect.beneficialOwners?.map(beneficialOwner => {
        return {
          ppe: beneficialOwner.ppe,
          shareHolding: parseInt(beneficialOwner.shareHolding),
          status: beneficialOwner.status,
          person: {
            id: beneficialOwner.person?.id ? IdCleaner.clean(beneficialOwner.person?.id) : null,
            ppe: beneficialOwner.ppe,
            gerant: beneficialOwner.person?.gerant,
            ppeCategory: beneficialOwner.person?.ppeCategory,
            title: beneficialOwner.person?.title,
            lastname: beneficialOwner.person?.lastname,
            firstname: beneficialOwner.person?.firstname,
            maidenName: beneficialOwner.person?.maidenName,
            birthdate: beneficialOwner.person?.birthdate ? DateCleaner.clean(beneficialOwner.person?.birthdate) : null,
            birthCountry: beneficialOwner.person?.birthCountry?.id,
            birthCity: beneficialOwner.person?.birthCity?.id,
            birthCityForeign: beneficialOwner.person?.birthCityForeign,
            nationality: beneficialOwner.person?.nationality?.id,
            address: {
              title: beneficialOwner.person?.address?.title,
              lastname: beneficialOwner.person?.address?.lastname,
              firstname: beneficialOwner.person?.address?.firstname,
              maidenName: beneficialOwner.person?.address?.maidenname,
              address: beneficialOwner.person?.address?.address,
              address2: beneficialOwner.person?.address?.address2,
              address3: beneficialOwner.person?.address?.address3,
              postcode: beneficialOwner.person?.address?.postcode,
              city: beneficialOwner.person?.address?.city?.id,
              country: beneficialOwner.person?.address?.country?.id,
            },
          },
        }
      }),
      undivideds: prospect.undivideds?.map(undivided => {
        return {
          ppe: undivided.ppe,
          shareHolding: parseInt(undivided.shareHolding),
          status: undivided.status,
          person: {
            id: undivided.person?.id ? IdCleaner.clean(undivided.person?.id) : null,
            title: undivided.person?.title,
            lastname: undivided.person?.lastname,
            firstname: undivided.person?.firstname,
            maidenName: undivided.person?.maidenName,
            birthdate: undivided.person?.birthdate ? DateCleaner.clean(undivided.person?.birthdate) : null,
            birthCountry: undivided.person?.birthCountry?.id,
            birthCity: undivided.person?.birthCity?.id,
            birthCityForeign: undivided.person?.birthCityForeign,
            nationality: undivided.person?.nationality?.id,
            ppe: undivided.person?.ppe,
            gerant: undivided.person?.gerant,
            ppeCategory: undivided.person?.ppeCategory,
            address: {
              title: undivided.person?.address?.title,
              lastname: undivided.person?.address?.lastname,
              firstname: undivided.person?.address?.firstname,
              maidenName: undivided.person?.address?.maidenname,
              address: undivided.person?.address?.address,
              address2: undivided.person?.address?.address2,
              address3: undivided.person?.address?.address3,
              postcode: undivided.person?.address?.postcode,
              city: undivided.person?.address?.city?.id,
              country: undivided.person?.address?.country?.id,
            },
          },
        }
      }),
      moralLegalRepresentatives: prospect.moralLegalRepresentatives?.map(moralLegalRepresentative => {
        return {
          ppe: moralLegalRepresentative.ppe,
          shareHolding: parseInt(moralLegalRepresentative.shareHolding),
          status: moralLegalRepresentative.status,
          person: {
            id: moralLegalRepresentative.person?.id ? IdCleaner.clean(moralLegalRepresentative.person?.id) : null,
            ppe: moralLegalRepresentative.ppe,
            gerant: moralLegalRepresentative.person?.gerant,
            ppeCategory: moralLegalRepresentative.person?.ppeCategory,
            title: moralLegalRepresentative.person?.title,
            lastname: moralLegalRepresentative.person?.lastname,
            firstname: moralLegalRepresentative.person?.firstname,
            maidenName: moralLegalRepresentative.person?.maidenName,
            birthdate: moralLegalRepresentative.person?.birthdate
              ? DateCleaner.clean(moralLegalRepresentative.person?.birthdate)
              : null,
            birthCountry: moralLegalRepresentative.person?.birthCountry?.id,
            birthCity: moralLegalRepresentative.person?.birthCity?.id,
            birthCityForeign: moralLegalRepresentative.person?.birthCityForeign,
            nationality: moralLegalRepresentative.person?.nationality?.id,
            address: {
              title: moralLegalRepresentative.person?.address?.title,
              lastname: moralLegalRepresentative.person?.address?.lastname,
              firstname: moralLegalRepresentative.person?.address?.firstname,
              maidenName: moralLegalRepresentative.person?.address?.maidenname,
              address: moralLegalRepresentative.person?.address?.address,
              address2: moralLegalRepresentative.person?.address?.address2,
              address3: moralLegalRepresentative.person?.address?.address3,
              postcode: moralLegalRepresentative.person?.address?.postcode,
              city: moralLegalRepresentative.person?.address?.city?.id,
              country: moralLegalRepresentative.person?.address?.country?.id,
            },
          },
        }
      }),
      sameFiscalAddress: prospect.sameFiscalAddress,
      distributionPaymentPeriodicity: prospect.distributionPaymentPeriodicity,
    }).then(data => {
      if (data.uuid) {
        return data.uuid
      }

      return null
    })
  }

  getExport(filter: FilterProspectInterface | null): Promise<string | null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/prospects/extract`, {
      lastName: filter?.lastName || '',
      maidenName: filter?.maidenName || '',
      keywords: filter?.keywords || '',
      birthDate: filter?.birthDate || '',
    })
      .then(data => {
        if (data && data.content) {
          return data.content
        }

        return null
      })
      .catch(response => {
        return null
      })
  }

  getRibs(prospectId: string, type: string): Promise<BankInformation[] | null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/prospect/${prospectId}/ribs?type=${type}`, {})
      .then(data => {
        if (data) {
          return data
        }

        return null
      })
      .catch(response => {
        return null
      })
  }

  getRibForProduct(prospectId: string, productId: string, type: string): Promise<BankInformation[] | null> {
    return Caller.executeGet(
      `${envVariable('REACT_APP_API_URL')}/accounts/${prospectId}/rib/${productId}?type=${type}`,
      {}
    )
      .then(data => {
        if (data) {
          return data
        }

        return null
      })
      .catch(response => {
        return null
      })
  }
}
