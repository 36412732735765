import { GeneralAddressInterface } from '../Address/GeneralAddress'
import { PartnerInterface } from '../Partner/Partner'
import { Undivided } from '../Prospect/Undivided'
import { BankInformation } from './BankInformation'
import { BeneficialOwner } from './BeneficialOwner'
import { CustomerLegalRepresentativesInterface } from './CustomerLegalRepresentatives'
import { FiscalityInterface } from './Fiscality'
import { MoralLegalRepresentative } from './MoralLegalRepresentative'
import { Notary, NotaryInterface } from './Notary'
import { PersonInterface } from './Person'
import { Relation } from './Relation'

interface CustomerInterface {
  id: string | null
  createdAt: string | null
  updatedAt: string | null
  deletedAt: string | null
  enabled: boolean | null
  customerType: string | null
  statusCreatedAt: string | null
  status: string | null
  exitedAt: string | null
  blockedAt: string | null
  customerCode: string | null
  externalDepositaryCode: string | null
  externalCode: string | null
  recoveryCode: string | null
  legalStatus: string | null
  companyName: string | null
  siret: string | null
  orias: string | null
  kycCategoryValue: string | null
  kycCategoryDate: string | null
  kycLabft: string | null
  kycLabftDate: string | null
  kycProfession: string | null
  kycFatca: boolean | null
  kycCategoryValueSecond: string | null
  kycCategoryDateSecond: string | null
  kycLabftSecond: string | null
  kycLabftDateSecond: string | null
  kycProfessionSecond: string | null
  kycFatcaSecond: boolean | null
  moralType: string | null
  fiscality: FiscalityInterface | null
  persons: PersonInterface[] | null
  customerAddresses: GeneralAddressInterface[] | null
  bankInformations: BankInformation[] | null
  relations: Relation[] | null
  beneficialOwners: BeneficialOwner[] | null
  undivideds: Undivided[] | null
  moralLegalRepresentatives: MoralLegalRepresentative[] | null
  groupRelation: string | null
  groupRelationSecond: string | null
  notary: Notary | null
  partner: PartnerInterface | null
  sameFiscalAddress: boolean
  legalRepresentatives: CustomerLegalRepresentativesInterface[] | null
  lastNotepadAt: string | null
  countNotepad: number | null
  distributionPaymentPeriodicity: string | null
}

class Customer implements CustomerInterface {
  constructor(
    public id: string | null = null,
    public createdAt: string | null = null,
    public updatedAt: string | null = null,
    public deletedAt: string | null = null,
    public enabled: boolean | null = null,
    public customerType: string | null = null,
    public statusCreatedAt: string | null = null,
    public status: string | null = null,
    public exitedAt: string | null = null,
    public blockedAt: string | null = null,
    public customerCode: string | null = null,
    public externalDepositaryCode: string | null = null,
    public externalCode: string | null = null,
    public recoveryCode: string | null = null,
    public legalStatus: string | null = null,
    public companyName: string | null = null,
    public siret: string | null = null,
    public orias: string | null = null,
    public kycCategoryValue: string | null = null,
    public kycCategoryDate: string | null = null,
    public kycLabft: string | null = null,
    public kycLabftDate: string | null = null,
    public kycProfession: string | null = null,
    public kycFatca: boolean | null = null,
    public moralType: string | null = null,
    public fiscality: FiscalityInterface | null = null,
    public persons: PersonInterface[] | null = null,
    public customerAddresses: GeneralAddressInterface[] | null = null,
    public bankInformations: BankInformation[] | null = null,
    public relations: Relation[] | null = null,
    public beneficialOwners: BeneficialOwner[] | null = null,
    public undivideds: Undivided[] | null = null,
    public moralLegalRepresentatives: MoralLegalRepresentative[] | null = null,
    public groupRelation: string | null = null,
    public notary: NotaryInterface | null = null,
    public partner: PartnerInterface | null = null,
    public sameFiscalAddress = false,
    public legalRepresentatives: CustomerLegalRepresentativesInterface[] = [],
    public lastNotepadAt: string | null = null,
    public countNotepad: number | null = null,
    public kycCategoryValueSecond: string | null = null,
    public kycCategoryDateSecond: string | null = null,
    public kycLabftSecond: string | null = null,
    public kycLabftDateSecond: string | null = null,
    public kycProfessionSecond: string | null = null,
    public kycFatcaSecond: boolean | null = null,
    public groupRelationSecond: string | null = null,
    public distributionPaymentPeriodicity: string | null = null
  ) {
    this.id = id
    this.createdAt = createdAt
    this.updatedAt = updatedAt
    this.deletedAt = deletedAt
    this.enabled = enabled
    this.customerType = customerType
    this.statusCreatedAt = statusCreatedAt
    this.status = status
    this.exitedAt = exitedAt
    this.blockedAt = blockedAt
    this.customerCode = customerCode
    this.externalDepositaryCode = externalDepositaryCode
    this.externalCode = externalCode
    this.recoveryCode = recoveryCode
    this.legalStatus = legalStatus
    this.companyName = companyName
    this.siret = siret
    this.orias = orias
    this.kycCategoryValue = kycCategoryValue
    this.kycCategoryDate = kycCategoryDate
    this.kycLabft = kycLabft
    this.kycLabftDate = kycLabftDate
    this.kycProfession = kycProfession
    this.kycFatca = kycFatca
    this.kycCategoryValueSecond = kycCategoryValueSecond
    this.kycCategoryDateSecond = kycCategoryDateSecond
    this.kycLabftSecond = kycLabftSecond
    this.kycLabftDateSecond = kycLabftDateSecond
    this.kycProfessionSecond = kycProfessionSecond
    this.kycFatcaSecond = kycFatcaSecond
    this.moralType = moralType
    this.fiscality = fiscality
    this.persons = persons
    this.customerAddresses = customerAddresses
    this.bankInformations = bankInformations
    this.relations = relations
    this.beneficialOwners = beneficialOwners
    this.undivideds = undivideds
    this.moralLegalRepresentatives = moralLegalRepresentatives
    this.groupRelation = groupRelation
    this.groupRelationSecond = groupRelationSecond
    this.notary = notary
    this.partner = partner
    this.sameFiscalAddress = sameFiscalAddress
    this.legalRepresentatives = legalRepresentatives
    this.lastNotepadAt = lastNotepadAt
    this.countNotepad = countNotepad
    this.distributionPaymentPeriodicity = distributionPaymentPeriodicity
  }
}

export { Customer }
export type { CustomerInterface }
