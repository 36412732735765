import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Country } from '../../../../domain/Referentiel/Country/Country'
import CityAndPostcode from '../../component/Datalist/CityAndPostcode/CityAndPostcode'
import CountryDatalist from '../../component/Datalist/Country/CountryDatalist'
import Input from '../../component/Elements/Input'

interface GeneralInformationFormProps {
  countryByDefault: Country | null
}

const GeneralInformationForm = ({ countryByDefault }: GeneralInformationFormProps) => {
  const { t } = useTranslation()
  const { register, control, setValue, getValues, watch } = useFormContext()

  const watchCountry = watch('country')

  return (
    <div className='w-full flex-container'>
      <div className='col-md-6'>
        <Input
          register={register}
          classes='col-md-12'
          label={t('settings.form.bloc-general-information.name')}
          type='text'
          name='companyName'
          id='companyName'
        />
        <Input
          register={register}
          classes='col-md-12'
          label={t('settings.form.bloc-general-information.address-first-line')}
          type='text'
          name='address1'
          id='address1'
        />
        <Input
          register={register}
          classes='col-md-12'
          label={t('settings.form.bloc-general-information.address-second-line')}
          type='text'
          name='address2'
          id='address2'
        />
        <Input
          register={register}
          classes='col-md-12'
          label={t('settings.form.bloc-general-information.address-third-line')}
          type='text'
          name='address3'
          id='address3'
        />
        <div className='col-md-12'>
          <div className='form-control'>
            <label htmlFor='country' className='form-control__label'>
              {t('settings.form.bloc-general-information.country')}
            </label>
            <div className='form-control__input'>
              <CountryDatalist control={control} name='country' defaultValue={getValues('country')} />
            </div>
          </div>
        </div>
        <CityAndPostcode
          control={control}
          nameCity='city'
          namePostcode='postcode'
          country={watchCountry}
          register={register}
          setValue={setValue}
          getValues={getValues}
          classes='col-md-6 u-px0 u-mb0 flex'
          mode='col'
          menuPortalTarget
        />
      </div>
      <div className='col-md-6'>
        <div className='flex-container'>
          <Input
            register={register}
            classes='col-md-12'
            label={t('settings.form.bloc-general-information.immatriculation')}
            type='text'
            name='registration'
            id='registration'
          />
        </div>
      </div>
    </div>
  )
}

export default GeneralInformationForm
