import { FunctionComponent, useEffect, useState } from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from "react-router-dom"

import { useAppDispatch, useAppSelector } from '../../../store/hook'
import HeaderPage from '../../component/HeaderPage/HeaderPage'
import Tab from '../../component/Tab/PreSubscription/Tab'
import { setOpenCreateSearchMandateAuto, setOpenDatalistFilterDismembermentLedger } from '../../../store/component/event'
import DismembermentLedgerDatalistFilter from '../../component/Sidebar/DismembermentLedger/DismembermentLedgerDatalistFilter'
import DismembermentLedgerDatalist from '../../component/Datalist/DismembermentLedger/DismembermentLedgerDatalist'
import { useDismembermentLedger } from '../../Context/DismembermentLedgerContext'
import AlertMatch from '../../component/Alert/AlertMatch/AlertMatch'
import MatchUseCase from '../../../../useCase/DismembermentLedger/Match/MatchUseCase'
import DismembermentLedgerGateway from '../../../../gateway/DismembermentLedger/DismembermentLedgerGateway'
import { toastError, toastSuccessWithLinks } from '../../util/Toast'
import CreateSearchMandateAuto from '../../component/Sidebar/DismembermentLedger/CreateSearchMandateAuto'
import PartnerDropdownPresenter from '../../../../presenter/DismembermentLedger/PartnerDropdownPresenter'
import InvestorDropdownPresenter from '../../../../presenter/DismembermentLedger/InvestorDropdownPresenter'
import { isApiError } from '../../../type/ApiError'

const DismembermentLedgerDashboard: FunctionComponent = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const {
    selectedBareOwners,
    selectedUsufructuaries,
    totalNbSharesBareOwners,
    totalNbSharesUsufructuaries,
    forceRefresh
  } = useDismembermentLedger()


  const openMainNavigation = useAppSelector(state => state.event.openMainNavigation)
  const dismembermentLedgerRules = useAppSelector(state => state.me.me?.rules.dismemberment_ledger)
  const [partnerOptions, setPartnerOptions] = useState<{value: string, label: string}[]>([])
  const [investorOptions, setInvestorOptions] = useState<{value: string, label: string}[]>([])

  if (!dismembermentLedgerRules?.visible) {
    navigate(`/`)
  }

  useEffect(() => {
    dispatch(
      setOpenDatalistFilterDismembermentLedger({
        show: false,
        count: 0,
        filters: {
          product: {
            id: '',
            value: '',
            label: '',
          },
          transactionType: '',
          status: [],
          tags: [],
          propertyType: [],
          paymentMode: '',
          user: null,
          name: '',
          keywords: '',
        },
      })
    )
  }, [dispatch])

  useEffect(() => {
    new DismembermentLedgerGateway().getPartners().then((response) => {
      if (null !== response) {
        setPartnerOptions(new PartnerDropdownPresenter().present(response))
      }
    })
    new DismembermentLedgerGateway().getInvestors().then((response) => {
      if (null !== response) {
        setInvestorOptions(new InvestorDropdownPresenter().present(response))
      }
    })
  }, [])

  const handleCreate = () => {
    const request = {
      bareOwners: selectedBareOwners.map((row) => row.id),
      usufructuaries: selectedUsufructuaries.map((row) => row.id)
    }
    new MatchUseCase(new DismembermentLedgerGateway()).execute(request).then((response) => {
      if (response) {
        const links = response.map((res) => ({
          label: t('dismemberment-ledger.notify.view-success', { id: res.code }),
          to: `/${t('url.pre-subscription.edit')}/${res.id}`
        }));

        toastSuccessWithLinks(t('dismemberment-ledger.notify.create-success'), links)
        forceRefresh()
      }
    }).catch((e) => {
      if (isApiError(e) && e.code !== 500 && e.data?.errors[0]?.messages?.length) {
        toastError(e.data?.errors[0]?.messages[0])
      } else {
        toastError(t('dismemberment-ledger.notify.create-error'))
      }
    })
  }

  const handleClickCreateMandateAuto = () => {
    dispatch(setOpenCreateSearchMandateAuto({show: true}))
  }

  return (
    <>
      <DismembermentLedgerDatalistFilter />
      {partnerOptions && partnerOptions.length > 0 && investorOptions && investorOptions.length > 0 && (
        <CreateSearchMandateAuto partnerOptions={partnerOptions} investorOptions={investorOptions} />
      )}
      <main className={`main-content ${openMainNavigation ? 'main-content--reduce' : ''}`}>
        <HeaderPage title={t('dismemberment-ledger.title')} addLink={undefined} />
        <Tab />
        <AlertMatch nbSelected={totalNbSharesUsufructuaries} nbSelectedMax={totalNbSharesBareOwners} />
        {partnerOptions && partnerOptions.length > 0 && investorOptions && investorOptions.length > 0 && totalNbSharesBareOwners > 0 && totalNbSharesUsufructuaries === 0 && (
          <div className="u-mbm">
            <button type='button' className='button button--ink-2' onClick={handleClickCreateMandateAuto}>
              {t('dismemberment-ledger.create-mandate-auto.title')}
            </button>
          </div>
        )}
        <div className='flex-container'>
          <div className='col-md-12 col-lg-6'>
            <DismembermentLedgerDatalist type={'bare-owners'} multiSelect={true} />
          </div>
          <div className='col-md-12 col-lg-6'>
            <DismembermentLedgerDatalist type={'usufructuaries'} multiSelect={true} />
          </div>
        </div>
        <footer className={`form-bloc__footer`}>
          <button
            type='button'
            className='button button--ink-2'
            disabled={totalNbSharesBareOwners === 0 || totalNbSharesUsufructuaries === 0}
            onClick={handleCreate}
          >
            {t('common.save')}
          </button>
          <button type='button' className='button button--ink-2 button--ink-2--outline'>
            {t('common.cancel')}
          </button>
        </footer>
      </main>
    </>
  )
}

export default DismembermentLedgerDashboard
