import React, {FunctionComponent, useEffect, useRef, useState} from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { v4 as uuidV4 } from 'uuid'
import { useAppDispatch, useAppSelector } from '../../../../store/hook'
import { setCountTotal } from '../../../../store/component/recurrence'

import RecurrenceSessionMandateListPresenter from '../../../../../presenter/Recurrence/SessionMandate/RecurrenceSessionMandateListPresenter'
import { ListRequest } from '../../../../../useCase/Recurrence/SessionMandate/ListRequest'
import ListUseCase from '../../../../../useCase/Recurrence/SessionMandate/ListUseCase'
import RecurrenceSessionMandateGateway from '../../../../../gateway/Recurrence/RecurrenceSessionMandateGateway'

import Pagination from '../../Pagination/Pagination'
import HeaderRight from '../Element/HeaderRight'
import SelectCustom from "../../Elements/Select"
import ConfirmationModalWithReason from '../../Modal/ConfirmationModelWithReason'
import SessionMandatePaymentRejectedModal from '../../Modal/SessionMandatePaymentRejectedModal'
import SessionMandatePaymentValidatedModal from '../../Modal/SessionMandatePaymentValidatedModal'
import TableHead from "../../Table/TableHead"

import Refused from '../../Svg/Refused'
import Validate from '../../Svg/Validate'
import '../../../../../assets/styles/components/_datalist.scss'

import { optionsNbRows } from "../../../../../fixtures/Referentiel"
import { saveNbRowsInLocalStorage } from "../../../util/SavePreferencesInLocalStorage"
import { SortInterface, SortOrder } from "../../../../../domain/Utils/List"
import { ReferentielInterface } from "../../../../../domain/Referentiel/ReferentielInterface"
import { RecurrenceSessionMandateInterface } from '../../../../../domain/Recurrence/RecurrenceSessionMandateInterface'
import { confirmAlert } from 'react-confirm-alert'
import { handleCloseToast, toastError, toastProcess, toastSuccess } from '../../../util/Toast'
import RecurrenceSessionMandateForm from '../../Form/Recurrence/SessionMandateFilter'
import {getLabelByValue} from '../../../util/ReferentialI18n'
import ConfirmationModal from '../../Modal/ConfirmationModal'


interface ViewModelInterface {
  title: string
  heading: []
  data: {
    sessionMandates: RecurrenceSessionMandateInterface[]
    state: string
  }
  filtersShortcut: []
  filters: []
  pagination: PaginationInterface
  count: number
}

interface PaginationInterface {
  itemsPerPage: number
  numberOfItems: number
}

type Props = {
  sessionId: string,
  isLectureMode?: boolean
}

const RecurrenceSessionMandateDatalist: FunctionComponent<Props> = ({ sessionId, isLectureMode }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const openDatalistFilter = useAppSelector((state) => state.event.openDatalistFilterRecurrenceSessionMandate)
  const [viewModel, setViewModel] = useState<ViewModelInterface | null>(null)
  const [sortOrder, setSortOrder] = useState<SortInterface>({ sortLabel: null, sortOrder: SortOrder.ASC })
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [isLoadingExport, setLoadingExport] = useState<boolean>(false)
  const controller = new AbortController()
  const preferences = localStorage.getItem('preferences')
  const referential: ReferentielInterface | null = useAppSelector(({ referential }) => referential.referential)
  const [uuidCurrent, setUuidCurrent] = useState<string>(uuidV4())
  const [colspan, setColspan] = useState(0)
  const tbodyRef = useRef<HTMLTableSectionElement>(null)

  const { register, setValue, watch } = useForm()

  const watchNumberRows = watch('numberRows')

  useEffect(() => {
    if (!watchNumberRows) {
      setValue('numberRows', preferences ? JSON.parse(preferences).numberRows : 50)
    } else {
      saveNbRowsInLocalStorage(preferences, watchNumberRows)
      const listRequest = new ListRequest(currentPage, watchNumberRows || 50, openDatalistFilter.filters, sessionId)
      const recurrences = new ListUseCase(new RecurrenceSessionMandateGateway()).execute(listRequest, controller.signal).then(response => {
        return response
      })
      const presenter = new RecurrenceSessionMandateListPresenter(recurrences)
      presenter.load().then(() => {
        setViewModel(presenter.immutableViewModel())
        setColspan(presenter.immutableViewModel().heading.length)
        dispatch(setCountTotal(presenter.immutableViewModel().pagination.numberOfItems))
      })
      setValue('keyword', openDatalistFilter.filters.keyword)
      setValue('status', openDatalistFilter.filters.status)
    }
  }, [currentPage, watchNumberRows, uuidCurrent, openDatalistFilter.filters])

  useEffect(() => {
    setCurrentPage(1)
  }, [openDatalistFilter.filters])

  const paginate = (pageNumber: number) => {
    controller.abort()
    setCurrentPage(pageNumber)
  }

  const [selectedItems, setSelectedItems] = useState<Record<string, boolean>>({})
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null)
  const [isCheckedAll, setIsCheckedAll] = useState<boolean>(false)

  const handleHeaderCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isLectureMode) {
      return;
    }

    const isChecked = event.target.checked
    const newSelectedItems: Record<string, boolean> = {}
    viewModel?.data.sessionMandates.forEach((item: RecurrenceSessionMandateInterface) => {
      if (item.mandate.id && item.paymentStatus !== 'excluded') {
        newSelectedItems[item.mandate.id] = isChecked
      }
    })
    setSelectedItems(newSelectedItems)
  }

  const handleCheckboxChange = (id: string | null, status: string) => {
    if (!id) {
      return
    }

    setSelectedItems(prevState => {
      const newState = { ...prevState, [id]: !prevState[id] }

      // Obtenir toutes les lignes actuellement sélectionnées
      const selectedKeys = Object.keys(newState).filter(key => newState[key])
      if (selectedKeys.length > 0) {
        // Vérifier le statut des lignes sélectionnées
        const selectedStatuses = viewModel?.data.sessionMandates
          .filter(item => selectedKeys.includes(item.mandate.id ?? ''))
          .map(item => item.paymentStatus)

        // Si tous les statuts sont identiques, définir selectedStatus
        setSelectedStatus(selectedStatuses?.every(s => s === status) ? status : null)
      } else {
        setSelectedStatus(null) // Réinitialiser si aucune case n'est cochée
      }

      return newState
    })
  }

  const isCheckboxDisabled = (status: string) => {
    return selectedStatus !== null && status !== selectedStatus
  }

  const checkIfAllSelected = () => {
    const allSelected = Object.values(selectedItems).every(value => value)
    setIsCheckedAll(allSelected && Object.keys(selectedItems).length > 0)
  }

  useEffect(() => {
    checkIfAllSelected()
  }, [selectedItems])

  const handleConfirmExcludeAction = () => {
    if (isLectureMode) {
      return;
    }

    confirmAlert({
      customUI: ({ onClose }) => {
        return <ConfirmationModalWithReason onConfirm={(message) => handleClickExcludeAction(message)} onClose={onClose} />
      },
    })
  }
  const handleClickExcludeAction = (reason: string) => {
    const toastId = toastProcess('recurrence.notify.session-mandate-exclude')
    new RecurrenceSessionMandateGateway()
      .bulkExclude(sessionId, Object.keys(selectedItems).filter(id => selectedItems[id]), reason)
      .then(response => {
        if (response) {
          toastSuccess(t('recurrence.notify.session-mandate-exclude-success'))
          setUuidCurrent(uuidV4())
          setSelectedItems({})
          setSelectedStatus(null)
          return
        }
        toastError(t('recurrence.notify.session-mandate-exclude-error'))
      })
      .catch(() => {
        toastError(t('recurrence.notify.session-mandate-exclude-error'))
      })
      .finally(() => {
        handleCloseToast(toastId)
      })
  }

  const handleConfirmReintegrateAction = () => {
    if (isLectureMode) {
      return;
    }

    confirmAlert({
      customUI: ({ onClose }) => {
        return <ConfirmationModal onConfirm={() => handleClickReintegrateAction()} onClose={onClose} />
      },
    })
  }
  const handleClickReintegrateAction = () => {
    const toastId = toastProcess('recurrence.notify.session-mandate-reintegrate')
    new RecurrenceSessionMandateGateway()
      .bulkReintegrate(sessionId, Object.keys(selectedItems).filter(id => selectedItems[id]))
      .then(response => {
        if (response) {
          toastSuccess(t('recurrence.notify.session-mandate-reintegrate-success'))
          setUuidCurrent(uuidV4())
          setSelectedItems({})
          setSelectedStatus(null)
          return
        }
        toastError(t('recurrence.notify.session-mandate-reintegrate-error'))
      })
      .catch(() => {
        toastError(t('recurrence.notify.session-mandate-exclude-error'))
      })
      .finally(() => {
        handleCloseToast(toastId)
      })
  }

  type FormValues = {
    paymentAt: string;
    externalReference: string;
    paymentReference: string;
    paymentAmount: number;
    mandateId: string;
  };

  const handleConfirmPaymentValidatedAloneAction = (mandateId: string) => {
    if (isLectureMode) {
      return;
    }

    confirmAlert({
      customUI: ({ onClose }) => {
        return <SessionMandatePaymentValidatedModal
          onConfirm={handleClickPaymentValidatedAloneAction}
          onClose={onClose}
          mandateId={mandateId}/>
      },
    })
  }

  const handleClickPaymentValidatedAloneAction = (data: FormValues) => {
    const toastId = toastProcess('recurrence.notify.session-mandate-payment-validated')
    new RecurrenceSessionMandateGateway()
      .paymentValidated(sessionId, data.mandateId, data.paymentAt, data.externalReference, data.paymentReference, data.paymentAmount)
      .then(response => {
        if (response) {
          toastSuccess(t('recurrence.notify.session-mandate-payment-validated-alone-success'))
          setUuidCurrent(uuidV4())
          return
        }
        toastError(t('recurrence.notify.session-mandate-payment-validated-alone-error'))
      })
      .catch(() => {
        toastError(t('recurrence.notify.session-mandate-payment-validated-alone-error'))
      })
      .finally(() => {
        handleCloseToast(toastId)
      })
  }

  type FormValuesRejected = {
    rejectCount: number,
    rejectReason: string,
    rejectReference: string
    mandateId: string;
  };

  const handleConfirmPaymentRejectedAloneAction = (mandateId: string) => {
    if (isLectureMode) {
      return;
    }

    confirmAlert({
      customUI: ({ onClose }) => {
        return <SessionMandatePaymentRejectedModal
          onConfirm={handleClickPaymentRejectedAloneAction}
          onClose={onClose}
          mandateId={mandateId}/>
      },
    })
  }

  const handleClickPaymentRejectedAloneAction = (data: FormValuesRejected) => {
    const toastId = toastProcess('recurrence.notify.session-mandate-payment-refused')
    new RecurrenceSessionMandateGateway()
      .paymentRejected(sessionId, data.mandateId, data.rejectCount, data.rejectReason, data.rejectReference)
      .then(response => {
        if (response) {
          toastSuccess(t('recurrence.notify.session-mandate-payment-refused-alone-success'))
          setUuidCurrent(uuidV4())
          return
        }
        toastError(t('recurrence.notify.session-mandate-payment-refused-alone-error'))
      })
      .catch(() => {
        toastError(t('recurrence.notify.session-mandate-payment--alone-error'))
      })
      .finally(() => {
        handleCloseToast(toastId)
      })
  }

  return (
    <>
      {viewModel !== null && (
        <>
          <div className={`datalist`}>
            <div className="datalist__title">{t(viewModel.title)}</div>
            <RecurrenceSessionMandateForm
              initialFilters={openDatalistFilter.filters}
            />
            <div className="datalist__header">
              <HeaderRight
                numberOfActivatedFilters={0}
                handleClickFilter={null}
                handleClickExport={() => setLoadingExport(true)}
                isLoadingExport={isLoadingExport}
                allowExport={false}
                hideFilter={true}
              />
            </div>
            <SelectCustom
              classes="flex justify-end u-mbs"
              id="numberRows"
              name="numberRows"
              label={t('filters.display-results-by')}
              options={optionsNbRows}
              register={register}
              noChoiceOption
            />
            <div className="table-fix-head">
              <table className="datalist__datas datalist__datas--with-checkbox">
                <thead>
                <tr>
                  <th colSpan={colspan}>
                    <div>{t('recurrence.button-action-status.title')}</div>
                    <div>
                      {(viewModel?.data.state === 'draft' ||
                        viewModel?.data.state === 'calculated' ||
                        viewModel?.data.state === 'controlled' ||
                        viewModel?.data.state === 'verified') && (
                          <>
                            {selectedStatus !== 'excluded' && (
                              <button className="button button--submit u-mrs" onClick={handleConfirmExcludeAction} disabled={isLectureMode}>
                                {t('recurrence.button-action-status.exclude-selection')}
                              </button>
                            )}
                            {selectedStatus === 'excluded' && (
                              <button className="button button--submit u-mrs" onClick={handleConfirmReintegrateAction} disabled={isLectureMode}>
                                {t('recurrence.button-action-status.reintegrate-selection')}
                              </button>
                              )}
                          </>
                      )}

                    </div>
                  </th>
                </tr>
                {viewModel.heading && (
                  <TableHead
                    typeFilter={'API'}
                    heading={viewModel.heading}
                    sortOrder={sortOrder}
                    setSortOrder={setSortOrder}
                    viewModel={viewModel}
                    setViewModel={setViewModel}
                    filter={{ sessionId: sessionId }}
                    watchNumberRows={watchNumberRows}
                    currentPage={currentPage}
                    listRequest={
                      new ListRequest(
                        currentPage,
                        watchNumberRows || 50,
                        openDatalistFilter.filters,
                        sessionId
                      )
                    }
                    listUseCase={ListUseCase}
                    listPresenter={RecurrenceSessionMandateListPresenter}
                    investorId={sessionId}
                    gateway={RecurrenceSessionMandateGateway}
                    showSelector={true}
                    onSelectorChange={handleHeaderCheckboxChange}
                    selectorValue={isCheckedAll}
                  />
                )}
                </thead>
                <tbody ref={tbodyRef}>
                {viewModel?.data.sessionMandates?.map((item: RecurrenceSessionMandateInterface) => (
                  <tr key={item.mandate.id} className={(item.paymentStatus === 'excluded') ? 'line--error' : ''}>
                    {item.mandate.id && (
                      <>
                        <td>
                          <input
                            type="checkbox"
                            checked={selectedItems[item.mandate.id] || false}
                            onChange={() => handleCheckboxChange(item.mandate.id, item.paymentStatus ?? '')}
                            disabled={isCheckboxDisabled(item.paymentStatus ?? '') || isLectureMode}
                          />
                        </td>
                        <td>
                          {viewModel?.data.state === 'validated' && item.paymentStatus !== 'excluded' && (
                            <div style={{width: '65px'}}>
                              <button onClick={() => handleConfirmPaymentValidatedAloneAction(item.mandate.id ?? '')}
                                      className={`button-reset`}
                                      disabled={isLectureMode}
                                      title={t('recurrence.button-action-status.payment-validated-alone')}
                              >
                                <Validate color={'var(--success)'} />
                              </button>
                              <button onClick={() => handleConfirmPaymentRejectedAloneAction(item.mandate.id ?? '')}
                                      className={`button-reset`}
                                      disabled={isLectureMode}
                                      title={t('recurrence.button-action-status.payment-refused-alone')}
                              >
                                <Refused color={`var(--error)`} />
                              </button>
                            </div>
                          )}
                        </td>
                        <td>{item.createdAt}</td>
                        <td>{item.mandate.product?.code}</td>
                        <td>{item.mandate.partner?.code}</td>
                        <td>{item.mandate.partner?.label}</td>
                        <td>{item.mandate.investor?.code}</td>
                        <td>
                          <div className={`badge etat etat--${item.paymentStatus} badge--shadow u-mrs`} data-tooltip={item.reason}>
                            <div className="badge__text etat-small">{getLabelByValue(item.paymentStatus ?? '', referential?.recurrence.session_mandate_status ?? [])}</div>
                          </div>
                        </td>
                        <td>{item.investmentShareCount}</td>
                        <td>{item.investmentPrice === null || isNaN(item.investmentPrice) ? '-' : item.investmentPrice}</td>
                        <td>{item.mandate.investor?.firstname} {item.mandate.investor?.lastname}</td>
                        <td>{item.sliceStart}</td>
                        <td>{item.sliceEnd}</td>
                        <td>{item.mandate.rum}</td>
                        <td>
                          <div className={`badge etat etat--${item.mandate.rumStatus} badge--shadow u-mrs`}>
                            <div className="badge__text etat-small">{t(`common.state.${item.mandate.rumStatus}`)}</div>
                          </div>
                        </td>
                        <td>{item.paymentReference}</td>
                        <td>{item.paymentRejectReason}</td>
                      </>
                    )}
                  </tr>
                ))}
                {viewModel?.data.sessionMandates?.length === 0 && (
                  <tr>
                    <td colSpan={viewModel?.heading.length}>{t('common.data-is-empty')}</td>
                  </tr>
                )}
                </tbody>
              </table>
            </div>
          </div>
          <Pagination
            currentPage={currentPage}
            itemsPerPage={watchNumberRows || viewModel.pagination.itemsPerPage}
            numberOfItems={viewModel.pagination.numberOfItems}
            callback={paginate}
          />
        </>
      )}
    </>
  )
}

export default RecurrenceSessionMandateDatalist

