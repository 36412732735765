import React, { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { CustomerInterface } from '../../../../../../../domain/Customer/Customer'
import { PersonInterface } from '../../../../../../../domain/Customer/Person'
import { ReferentielInterface } from '../../../../../../../domain/Referentiel/ReferentielInterface'
import FiscalityForm from './FiscalityForm'

type Props = {
  customer: CustomerInterface
  referential: ReferentielInterface
}

const FiscalityBlock: FunctionComponent<Props> = ({ customer, referential }) => {
  const { t } = useTranslation()

  return (
    <div className={`form-bloc form--bloc--fiscality`}>
      <div className='form-bloc__title'>{t('account.form.fiscality.title')}</div>
      <div className='form-bloc__form flex-container'>
        {customer.persons?.map((person: PersonInterface, index: number) => {
          return (
            person.id && <FiscalityForm referential={referential} indexPerson={index} key={`fiscality-form-${index}`} />
          )
        })}
      </div>
    </div>
  )
}

export default FiscalityBlock
