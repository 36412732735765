import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface settings {
  isEnableRecurrenceModifyPrice: boolean
}

const initialState: settings = {
  isEnableRecurrenceModifyPrice: false,
}

export const settingsSlice = createSlice({
  name: 'settings',
  initialState: initialState,
  reducers: {
    setIsEnableRecurrenceModifyPrice: (state, action: PayloadAction<boolean>) => {
      state.isEnableRecurrenceModifyPrice = action.payload
    },
  },
})

export const { setIsEnableRecurrenceModifyPrice } = settingsSlice.actions

export default settingsSlice.reducer
