import React, { FunctionComponent } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ReferentielInterface } from '../../../../../../../domain/Referentiel/ReferentielInterface'
import Input from '../../../../Elements/Input'
import RadioYesOrNot from '../../../../Elements/RadioYesOrNot'
import SelectCustom from '../../../../Elements/Select'

type Props = {
  referential: ReferentielInterface
  indexPerson: number
}

const FiscalityForm: FunctionComponent<Props> = ({ referential, indexPerson }) => {
  const { t } = useTranslation()
  const { register } = useFormContext()

  return (
    <div className='col-md-6'>
      <div className='flex-container'>
        <div className='col-md-6'>
          {t('account.form.person')} {indexPerson + 1}
        </div>
        <div className='col-md-12'>
          <Input
            id={`persons.${indexPerson}.fiscality.nif`}
            name={`persons.${indexPerson}.fiscality.nif`}
            label={t('account.form.fiscality.nif')}
            register={register}
          />
        </div>
        <SelectCustom
          classes='col-md-12'
          label={t('account.form.fiscality.taxResidency')}
          id={`person.${indexPerson}.fiscality.taxResidency`}
          name={`persons.${indexPerson}.fiscality.taxResidency`}
          options={referential.prospect.tax_residency || []}
          register={register}
        />
        <div className='col-md-12'>
          <RadioYesOrNot
            id={`persons.${indexPerson}.fiscalityIncomeTax`}
            name={`persons.${indexPerson}.fiscalityIncomeTax`}
            label={t('account.form.fiscality.incomeTax')}
            register={register}
          />
        </div>
        <div className='col-md-12'>
          <RadioYesOrNot
            id={`persons.${indexPerson}.fiscalitySubjectIfi`}
            name={`persons.${indexPerson}.fiscalitySubjectIfi`}
            label={t('account.form.fiscality.subjectIfi')}
            register={register}
          />
        </div>
        <SelectCustom
          classes='col-md-12'
          label={t('account.form.fiscality.rate')}
          id={`persons.${indexPerson}.fiscality.rate`}
          name={`persons.${indexPerson}.fiscality.rate`}
          options={referential.prospect.nr_rate || []}
          register={register}
        />

        <div className='col-md-12'>
          <RadioYesOrNot
            id={`persons.${indexPerson}.fiscalityWithholdingTax`}
            name={`persons.${indexPerson}.fiscalityWithholdingTax`}
            label={t('account.form.fiscality.withholdingTax')}
            register={register}
          />
        </div>
      </div>
    </div>
  )
}

export default FiscalityForm
